import { Button, Card, Popover, Segmented, Select, Space } from 'antd';
import React, { createContext, useContext, useState } from 'react';
import './widget.scss';
import Icon from '@mdi/react';
import { mdiArrowAll, mdiFileTree, mdiFilterOutline } from '@mdi/js';
import WidgetFilter from './WidgetFilter';
import clsx from 'clsx';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const WidgetContext = createContext({});

export const useWidget = () => useContext(WidgetContext);

const Widget = (props) => {
  const default_filter = { statuses: [], network_account_ids: [], network: null, limit: 10 };
  const [filters, setFilters] = useState(default_filter);
  const [level, setLevel] = useState('adset');
  const [runFilterTimestamp, setRunFilterTimestamp] = useState('adset');
  const {
    widget_key,
    title,
    tableWidget,
    disableLevelFilter,
    disableLimit,
    disableDateFilter,
    disableStatusFilter,
    disableAcountsFilter,
  } = props;

  const { date_from, date_to } = useWidgetDate(widget_key);

  if (!widget_key) return null;

  /** Update filter timestamp, table and query listens to it and then it is updated */
  const runFilter = () => {
    setRunFilterTimestamp(new Date().getTime());
  };

  /** Change reporting level */
  const changeLevel = (level) => {
    setLevel(level);
    // When reporting level is changed filter must run immidiatelly
    runFilter();

    props.onLevelChange?.(level);
  };

  const changeLimit = (value) => {
    setFilters((f) => {
      f.limit = value;
      return f;
    });
    runFilter();
  };

  return (
    <WidgetContext.Provider
      value={{
        widget_key,
        title,

        filters,
        setFilters,

        level,
        changeLevel,

        runFilterTimestamp,
        runFilter,

        default_filter,

        widget_filters: {
          ...filters,
          date_from,
          date_to,
          level,
        },
      }}
    >
      <Card
        title={title}
        extra={
          <Space>
            {!disableLevelFilter ? (
              <Popover
                content={
                  <Segmented
                    onChange={changeLevel}
                    options={[
                      {
                        label: 'Adsets',
                        value: 'adset',
                      },
                      {
                        label: 'Campaigns',
                        value: 'campaign',
                      },
                      {
                        label: 'Ads',
                        value: 'ad',
                      },
                    ]}
                  />
                }
                trigger='click'
              >
                <Button
                  icon={<Icon path={mdiFileTree} />}
                  loading={false}
                  onClick={() => console.log('test')}
                />
              </Popover>
            ) : null}
            <Popover
              placement='left'
              content={
                <WidgetFilter
                  widget_key={widget_key}
                  disableDateFilter={disableDateFilter}
                  disableStatusFilter={disableStatusFilter}
                  disableAcountsFilter={disableAcountsFilter}
                />
              }
              trigger='click'
            >
              <Button icon={<Icon path={mdiFilterOutline} />} loading={false} />
            </Popover>
            <Button
              icon={<Icon path={mdiArrowAll} />}
              loading={false}
              onClick={() => console.log('moving')}
            />
            {!disableLimit ? (
              <Select
                onChange={changeLimit}
                options={[
                  { value: '5', label: 5 },
                  { value: '10', label: 10 },
                  { value: '20', label: 20 },
                  { value: '25', label: 25 },
                  { value: '50', label: 50 },
                ]}
                value={filters.limit}
              />
            ) : null}
          </Space>
        }
        className={clsx('widget-card w-full', {
          'table-widget': tableWidget,
        })}
      >
        {props.children}
      </Card>
    </WidgetContext.Provider>
  );
};

export default Widget;
