import React, { useState, useEffect } from 'react';
import { DateRangePicker as DateRangePickerRaw } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import storage from '@ninja/storage';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const DateRangePicker = (props) => {
  const { value, date_key, globalDate, onChange } = props;
  const [state, setState] = useState(value);
  const classes = useStyles();
  const history = useHistory();
  const [changeGlobal, setChangeGlobal] = useState(globalDate);

  useEffect(() => {
    setState(value);
  }, [value]);

  const { setDate } = useWidgetDate(date_key);

  const handleChange = (item) => {
    setState({ ...state, ...item });
    if (typeof onChange !== 'function') return;
    onChange(item);

    const dates = {
      date_from: format(item.range.startDate, 'yyyy-MM-dd'),
      date_to: format(item.range.endDate, 'yyyy-MM-dd'),
    };

    if (changeGlobal) {
      const query = new URLSearchParams(window.location.search);
      query.set('date_from', dates.date_from);
      query.set('date_to', dates.date_to);
      storage.set('dates', dates);
      history.push({
        pathname: window.location.pathname,
        search: `?${query.toString()}`,
      });
    }

    setDate({
      date_from: dates.date_from,
      date_to: dates.date_to,
      change_global: changeGlobal,
      date_key: date_key,
    });
  };

  return (
    <div className={classes.foot}>
      <DateRangePickerRaw
        showDateDisplay={false}
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[state.range]}
        direction='horizontal'
        footerContent={
          <Checkbox
            className='px-2'
            onChange={(e) => setChangeGlobal(e.target.checked)}
            checked={changeGlobal}
          >
            <span style={{ fontSize: 13 }}>Apply Globally</span>
          </Checkbox>
        }
      />
    </div>
  );
};

export default DateRangePicker;

const useStyles = makeStyles(({ breakpoints }) => ({
  foot: {
    [breakpoints.down('sm')]: {
      maxHeight: '500px',
      overflowY: 'scroll',
    },
    '& .ant-popover': {
      height: '500px',
      overflowY: 'scroll',
    },
    '& .rdrMonthAndYearWrapper': {
      padding: 0,
      height: 'auto',
    },
    '& .rdrDateRangePickerWrapper': {
      [breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'row',
      },
      display: 'flex',
      flexDirection: 'column',
    },
    '& .rdrDefinedRangesWrapper': {
      width: '100%',
      [breakpoints.down('sm')]: {
        order: 1,
      },
    },
    '& .rdrMonths': {
      [breakpoints.up('sm')]: {
        display: 'flex',
      },
      display: 'grid',
    },
    '& .rdrMonth': {
      [breakpoints.down('sm')]: {
        width: '100%',
      },
      padding: '0 0.833em 0.666em 0.833em',
    },
    '& .MuiButton-label': {
      fontSize: '12px !important',
    },
    '& .rdrStaticRangeLabel': {
      padding: '10px',
      fontSize: '13px',
    },
    '& .rdrInputRange': {
      padding: '5px 8px',
    },
    '& .rdrMonthAndYearPickers select': {
      fontWeight: 500,
    },
    '& .rdrDay': {
      width: 40,
    },
    '& .rdrDayNumber span': {
      fontSize: 12,
      fontWeight: 400,
    },
    '& .rdrInputRange span': {
      fontSize: '13px !important',
    },
  },
}));
