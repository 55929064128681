import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import List from '@material-ui/core/List';
import { Avatar, ListItem, Popover } from '@material-ui/core';
import clsx from 'clsx';

const SelectFormatter = (props) => {
  const {
    value,
    options = [],
    onChange,
    showColor,
    showAvatar,
    nameStyle,
    descriptionStyle,
    disabled = true,
  } = props;

  const classes = useStyles();

  const selected = options.find((op) => op.id === value);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const PopOver = ({ open, anchorEl, onClose }) => {
    return (
      <Popover
        open={open && disabled}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component='nav' style={{ padding: 15 }}>
          {options.map((option) => (
            <ListItem
              button
              key={option.id}
              className={classes.listItem}
              style={{ color: showColor && option.color }}
              selected={value === option.id}
              onClick={() => {
                onChange(option.id);
                handlePopoverClose();
              }}
            >
              <div className={classes.listItemTitle}>
                {showColor && (
                  <div className={classes.color} style={{ background: option.color }} />
                )}
                {showAvatar && (
                  <Avatar
                    alt=''
                    src={option.avatar}
                    className={clsx(classes.avatar, {
                      [classes.avatarBorder]: !option.hideAvatarBorder,
                    })}
                  />
                )}
                <div>
                  <Typography className={classes.name} style={nameStyle}>
                    {option.name}
                  </Typography>
                  <Typography className={classes.description} style={descriptionStyle}>
                    {option.description}
                  </Typography>
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  };

  if (!selected) {
    return (
      <div className='w-full cursor-pointer'>
        <div className='w-full flex items-center justify-around' onClick={handlePopoverOpen}>
          <span className='font-medium text-xs text-[#536A8B]'>Set Status</span>
          <img src='/icons/arrow/arrowDropDown.svg' alt='' />
        </div>
        <PopOver open={open} anchorEl={anchorEl} onClose={handlePopoverClose} />
      </div>
    );
  }

  return (
    <div
      style={{
        background: showColor && selected.background,
      }}
      className='w-full h-full flex items-center justify-between'
    >
      <div
        onClick={handlePopoverOpen}
        className={classes.renderCell}
        style={{ color: showColor && selected.color }}
      >
        {showColor && <div className={classes.color} style={{ background: selected.color }} />}
        {showAvatar && (
          <Avatar
            alt=''
            src={selected.avatar}
            className={clsx(classes.avatar, {
              [classes.avatarBorder]: !selected.hideAvatarBorder,
            })}
          />
        )}
        <div>
          <Typography className={classes.name} style={nameStyle}>
            {selected.name}
          </Typography>
          <Typography className={classes.description} style={descriptionStyle}>
            {selected.description}
          </Typography>
        </div>
      </div>
      <img src='/icons/arrow/arrowDropDown.svg' alt='' />
      <PopOver open={open} anchorEl={anchorEl} onClose={handlePopoverClose} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  renderCell: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minWidth: 200,
    padding: 15,
    borderRadius: 6,
    '&.Mui-selected': {
      background: '#E1EEFF',
    },
  },
  listItemTitle: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
  },
  color: {
    width: 12,
    height: 12,
    borderRadius: '100%',
    marginRight: 10,
  },
  name: {
    fontWeight: 350,
    fontSize: 12,
  },
  description: {
    fontWeight: 600,
    fontSize: 12,
    whiteSpace: 'pre-wrap',
  },
  avatar: {
    marginRight: 10,
  },
  avatarBorder: {
    border: '2px solid #2C7DF0;',
  },
}));

export default SelectFormatter;
