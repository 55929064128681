import { SvgIcon } from '@material-ui/core';
import CheckboxBase from '@material-ui/core/Checkbox';
import { ninjaCheckbox, ninjaCheckboxChecked } from 'assets/svgPaths';
import React from 'react';

const CheckboxCheckedIcon = (
  <SvgIcon viewBox='0 0 16 16'>
    <path d={ninjaCheckboxChecked} />
  </SvgIcon>
);

const CheckboxIcon = (
  <SvgIcon viewBox='0 0 16 16'>
    <path d={ninjaCheckbox} />
  </SvgIcon>
);

const Checkbox = (props) => {
  return <CheckboxBase checkedIcon={CheckboxCheckedIcon} icon={CheckboxIcon} {...props} />;
};

export default Checkbox;
