import React from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

export default function JustValueFormatter(props) {
  let val = 0;
  // Get column value
  if (props.getValue) {
    val = props.getValue(props);
  } else {
    val = props.row[props.column.key];
  }

  if (val !== 0 && val !== null) {
    val = val.toFixed(2);
  } else if (val === null) {
    val = 0;
  }

  return <span>{getFormattedValue(val, props.row.currency ? props.row.currency : null)}</span>;
}
