import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Input, Button, Form, Typography, Alert } from 'antd';
import { useSendResetPasswordLinkMutation } from 'store/redux/apis/user.api';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import UrlHelper from 'common/utils/url-helper';

const RequestResetPasswordPage = () => {
  const [sendResetPasswordQuery, { isLoading, isError, isSuccess }] =
    useSendResetPasswordLinkMutation();

  const sendPasswordResetLink = (data) => {
    sendResetPasswordQuery(data)
      .unwrap()
      .then((data) => {
        if (!data.token) return;
      });
  };

  const form = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: sendPasswordResetLink,
    validateOnChange: false,
  });

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='flex flex-col w-full h-full mx-auto items-center'>
        <a className='flex no-underline flex-col' href={UrlHelper.home()}>
          <img className='m-auto w-[150px]' src='/icons/dataninja/dataninja.svg' />
          <div className='relative'>
            <span className='absolute text-white tracking-wider -right-6 bottom-16 text-xs bg-orange font-medium rounded-3xl px-1 py-0.5'>
              BETA
            </span>
            <Typography.Title level={1} className='!text-white'>
              MyDataNinja
            </Typography.Title>
          </div>
        </a>

        <div className='bg-white p-5 rounded-md shadow-md transition-all duration-300 w-full md:w-1/3 lg:w-1/5'>
          <div>
            <p className='font-bold text-xl text-color-dark-text'>Reset Your Password</p>
          </div>
          <form className='disable-antd-margin' onSubmit={form.handleSubmit}>
            <div className='grid grid-cols-2 flex-wrap gap-4'>
              {isError ? (
                <div className='col-span-2'>
                  <Alert
                    closable
                    className='w-full'
                    type='error'
                    message='This email is not attached to any account'
                  />
                </div>
              ) : null}
              {isSuccess ? (
                <div className='col-span-2'>
                  <Alert
                    closable
                    className='w-full'
                    type='success'
                    message='Password Reset Link Sent To Your Email'
                  />
                </div>
              ) : null}
              <div className='col-span-2'>
                <Form.Item
                  hasFeedback
                  {...formikFieldToAntProps(form.errors, form.touched, 'email')}
                >
                  <Input
                    size='large'
                    type='email'
                    placeholder='Enter Your Email'
                    name='email'
                    value={form.values.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='flex-center pt-4'>
              <Button
                disabled={isSuccess}
                loading={isLoading}
                htmlType='submit'
                size='large'
                type='primary'
              >
                Send Reset Link
              </Button>
            </div>
          </form>
        </div>
        <div className='pt-4'>
          <Typography.Text className='text-white text-sm font-bold'>
            If You Already Have Password{' '}
            <Link to={P.AUTH.LOGIN} className='text-white text-sm link'>
              Log In Here
            </Link>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default RequestResetPasswordPage;

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
});
