import ninjaApi from 'store/redux/apis/ninja.api';

export const adAdApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['AdAd'],
  endpoints: (builder) => ({
    /* Change Ad Status */
    changeAdStatus: builder.mutation({
      query: (body) => ({
        url: '/network/creative/change-status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AdAd'],
    }),
    /* search ad*/
    SearchAd: builder.query({
      query: (body) => ({
        url: '/network/creative/search',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useChangeAdStatusMutation, useSearchAdQuery } = adAdApi;
