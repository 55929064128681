import { Avatar } from 'antd';
import React from 'react';

const WorkspaceAvatar = ({ workspace, ...restProps }) => {
  if (workspace.avatar) {
    return <Avatar src={workspace.avatar} size='small' {...restProps} />;
  }

  return (
    <Avatar size='small' {...restProps}>
      {workspace?.name?.[0]}
    </Avatar>
  );
};

export default WorkspaceAvatar;
