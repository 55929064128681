export const ninjaReload =
  'M23.7806 4.71989L19.2807 0.219679C19.0662 0.00516843 18.7437 -0.0593413 18.4632 0.0576793C18.1827 0.174684 17.9997 0.446205 17.9997 0.749206V2.99932H8.24988C3.69294 2.99932 0 6.69249 0 11.2497C0 11.6637 0.335993 11.9997 0.74999 11.9997H3.74993C4.16393 11.9997 4.49993 11.6637 4.49993 11.2497C4.49993 9.17811 6.17839 7.49954 8.24986 7.49954H17.9997V9.74965C17.9997 10.0527 18.1827 10.3272 18.4632 10.4427C18.7437 10.5597 19.0662 10.4952 19.2807 10.2807L23.7806 5.78047C24.0731 5.48795 24.0731 5.0124 23.7806 4.71989Z M23.25 11.9988H20.2501C19.8361 11.9988 19.5001 12.3348 19.5001 12.7488C19.5001 14.8204 17.8216 16.499 15.7501 16.499H6.00028V14.2489C6.00028 13.9458 5.81728 13.6713 5.5368 13.5558C5.25629 13.4404 4.9353 13.5048 4.7193 13.7194L0.219375 18.2196C-0.0731251 18.5121 -0.0731251 18.9876 0.219375 19.2801L4.7193 23.7803C4.9338 23.9948 5.25629 24.0593 5.5368 23.9423C5.8173 23.8253 6.00028 23.5523 6.00028 23.2493V20.9992H15.7501C20.3071 20.9992 24 17.306 24 12.7488C24 12.3348 23.664 11.9988 23.25 11.9988Z';

export const ninjaReporting =
  'M12.3318 0C11.9755 0 11.6872 0.288284 11.6872 0.64454V9.71106C11.6872 10.0673 11.9755 10.3556 12.3318 10.3556H21.3553C21.7116 10.3556 21.9999 10.0673 21.9999 9.71106C21.9999 4.37978 17.6631 0 12.3318 0Z M13.5262 20.5548L10.3986 12.1718V3.26539C10.3986 2.90914 10.1103 2.62085 9.75405 2.62085C4.42275 2.62085 0 6.95765 0 12.2889C0 17.6202 4.42275 22 9.75405 22C10.921 22 12.0634 21.7929 13.1492 21.385C13.4822 21.2598 13.6509 20.8884 13.5262 20.5548Z M21.3555 11.6445H12.3319C12.1204 11.6445 11.9228 11.7483 11.8019 11.922C11.6817 12.0957 11.654 12.3173 11.7282 12.5155L14.8968 21.0081C14.9572 21.1679 15.0781 21.2976 15.2342 21.3681C15.3904 21.4391 15.5664 21.4451 15.7277 21.3845C19.4791 19.9726 22 16.2922 22 12.289C22 11.9327 21.7117 11.6445 21.3555 11.6445Z';

export const ninjaSearch =
  'M10.56 3.84C6.84865 3.84 3.84 6.84865 3.84 10.56C3.84 14.2714 6.84865 17.28 10.56 17.28C14.2714 17.28 17.28 14.2714 17.28 10.56C17.28 6.84865 14.2714 3.84 10.56 3.84ZM0 10.56C0 4.72787 4.72787 0 10.56 0C16.3921 0 21.12 4.72787 21.12 10.56C21.12 12.7824 20.4335 14.8444 19.2609 16.5456L23.4376 20.7224C24.1875 21.4722 24.1875 22.6878 23.4376 23.4376C22.6878 24.1875 21.4722 24.1875 20.7224 23.4376L16.5456 19.2609C14.8444 20.4335 12.7824 21.12 10.56 21.12C4.72787 21.12 0 16.3921 0 10.56Z';

export const ninjaFilter =
  'M7.38889 12H11.6111V10H7.38889V12ZM0 0V2.00002H19V0H0ZM3.16666 7.00001H15.8333V5.00002H3.16666V7.00001Z';

export const ninjaCheckboxChecked =
  'M4.57143 2.28571C3.30906 2.28571 2.28571 3.30906 2.28571 4.57143V11.4286C2.28571 12.6909 3.30906 13.7143 4.57143 13.7143H11.4286C12.6909 13.7143 13.7143 12.6909 13.7143 11.4286V4.57143C13.7143 3.30906 12.6909 2.28571 11.4286 2.28571H4.57143ZM0 4.57143C0 2.0467 2.0467 0 4.57143 0H11.4286C13.9533 0 16 2.0467 16 4.57143V11.4286C16 13.9533 13.9533 16 11.4286 16H4.57143C2.0467 16 0 13.9533 0 11.4286V4.57143Z M12.2367 4.90602C12.683 5.35234 12.683 6.07595 12.2367 6.52227L7.66528 11.0937C7.21897 11.54 6.49535 11.54 6.04904 11.0937L3.76332 8.80798C3.31701 8.36167 3.31701 7.63805 3.76332 7.19174C4.20964 6.74542 4.93325 6.74542 5.37957 7.19174L6.85716 8.66933L10.6205 4.90602C11.0668 4.45971 11.7904 4.45971 12.2367 4.90602Z';

export const ninjaCheckbox =
  'M4.57143 2.28571C3.30906 2.28571 2.28571 3.30906 2.28571 4.57143V11.4286C2.28571 12.6909 3.30906 13.7143 4.57143 13.7143H11.4286C12.6909 13.7143 13.7143 12.6909 13.7143 11.4286V4.57143C13.7143 3.30906 12.6909 2.28571 11.4286 2.28571H4.57143ZM0 4.57143C0 2.0467 2.0467 0 4.57143 0H11.4286C13.9533 0 16 2.0467 16 4.57143V11.4286C16 13.9533 13.9533 16 11.4286 16H4.57143C2.0467 16 0 13.9533 0 11.4286V4.57143Z';

export const selectDropDownIcon =
  'M23.5545 5.46775C23.2578 5.15586 22.9061 5 22.4998 5H1.50013C1.09361 5 0.742215 5.15582 0.445267 5.46775C0.148354 5.78 0 6.14922 0 6.57595C0 7.00265 0.14832 7.37184 0.445267 7.6838L10.9451 18.7137C11.2424 19.0256 11.5938 19.1818 12 19.1818C12.4062 19.1818 12.758 19.0257 13.0546 18.7137L23.5545 7.68377C23.8511 7.37187 24 7.00269 24 6.57588C24 6.14926 23.8511 5.78004 23.5545 5.46775H23.5545Z';
