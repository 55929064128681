import { Select } from 'antd';
import React from 'react';
import { countries } from './countries';
import UrlHelper from 'common/utils/url-helper';

const CountriesSelect = (props) => {
  const {
    value = [],
    onChange,
    multiple = false,
    showPhoneCode,
    hideFlag = false,
    ...rest
  } = props;

  const handleChange = (value) => {
    onChange?.(value);
  };

  /**
   * To turn of autocomplete
   */
  const handleOnFocus = (e) => {
    e.target.setAttribute('autocomplete', 'one-time-code');
  };

  return (
    <Select
      style={{ maxWidth: '40%' }}
      placeholder='Select Country'
      maxTagCount='responsive'
      onChange={handleChange}
      value={value}
      allowClear
      showSearch
      onFocus={handleOnFocus}
      autoComplete='off'
      optionFilterProp='label'
      {...rest}
    >
      {countries.map((opt) => {
        return (
          <Select.Option value={opt.code} label={opt.name}>
            <div className='flex gap-2 items-center justify-start pr-4'>
              {!hideFlag ? (
                <img src={UrlHelper.getFlagIconUrl(opt.code)} className='object-cover w-4' />
              ) : null}
              <div className='flex-grow'>
                {opt.name}
                {showPhoneCode ? ` (${opt.phone_code})` : null}
              </div>
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CountriesSelect;
