import React from 'react';
import { makeStyles } from '@material-ui/core';

const TableButton = (props) => {
  const classes = useStyles();
  const { children, handleClick, variant = 'contained-secondary', disabled, ...rest } = props;

  return (
    <button
      className={classes.root}
      disabled={disabled}
      onClick={handleClick}
      style={{ ...styles[disabled ? 'disabled-button' : variant] }}
      {...rest}
    >
      {children}
    </button>
  );
};

const styles = {
  'contained-primary': {
    backgroundColor: '#2c7df0',
    color: '#fff',
  },
  'contained-secondary': {
    backgroundColor: '#ff4e00',
    color: '#fff',
  },
  'disabled-button': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  'outlined-secondary': {
    border: `1px solid #ff4e00`,
    color: '#ff4e00',
  },
};

export default TableButton;

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '12px ',
    fontWeight: '400',
    borderRadius: 6,
    width: '100%',
    height: '2.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
}));
