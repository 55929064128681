import ninjaApi from 'store/redux/apis/ninja.api';

export const postbacksApi = ninjaApi.injectEndpoints({
  tagTypes: ['Postbacks'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * List all postbacks
     */
    listPostbacks: builder.query({
      query: (body) => ({
        url: '/workspace/postbacks/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Postbacks'],
    }),
    /**
     * Get Single Postback
     */
    getPostback: builder.query({
      query: (id) => ({
        url: '/workspace/postbacks/get',
        method: 'POST',
        body: { id },
      }),
      providesTags: ['Postbacks'],
    }),
    /**
     * Load Postback Logs
     */
    postbackLogs: builder.query({
      query: (body) => ({
        url: '/workspace/postbacks/logs',
        method: 'POST',
        body,
      }),
    }),
    /**
     * Create new postback
     */
    createPostback: builder.mutation({
      query: (body) => ({
        url: '/workspace/postbacks/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Postbacks'],
    }),
    /**
     * Update existing postback
     */
    updatePostback: builder.mutation({
      query: (body) => ({
        url: '/workspace/postbacks/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Postbacks'],
    }),
    /**
     * Delete Postback
     */
    deletePostback: builder.mutation({
      query: (body) => ({
        url: '/workspace/postbacks/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Postbacks'],
    }),

    /**
     *OutgoingPostback data
     */
    outgoingPostback: builder.query({
      query: (body) => ({
        url: '/workspace/outgoing-postbacks/index',
        method: 'POST',
        body,
      }),
      providesTags: ['OutgoingPostbacks'],
    }),

    /**
     * OutgoingPostbackLogs data
     */
    outgoingPostbackLogs: builder.query({
      query: (body) => ({
        url: '/workspace/outgoing-postbacks/logs',
        method: 'POST',
        body,
      }),
      providesTags: ['OutgoingPostbackLog'],
    }),

    /**
     *Create Outgoing Postback
     */
    createOutgoingPostback: builder.mutation({
      query: (data) => ({
        url: '/workspace/outgoing-postbacks/create',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['OutgoingPostbacks'],
    }),
    /**
     * Delete Outgoing Postback
     */
    deleteOutgoingPostback: builder.mutation({
      query: (body) => ({
        url: '/workspace/outgoing-postbacks/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OutgoingPostbacks'],
    }),
    /**
     * Update existing outgoing postback
     */
    updateOutgoingPostback: builder.mutation({
      query: (body) => ({
        url: '/workspace/outgoing-postbacks/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OutgoingPostbacks'],
    }),
    /**
     * get single outgoing postback
     */
    getOutgoingPostback: builder.query({
      query: (body) => ({
        url: '/workspace/outgoing-postbacks/get',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OutgoingPostbacks'],
    }),

    /**
     * get single postback details
     */
    getPostbackDetails: builder.query({
      query: (body) => ({
        url: '/workspace/postbacks/details',
        method: 'POST',
        body,
      }),
      providesTags: [],
    }),
  }),
});

export const {
  useListPostbacksQuery,
  useGetPostbackQuery,
  useCreatePostbackMutation,
  useUpdatePostbackMutation,
  useDeletePostbackMutation,
  useOutgoingPostbackQuery,
  useOutgoingPostbackLogsQuery,
  useCreateOutgoingPostbackMutation,
  useDeleteOutgoingPostbackMutation,
  useUpdateOutgoingPostbackMutation,
  useGetOutgoingPostbackQuery,
  useGetPostbackDetailsQuery,
} = postbacksApi;
