import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { DialogTitle, Dialog as DialogBase, Typography, DialogContent } from '@material-ui/core';

const Dialog = (props) => {
  const {
    title,
    open = false,
    titleVariant = 'h3',
    className = null,
    titleProps = {},
    ...rest
  } = props;

  return (
    <DialogBase open={open} {...rest}>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant={titleVariant} {...titleProps}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent className={clsx(className)}>{props.children}</DialogContent>
    </DialogBase>
  );
};

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
  titleProps: PropTypes.object,
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

export default Dialog;
