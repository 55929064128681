import React from 'react';
import { useWidget } from './Widget';
import { Button, Card, Divider, Dropdown, Space } from 'antd';
import NetworkAccountSelect from 'components/Selects/NetworkAccountSelect/NetworkAccountSelect';
import Icon from '@mdi/react';
import { mdiFloppy } from '@mdi/js';
import StatusSelect from 'components/Selects/StatusSelect';
import DateRangePicker from 'components/form/fields/DateRangePicker';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const WidgetFilter = (props) => {
  const { widget_key, filters, setFilters, runFilter, default_filter } = useWidget();
  const { setDate } = useWidgetDate(widget_key);

  const handleAccountFiltersChange = (account_ids) => {
    setFilters({
      ...filters,
      network_account_ids: account_ids,
    });
  };

  const handleStatusChange = (statuses) => {
    setFilters({
      ...filters,
      statuses: statuses,
    });
  };

  const resetFilter = () => {
    setFilters(default_filter);
    setDate({ date_from: new Date(), date_to: new Date() });
    runFilter();
  };

  return (
    <Card size='small' className='w-[400px]'>
      <Space direction='vertical' className='w-full'>
        <div className='grid grid-cols-2 gap-2'>
          {!props.disableDateFilter ? (
            <div className='col-span-2 md:col-span-1'>
              <DateRangePicker variant='input' date_key={widget_key} />
            </div>
          ) : null}
          {!props.disableStatusFilter ? (
            <div className='col-span-2 md:col-span-1'>
              <StatusSelect value={filters.statuses} onChange={handleStatusChange} />
            </div>
          ) : null}
        </div>

        {!props.disableAccountsFilter ? (
          <NetworkAccountSelect
            value={filters.network_account_ids}
            onChange={handleAccountFiltersChange}
            maxTagCount={10}
          />
        ) : null}

        <Divider className='my-2' />
        <div className='flex justify-between'>
          <Button type='dashed' onClick={resetFilter}>
            Reset
          </Button>
          <Dropdown.Button
            onClick={runFilter}
            style={{ width: 'unset' /* Or justify not working */ }}
            menu={{
              items: [
                {
                  key: 'save',
                  label: 'Save',
                  icon: <Icon path={mdiFloppy} />,
                  onClick: () => console.log('save'),
                },
              ],
            }}
            type='primary'
          >
            Filter
          </Dropdown.Button>
        </div>
      </Space>
    </Card>
  );
};

export default WidgetFilter;
