import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { mdiMenu, mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import HeaderButton from 'components/layouts/components/Header/HeaderButton';
import SettingsMenu from 'components/layouts/components/Header/SettingsMenu';
import WorkspaceSwitcher from 'components/layouts/components/Header/WorkspaceSwitcher';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSidebar } from 'store/redux/states/ui/ui.hooks';
import P from 'router/paths';
import HeaderNotifications from './HeaderNotifications';
import AccountChooser from './AccountChooser';
import { useCronUpdateTimeQuery } from 'store/redux/apis/cron-update-time.api';
import ninjaApi from 'store/redux/apis';
import { usePermissions } from 'store/redux/apis/workspace';
import HelpMenu from './HelpMenu/HelpMenu';
import { Select } from 'antd';
import useAuth from 'store/redux/hooks/useAuth';
import { useRevenueSourceUpdateMutation } from 'store/redux/apis/user.api';

const Header = () => {
  const [, , toggleSidebar] = useSidebar();
  const { canEdit } = usePermissions();
  const [revenueSourceUpdate] = useRevenueSourceUpdateMutation();
  const { user } = useAuth();

  const classes = useStyles();

  const handleResource = (value) => {
    revenueSourceUpdate({ source: value })
      .unwrap()
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar disableGutters className={classes.toolBar}>
        <div className='w-full flex items-center'>
          <Hidden smUp>
            <div>
              <IconButton onClick={toggleSidebar} className={classes.toggleSidebarButton}>
                <Icon path={mdiMenu} />
              </IconButton>
            </div>
          </Hidden>
          <div>
            <AccountChooser />
          </div>
          <div className='div-flex-grow' />
          <div>
            <div className='flex-container items-center'>
              <Hidden smDown>
                <div className={classes.select} id='revenue'>
                  <Select
                    className='flex items-center justify-center'
                    size='middle'
                    popupMatchSelectWidth={false}
                    defaultValue={user.revenue_source}
                    onChange={handleResource}
                    options={[
                      {
                        label: (
                          <div className='flex gap-2 items-center'>
                            <Icon path={mdiWeb} className='text-orange' />
                            Revenue Data From Ad Networks
                          </div>
                        ),
                        value: 'network',
                      },
                      {
                        label: (
                          <div className='flex gap-2 items-center'>
                            <img src='/icons/dataninja/dataninja.svg' className='w-[18px]' />
                            Revenue Data From Ninja CRM
                          </div>
                        ),
                        value: 'ninja',
                      },
                    ]}
                  />
                </div>
                <HeaderButton
                  disabled={!canEdit('ads')}
                  color={!canEdit('ads') ? 'xlight' : 'orange'}
                  component={Link}
                  to={P.AD.CREATE}
                >
                  + Create Ad
                </HeaderButton>
                <SettingsMenu />
                <HelpMenu />
                <HeaderNotifications />
              </Hidden>
              <WorkspaceSwitcher />
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 1050,
    background: theme.colors.white,
    paddingRight: '0px !important',
    transition: '0.5s ease-in-out',
    boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.101961)',
    position: 'sticky',
    '& .ant-select-selection-item': {
      color: '#ff4e00',
      fontWeight: 600,
      fontSize: 12,
    },
    '& .ant-select-arrow': {
      color: '#ff4e00',
    },
    '& .ant-segmented': {
      backgroundColor: ' #F1F5F8',
      fontWeight: 500,
      color: 'rgba(44, 67, 90, 0.8)',
    },
    '& .ant-segmented-item-selected': {
      backgroundColor: 'rgb(255, 78, 0)',
      color: 'white',
    },
    '& .ant-radio-wrapper .ant-radio-inner::after': {
      width: '14px',
      height: '14px',
      marginBlockStart: '-7px',
      marginInlineStart: '-7px',
    },
    '& .ant-radio-wrapper span.ant-radio+*': {
      paddingInlineStart: '3px',
      paddingInlineEnd: '3px',
      fontSize: 12,
      fontWeight: 500,
    },
    '& .ant-radio-wrapper .ant-radio-inner ': {
      width: '14px',
      height: '14px',
    },
  },
  toggleSidebarButton: {
    margin: theme.spacing(0, 2),
  },
  toolBar: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 4, 0, 4),
    },
  },
  createWebsiteContainer: {
    position: 'relative',
    '& h5': {
      color: theme.colors.light,
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(250, 250, 250, 0.8)',
      zIndex: 1,
      padding: '50px 0px 0px 54px',
      fontWeight: 700,
    },
  },
  select: {
    borderRight: `2px solid ${theme.colors.bgColorLight}`,
    height: '64px',
    padding: '8px 24px',
    display: 'flex',
  },
}));
