import FullPageOverlay from 'components/Loaders/FullPageOverlay/FullPageOverlay';
import React from 'react';
import { Route as BaseRoute, Switch } from 'react-router-dom';
import { usePermissions } from 'store/redux/apis/workspace';
import useAuth from 'store/redux/hooks/useAuth';
import useMiddleware from './useMiddleware';

export const renderRoutes = (routes, extraProps = {}, switchProps = {}) => {
  return (
    <Switch {...switchProps}>
      {routes.map((route, i) => {
        const { render: routeRender, component: Component, ...rest } = route;

        const render = (props) => {
          if (routeRender) {
            return routeRender({ ...props, ...extraProps, route: route });
          } else if (Component) {
            return <Component {...props} {...extraProps} route={route} />;
          }
        };

        return <Route key={route.key || i} render={render} {...rest} />;
      })}
    </Switch>
  );
};

export const Route = (props) => {
  const { middleware, ...rest } = props;
  const { checkAll } = useMiddleware();
  const { loading } = useAuth();
  const { loading: isPermissionsLoading } = usePermissions();

  if (loading || isPermissionsLoading) {
    return <FullPageOverlay />;
  }

  /**
   * Check middleware here
   */
  const result = checkAll(middleware);
  if (result && result.onError) {
    result.onError();
  }

  return <BaseRoute {...rest} />;
};
