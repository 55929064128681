import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useNinjaTable } from '../NinjaTable';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';

const TextFormatter = (props) => {
  const {
    type = 'normal',
    color = 'black',
    onClick,
    fontSize,
    fontWeight,
    emptyText,
    className,
    column,
    row,
    before,
    after,
    defaultValue,
    leadCount,
    uniqueField,
  } = props;
  const value = props.value || row[column.id] || defaultValue;
  const { id: formId } = useParams();
  const history = useHistory();

  let calculatedFontSize = type === 'primary' ? 16 : type === 'secondary' ? 11 : 13;
  let calculatedFontWeight = type === 'primary' ? 600 : 350;

  const { setFilters, setPagination } = useNinjaTable();

  const classes = useStyles({
    color: color,
    fontSize: fontSize || calculatedFontSize,
    fontWeight: fontWeight || calculatedFontWeight,
  });

  if (typeof value === 'object' && value.option_id && typeof value.value !== 'undefined') {
    return (
      <Typography className={`${classes.textFormatter} ${className}`} onClick={onClick}>
        {before}
        {value.value}
        {after}
      </Typography>
    );
  }

  if (value === null || value === undefined) {
    return (
      <Typography className={classes.emptyText} onClick={onClick}>
        {emptyText}
      </Typography>
    );
  }
  const handleFilter = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
    }));
    setFilters({ [column.key]: value });
    history.push(P.CRM.DATA_LIST.replace(':id', formId));
  };

  return (
    <div className='flex w-full justify-between items-center'>
      <Typography className={`${classes.textFormatter} ${className}`} onClick={onClick}>
        {before}
        {value}
        {after}
      </Typography>
      {uniqueField && leadCount && (
        <div
          onClick={handleFilter}
          className='text-xs h-4 p-1 min-w-[1rem] bg-color-light text-white rounded-full flex items-center justify-center cursor-pointer'
        >
          {leadCount}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  textFormatter: {
    fontFamily: 'fRegular',
    color: (props) => props.color,
    fontSize: (props) => props.fontSize,
    fontWeight: (props) => props.fontWeight,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'flex',
    gap: '0.25rem',
    alignItems: 'center',
  },
  emptyText: {
    fontSize: 12,
    fontWeight: 350,
    color: '#999999',
  },
}));

export default TextFormatter;
