import useFastspringButton from '@lib/fastspring/useFastSpringButton';
import { Button } from 'antd';
import React from 'react';
import useBilling from 'store/redux/states/billing/hooks/useBilling';

const PlanCard = (props) => {
  const { plan, yearly, isCurrent, onCheckout, onError, update, button_text } = props;
  const { billing, isLoading: isBillinLoading } = useBilling();

  const { isScriptLoading, isSubscriptionLoading, checkout } = useFastspringButton({
    onSuccess: onCheckout,
    onError: onError,
    update: update,
    planCode: plan.code,
  });

  let yearlyDisabled = false;
  if (yearly && !plan.yearly_price) {
    yearlyDisabled = true;
  }

  const loading = isScriptLoading || isSubscriptionLoading || isBillinLoading;

  return (
    <div class='bg-white flex justify-between flex-col border-color-xlight border rounded-lg w-70 shadow-sm p-5 transition duration-500 ease-in-out hover:shadow-2xl hover:border-gray-200 cursor-pointer'>
      <div>
        <div class='flex flex-col sm:h-44 !border-b border-color-xlight justify-between'>
          <div class='mb-4'>
            <div class='flex gap-2'>
              <img class='w-12' src='/icons/dataninja/dataninja.svg' />
              <div class='gap-1 grid'>
                <span class='text-xl font-bold'>{plan.name}</span>
                <span class='text-sm text-lightBlue font-normal !leading-4'>
                  {plan.description}
                </span>
              </div>
            </div>
          </div>
          <div class='py-5'>
            <div class='flex items-end gap-1.5 justify-end'>
              <div>
                <span class='text-4xl font-semibold text-orange'>
                  ${yearly && plan.yearly_price ? plan.yearly_price : plan.price}
                </span>
              </div>
              <span class='text-sm'>/</span>
              <span class='text-sm tracking-wide font-thin'>{yearly ? 'Yearly' : 'Month'}</span>
            </div>

            <span class='flex justify-end text-xs mt-2 font-semibold'>{plan.price_caption}</span>
          </div>
        </div>
        <div class=' gap-2 pt-5  grid'>
          {plan.tags.map((feature) => {
            return (
              <div class='flex items-center gap-1'>
                <img className='w-5' src='/icons/success.svg' alt='' />
                <span class='text-xs font-normal'>{feature}</span>
              </div>
            );
          })}
        </div>
      </div>
      <Button
        loading={loading}
        disabled={yearlyDisabled || isCurrent}
        type='primary'
        className='mt-5'
        onClick={() => {
          checkout(yearly ? `${plan.code}-yearly` : plan.code);
        }}
      >
        {isCurrent ? 'Current Plan' : button_text}
      </Button>
    </div>
  );
};

export default PlanCard;
