import { Badge, Popover, withStyles } from '@material-ui/core';
import { mdiBellAlert } from '@mdi/js';
import Icon from '@mdi/react';
import HeaderButton from 'components/layouts/components/Header/HeaderButton';
import NotificationsPanel from 'modules/NotificationsModule/components/NotificationsPanel';
import React, { useState } from 'react';

const HeaderNotifications = (props) => {
  const unread = [];
  const [anchorPosition, setAnchorPosition] = useState(null);
  const opened = Boolean(anchorPosition);
  const isLoading = true;

  const handleClose = () => {
    setAnchorPosition(null);
  };

  const handleClick = (e) => {
    if (opened) return;
    /* Mark As Seen */
    setAnchorPosition({
      left: e.clientX,
      top: e.clientY,
    });
  };

  return (
    <HeaderButton onClick={handleClick} color='darkText'>
      <StyledBadge
        overlap='circular'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          badge: 'bg-orange text-white',
        }}
        badgeContent={isLoading ? null : unread ? unread : null}
      >
        <Icon size='32px' path={mdiBellAlert} />
      </StyledBadge>

      <Popover
        open={opened}
        anchorReference='anchorPosition'
        anchorPosition={anchorPosition}
        onClose={handleClose}
      >
        <NotificationsPanel />
      </Popover>
    </HeaderButton>
  );
};

const StyledBadge = withStyles((theme) => ({
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1s infinite ease-in-out',
      border: '1px solid #ff4e00',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

export default HeaderNotifications;
