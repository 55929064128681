import ninjaApi from 'store/redux/apis/ninja.api';

export const adCampaignApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['AdCampaign'],
  endpoints: (builder) => ({
    /* Change Campaign Status */
    changeCampaignStatus: builder.mutation({
      query: (body) => ({
        url: '/network/campaign/change-status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AdCampaign'],
    }),

    /* Change Campaign budget */
    changeCampaignBudget: builder.mutation({
      query: (body) => ({
        url: '/network/campaign/change-budget',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Reporting'],
    }),

    /* search Campaign */
    searchCampaign: builder.query({
      query: (body) => ({
        url: '/network/campaign/search',
        method: 'POST',
        body,
      }),
    }),

    /* Fetch all campaign  details */
    campaignDetails: builder.query({
      query: (body) => ({
        url: '/network/campaign/details',
        method: 'POST',
        body,
      }),
      providesTags: ['AdCampaign'],
    }),

    // copy campaign
    copyCampaign: builder.mutation({
      query: (body) => ({
        url: '/network/campaign/copy',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['KeywordReporting'],
    }),
  }),
});

export const {
  useChangeCampaignStatusMutation,
  useChangeCampaignBudgetMutation,
  useSearchCampaignQuery,
  useCampaignDetailsQuery,
  useCopyCampaignMutation,
} = adCampaignApi;
