export const countries = [
  { name: 'Afghanistan', phone_code: '+93', code: 'af' },
  { name: 'Albania', phone_code: '+355', code: 'al' },
  { name: 'Algeria', phone_code: '+213', code: 'dz' },
  { name: 'American Samoa', phone_code: '+1-684', code: 'as' },
  { name: 'Andorra', phone_code: '+376', code: 'ad' },
  { name: 'Angola', phone_code: '+244', code: 'ao' },
  { name: 'Anguilla', phone_code: '+1-264', code: 'ai' },
  { name: 'Antarctica', phone_code: '+672', code: 'aq' },
  { name: 'Antigua and Barbuda', phone_code: '+1-268', code: 'ag' },
  { name: 'Argentina', phone_code: '+54', code: 'ar' },
  { name: 'Armenia', phone_code: '+374', code: 'am' },
  { name: 'Aruba', phone_code: '+297', code: 'aw' },
  { name: 'Australia', phone_code: '+61', code: 'au' },
  { name: 'Austria', phone_code: '+43', code: 'at' },
  { name: 'Azerbaijan', phone_code: '+994', code: 'az' },
  { name: 'Bahamas', phone_code: '+1-242', code: 'bs' },
  { name: 'Bahrain', phone_code: '+973', code: 'bh' },
  { name: 'Bangladesh', phone_code: '+880', code: 'bd' },
  { name: 'Barbados', phone_code: '+1-246', code: 'bb' },
  { name: 'Belarus', phone_code: '+375', code: 'by' },
  { name: 'Belgium', phone_code: '+32', code: 'be' },
  { name: 'Belize', phone_code: '+501', code: 'bz' },
  { name: 'Benin', phone_code: '+229', code: 'bj' },
  { name: 'Bermuda', phone_code: '+1-441', code: 'bm' },
  { name: 'Bhutan', phone_code: '+975', code: 'bt' },
  { name: 'Bolivia', phone_code: '+591', code: 'bo' },
  { name: 'Bosnia and Herzegovina', phone_code: '+387', code: 'ba' },
  { name: 'Botswana', phone_code: '+267', code: 'bw' },
  { name: 'Brazil', phone_code: '+55', code: 'br' },
  { name: 'Brunei', phone_code: '+673', code: 'bn' },
  { name: 'Bulgaria', phone_code: '+359', code: 'bg' },
  { name: 'Burkina Faso', phone_code: '+226', code: 'bf' },
  { name: 'Burundi', phone_code: '+257', code: 'bi' },
  { name: 'Cambodia', phone_code: '+855', code: 'kh' },
  { name: 'Cameroon', phone_code: '+237', code: 'cm' },
  { name: 'Canada', phone_code: '+1', code: 'ca' },
  { name: 'Cape Verde', phone_code: '+238', code: 'cv' },
  { name: 'Cayman Islands', phone_code: '+1-345', code: 'ky' },
  { name: 'Central African Republic', phone_code: '+236', code: 'cf' },
  { name: 'Chad', phone_code: '+235', code: 'td' },
  { name: 'Chile', phone_code: '+56', code: 'cl' },
  { name: 'China', phone_code: '+86', code: 'cn' },
  { name: 'Christmas Island', phone_code: '+61', code: 'cx' },
  { name: 'Cocos Islands', phone_code: '+61', code: 'cc' },
  { name: 'Colombia', phone_code: '+57', code: 'co' },
  { name: 'Comoros', phone_code: '+269', code: 'km' },
  { name: 'Cook Islands', phone_code: '+682', code: 'ck' },
  { name: 'Costa Rica', phone_code: '+506', code: 'cr' },
  { name: 'Croatia', phone_code: '+385', code: 'hr' },
  { name: 'Cuba', phone_code: '+53', code: 'cu' },
  { name: 'Curacao', phone_code: '+599', code: 'cw' },
  { name: 'Cyprus', phone_code: '+357', code: 'cy' },
  { name: 'Czech Republic', phone_code: '+420', code: 'cz' },
  { name: 'Denmark', phone_code: '+45', code: 'dk' },
  { name: 'Djibouti', phone_code: '+253', code: 'dj' },
  { name: 'Dominica', phone_code: '+1-767', code: 'dm' },
  { name: 'East Timor', phone_code: '+670', code: 'tl' },
  { name: 'Ecuador', phone_code: '+593', code: 'ec' },
  { name: 'Egypt', phone_code: '+20', code: 'eg' },
  { name: 'El Salvador', phone_code: '+503', code: 'sv' },
  { name: 'Equatorial Guinea', phone_code: '+240', code: 'gq' },
  { name: 'Eritrea', phone_code: '+291', code: 'er' },
  { name: 'Estonia', phone_code: '+372', code: 'ee' },
  { name: 'Ethiopia', phone_code: '+251', code: 'et' },
  { name: 'Falkland Islands', phone_code: '+500', code: 'fk' },
  { name: 'Faroe Islands', phone_code: '+298', code: 'fo' },
  { name: 'Fiji', phone_code: '+679', code: 'fj' },
  { name: 'Finland', phone_code: '+358', code: 'fi' },
  { name: 'France', phone_code: '+33', code: 'fr' },
  { name: 'French Polynesia', phone_code: '+689', code: 'pf' },
  { name: 'Gabon', phone_code: '+241', code: 'ga' },
  { name: 'Gambia', phone_code: '+220', code: 'gm' },
  { name: 'Georgia', phone_code: '+995', code: 'ge' },
  { name: 'Germany', phone_code: '+49', code: 'de' },
  { name: 'Ghana', phone_code: '+233', code: 'gh' },
  { name: 'Gibraltar', phone_code: '+350', code: 'gi' },
  { name: 'Greece', phone_code: '+30', code: 'gr' },
  { name: 'Greenland', phone_code: '+299', code: 'gl' },
  { name: 'Grenada', phone_code: '+1-473', code: 'gd' },
  { name: 'Guam', phone_code: '+1-671', code: 'gu' },
  { name: 'Guatemala', phone_code: '+502', code: 'gt' },
  { name: 'Guernsey', phone_code: '+44-1481', code: 'gg' },
  { name: 'Guinea', phone_code: '+224', code: 'gn' },
  { name: 'Guinea-Bissau', phone_code: '+245', code: 'gw' },
  { name: 'Guyana', phone_code: '+592', code: 'gy' },
  { name: 'Haiti', phone_code: '+509', code: 'ht' },
  { name: 'Honduras', phone_code: '+504', code: 'hn' },
  { name: 'Hong Kong', phone_code: '+852', code: 'hk' },
  { name: 'Hungary', phone_code: '+36', code: 'hu' },
  { name: 'Iceland', phone_code: '+354', code: 'is' },
  { name: 'India', phone_code: '+91', code: 'in' },
  { name: 'Indonesia', phone_code: '+62', code: 'id' },
  { name: 'Iran', phone_code: '+98', code: 'ir' },
  { name: 'Iraq', phone_code: '+964', code: 'iq' },
  { name: 'Ireland', phone_code: '+353', code: 'ie' },
  { name: 'Isle of Man', phone_code: '+44-1624', code: 'im' },
  { name: 'Israel', phone_code: '+972', code: 'il' },
  { name: 'Italy', phone_code: '+39', code: 'it' },
  { name: 'Ivory Coast', phone_code: '+225', code: 'ci' },
  { name: 'Jamaica', phone_code: '+1-876', code: 'jm' },
  { name: 'Japan', phone_code: '+81', code: 'jp' },
  { name: 'Jersey', phone_code: '+44-1534', code: 'je' },
  { name: 'Jordan', phone_code: '+962', code: 'jo' },
  { name: 'Kazakhstan', phone_code: '+7', code: 'kz' },
  { name: 'Kenya', phone_code: '+254', code: 'ke' },
  { name: 'Kiribati', phone_code: '+686', code: 'ki' },
  { name: 'Kosovo', phone_code: '+383', code: 'xk' },
  { name: 'Kuwait', phone_code: '+965', code: 'kw' },
  { name: 'Kyrgyzstan', phone_code: '+996', code: 'kg' },
  { name: 'Laos', phone_code: '+856', code: 'la' },
  { name: 'Latvia', phone_code: '+371', code: 'lv' },
  { name: 'Lebanon', phone_code: '+961', code: 'lb' },
  { name: 'Lesotho', phone_code: '+266', code: 'ls' },
  { name: 'Liberia', phone_code: '+231', code: 'lr' },
  { name: 'Libya', phone_code: '+218', code: 'ly' },
  { name: 'Liechtenstein', phone_code: '+423', code: 'li' },
  { name: 'Lithuania', phone_code: '+370', code: 'lt' },
  { name: 'Luxembourg', phone_code: '+352', code: 'lu' },
  { name: 'Macau', phone_code: '+853', code: 'mo' },
  { name: 'Macedonia', phone_code: '+389', code: 'mk' },
  { name: 'Madagascar', phone_code: '+261', code: 'mg' },
  { name: 'Malawi', phone_code: '+265', code: 'mw' },
  { name: 'Malaysia', phone_code: '+60', code: 'my' },
  { name: 'Maldives', phone_code: '+960', code: 'mv' },
  { name: 'Mali', phone_code: '+223', code: 'ml' },
  { name: 'Malta', phone_code: '+356', code: 'mt' },
  { name: 'Marshall Islands', phone_code: '+692', code: 'mh' },
  { name: 'Mauritania', phone_code: '+222', code: 'mr' },
  { name: 'Mauritius', phone_code: '+230', code: 'mu' },
  { name: 'Mayotte', phone_code: '+262', code: 'yt' },
  { name: 'Mexico', phone_code: '+52', code: 'mx' },
  { name: 'Micronesia', phone_code: '+691', code: 'fm' },
  { name: 'Moldova', phone_code: '+373', code: 'md' },
  { name: 'Monaco', phone_code: '+377', code: 'mc' },
  { name: 'Mongolia', phone_code: '+976', code: 'mn' },
  { name: 'Montenegro', phone_code: '+382', code: 'me' },
  { name: 'Montserrat', phone_code: '+1-664', code: 'ms' },
  { name: 'Morocco', phone_code: '+212', code: 'ma' },
  { name: 'Mozambique', phone_code: '+258', code: 'mz' },
  { name: 'Myanmar', phone_code: '+95', code: 'mm' },
  { name: 'Namibia', phone_code: '+264', code: 'na' },
  { name: 'Nauru', phone_code: '+674', code: 'nr' },
  { name: 'Nepal', phone_code: '+977', code: 'np' },
  { name: 'Netherlands', phone_code: '+31', code: 'nl' },
  { name: 'New Caledonia', phone_code: '+687', code: 'nc' },
  { name: 'New Zealand', phone_code: '+64', code: 'nz' },
  { name: 'Nicaragua', phone_code: '+505', code: 'ni' },
  { name: 'Niger', phone_code: '+227', code: 'ne' },
  { name: 'Nigeria', phone_code: '+234', code: 'ng' },
  { name: 'Niue', phone_code: '+683', code: 'nu' },
  { name: 'North Korea', phone_code: '+850', code: 'kp' },
  { name: 'Norway', phone_code: '+47', code: 'no' },
  { name: 'Oman', phone_code: '+968', code: 'om' },
  { name: 'Pakistan', phone_code: '+92', code: 'pk' },
  { name: 'Palau', phone_code: '+680', code: 'pw' },
  { name: 'Palestine', phone_code: '+970', code: 'ps' },
  { name: 'Panama', phone_code: '+507', code: 'pa' },
  { name: 'Papua New Guinea', phone_code: '+675', code: 'pg' },
  { name: 'Paraguay', phone_code: '+595', code: 'py' },
  { name: 'Peru', phone_code: '+51', code: 'pe' },
  { name: 'Philippines', phone_code: '+63', code: 'ph' },
  { name: 'Pitcairn', phone_code: '+64', code: 'pn' },
  { name: 'Poland', phone_code: '+48', code: 'pl' },
  { name: 'Portugal', phone_code: '+351', code: 'pt' },
  { name: 'Puerto Rico', phone_code: '+1-787, 1-939', code: 'pr' },
  { name: 'Qatar', phone_code: '+974', code: 'qa' },
  { name: 'Republic of the Congo', phone_code: '+242', code: 'cg' },
  { name: 'Reunion', phone_code: '+262', code: 're' },
  { name: 'Romania', phone_code: '+40', code: 'ro' },
  { name: 'Russia', phone_code: '+7', code: 'ru' },
  { name: 'Rwanda', phone_code: '+250', code: 'rw' },
  { name: 'Saint Barthelemy', phone_code: '+590', code: 'bl' },
  { name: 'Saint Helena', phone_code: '+290', code: 'sh' },
  { name: 'Saint Kitts and Nevis', phone_code: '+1-869', code: 'kn' },
  { name: 'Saint Lucia', phone_code: '+1-758', code: 'lc' },
  { name: 'Saint Martin', phone_code: '+590', code: 'mf' },
  { name: 'Samoa', phone_code: '+685', code: 'ws' },
  { name: 'San Marino', phone_code: '+378', code: 'sm' },
  { name: 'Sao Tome and Principe', phone_code: '+239', code: 'st' },
  { name: 'Saudi Arabia', phone_code: '+966', code: 'sa' },
  { name: 'Senegal', phone_code: '+221', code: 'sn' },
  { name: 'Serbia', phone_code: '+381', code: 'rs' },
  { name: 'Seychelles', phone_code: '+248', code: 'sc' },
  { name: 'Sierra Leone', phone_code: '+232', code: 'sl' },
  { name: 'Singapore', phone_code: '+65', code: 'sg' },
  { name: 'Sint Maarten', phone_code: '+1-721', code: 'sx' },
  { name: 'Slovakia', phone_code: '+421', code: 'sk' },
  { name: 'Slovenia', phone_code: '+386', code: 'si' },
  { name: 'Solomon Islands', phone_code: '+677', code: 'sb' },
  { name: 'Somalia', phone_code: '+252', code: 'so' },
  { name: 'South Africa', phone_code: '+27', code: 'za' },
  { name: 'South Korea', phone_code: '+82', code: 'kr' },
  { name: 'South Sudan', phone_code: '+211', code: 'ss' },
  { name: 'Spain', phone_code: '+34', code: 'es' },
  { name: 'Sri Lanka', phone_code: '+94', code: 'lk' },
  { name: 'Sudan', phone_code: '+249', code: 'sd' },
  { name: 'Suriname', phone_code: '+597', code: 'sr' },
  { name: 'Svalbard and Jan Mayen', phone_code: '+47', code: 'sj' },
  { name: 'Swaziland', phone_code: '+268', code: 'sz' },
  { name: 'Sweden', phone_code: '+46', code: 'se' },
  { name: 'Switzerland', phone_code: '+41', code: 'ch' },
  { name: 'Syria', phone_code: '+963', code: 'sy' },
  { name: 'Taiwan', phone_code: '+886', code: 'tw' },
  { name: 'Tajikistan', phone_code: '+992', code: 'tj' },
  { name: 'Tanzania', phone_code: '+255', code: 'tz' },
  { name: 'Thailand', phone_code: '+66', code: 'th' },
  { name: 'Togo', phone_code: '+228', code: 'tg' },
  { name: 'Tokelau', phone_code: '+690', code: 'tk' },
  { name: 'Tonga', phone_code: '+676', code: 'to' },
  { name: 'Tunisia', phone_code: '+216', code: 'tn' },
  { name: 'Turkey', phone_code: '+90', code: 'tr' },
  { name: 'Turkmenistan', phone_code: '+993', code: 'tm' },
  { name: 'Tuvalu', phone_code: '+688', code: 'tv' },
  { name: 'Uganda', phone_code: '+256', code: 'ug' },
  { name: 'Ukraine', phone_code: '+380', code: 'ua' },
  { name: 'United Arab Emirates', phone_code: '+971', code: 'ae' },
  { name: 'United Kingdom', phone_code: '+44', code: 'gb' },
  { name: 'United States', phone_code: '+1', code: 'us' },
  { name: 'Uruguay', phone_code: '+598', code: 'uy' },
  { name: 'Uzbekistan', phone_code: '+998', code: 'uz' },
  { name: 'Vanuatu', phone_code: '+678', code: 'vu' },
  { name: 'Vatican', phone_code: '+379', code: 'va' },
  { name: 'Venezuela', phone_code: '+58', code: 've' },
  { name: 'Vietnam', phone_code: '+84', code: 'vn' },
  { name: 'Yemen', phone_code: '+967', code: 'ye' },
  { name: 'Zambia', phone_code: '+260', code: 'zm' },
  { name: 'Zimbabwe', phone_code: '+263', code: 'zw' },
];
