import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const AgeRangeFormatter = (props) => {
  const { leftIcon, title, row, column } = props;
  const value = row[column.id];
  const classes = useStyles();

  return (
    <div className={`${classes.root} flex items-center`}>
      {leftIcon}
      <div className='w-full flex flex-col' style={{ marginLeft: 20 }}>
        {
          // title &&
          <Typography variant='h1' className={classes.text}>
            {title}
          </Typography>
        }
        <Typography variant='h1' className={classes.text}>
          {value}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {},
  text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

export default AgeRangeFormatter;
