import React from 'react';
import { makeStyles } from '@material-ui/core';

const Content = (props) => {
  const classes = useStyles();

  return (
    <main id='page-content' className={classes.mainContent}>
      {props.children}
    </main>
  );
};

export default Content;

const useStyles = makeStyles((theme) => ({
  mainContent: {
    // padding: theme.spacing(2),
    transition: '0.5s all ease-in-out',
    background: '#F1F5F8',
    flex: 1,
  },
}));
