import ninjaApi from 'store/redux/apis/ninja.api';

export const domainsApi = ninjaApi.injectEndpoints({
  tagTypes: ['Domains', 'DefaultWorkspace'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * List all workspace websites
     */
    listDomains: builder.query({
      query: (body) => ({
        url: '/workspace/websites/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Domains'],
    }),
    /**
     * Get Single Postback
     */
    getDomain: builder.query({
      query: (id) => ({
        url: '/workspace/websites/get',
        method: 'POST',
        body: { id },
      }),
      providesTags: ['Domains'],
    }),
    /**
     * Create new postback
     */
    createDomain: builder.mutation({
      query: (body) => ({
        url: '/workspace/websites/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Domains'],
    }),
    /**
     * Update existing domain
     */
    updateDomain: builder.mutation({
      query: (body) => ({
        url: '/workspace/websites/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Domains'],
    }),
    /**
     * Delete domain
     */
    deleteDomain: builder.mutation({
      query: (body) => ({
        url: '/workspace/websites/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Domains'],
    }),
  }),
});

export const {
  useListDomainsQuery,
  useGetDomainQuery,
  useCreateDomainMutation,
  useUpdateDomainMutation,
  useDeleteDomainMutation,
} = domainsApi;
