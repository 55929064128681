import ninjaApi from 'store/redux/apis/ninja.api';

export const adsetInnerReportingApi = ninjaApi.injectEndpoints({
  tagTypes: ['InnerReporting'],
  overrideExisting: true,
  endpoints: (builder) => ({
    // Load general info of adset
    adsetGeneralInfo: builder.query({
      query: (body) => ({
        url: '/reporting/inner/overview',
        method: 'POST',
        body,
      }),
      providesTags: ['InnerReporting'],
    }),

    // Load total info
    adsetTotals: builder.query({
      query: ({ id, dateFrom, dateTo }) => ({
        url: '/reporting/inner/totals',
        method: 'POST',
        body: { id: id, date_from: dateFrom, date_to: dateTo },
      }),
      providesTags: ['InnerReporting'],
    }),

    // Load adset daily statistics
    adsetDailyStatisticsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/daily',
        method: 'POST',
        body,
      }),
      providesTags: (data, err, arg) => {
        return ['AdsetDailyData:' + arg.id];
      },
    }),

    // Load adset event statistics
    adsetEventStatisticsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/events-data',
        method: 'POST',
        body,
      }),
    }),

    // Load events
    adsetEvents: builder.query({
      query: (body) => ({
        url: '/reporting/inner/events',
        method: 'POST',
        body,
      }),
    }),
    // Load adset age statistics
    adsetAgeStatisticsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/by-age',
        method: 'POST',
        body,
      }),
    }),

    //load adset review
    innerAdLink: builder.query({
      query: (body) => ({
        url: '/network/adset/ad-links',
        method: 'POST',
        body,
      }),
    }),

    //load adset review
    syncAdset: builder.mutation({
      query: (body) => ({
        url: '/network/adset/sync',
        method: 'POST',
        body,
      }),
    }),

    //load comments
    comments: builder.query({
      query: (body) => ({
        url: '/ad/comments/get',
        method: 'POST',
        body,
      }),
      providesTags: ['InnerReporting'],
    }),

    //ad new  comment
    newComments: builder.mutation({
      query: (body) => ({
        url: '/ad/comments/add',
        method: 'POST',
        body,
      }),
    }),

    byGender: builder.query({
      query: (body) => ({
        url: '/reporting/inner/by-gender',
        method: 'POST',
        body,
      }),
    }),

    byDevice: builder.query({
      query: (body) => ({
        url: '/reporting/inner/by-device',
        method: 'POST',
        body,
      }),
    }),

    location: builder.query({
      query: (body) => ({
        url: '/reporting/inner/locations',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAdsetGeneralInfoQuery,
  useAdsetDailyStatisticsDataQuery,
  useAdsetEventStatisticsDataQuery,
  useAdsetEventsQuery,
  useAdsetAgeStatisticsData,
  useInnerAdLinkQuery,
  useAdsetTotalsQuery,
  useSyncAdsetMutation,
  useNewCommentsMutation,
  useCommentsQuery,
  useByGenderQuery,
  useByDeviceQuery,
  useByAgeQuery,
  useLocationQuery,
} = adsetInnerReportingApi;
