import axios from 'axios';
import { tokenKey } from '@ninja/consts';
import storage from '@ninja/storage';
import UrlHelper from 'common/utils/url-helper';

const api = axios.create({
  baseURL: UrlHelper.api('/api'),
  timeout: 60000,
  headers: {
    common: {},
  },
});

api.interceptors.request.use(
  (config) => {
    const token = storage.get(tokenKey, null);
    const testing = storage.get('Testing');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (testing) {
      config.headers['Testing'] = testing;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
