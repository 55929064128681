import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FacebookLikeButton from 'components/NetworkConnectButtons/FacebookConnectButton/FacebookLikeButton';
import React from 'react';
import { useModal } from 'store/redux/states/ui/ui.hooks';
import { FacebookConnectButton, GoogleConnectButton } from 'components/NetworkConnectButtons';
import GoogleLikeButton from 'components/NetworkConnectButtons/GoogleConnectButton/GoogleLikeButton';

const AccountConnectModal = () => {
  const [open, setOpen] = useModal('connect_accounts');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle onClose={handleClose}>
        <div className='flex justify-between items-center'>
          <Typography className='!font-bold'>Choose Network</Typography>
          <IconButton aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className='p-4'>
        <div className='flex w-[300px] gap-4 flex-col'>
          <FacebookConnectButton component={FacebookLikeButton} />
          <GoogleConnectButton component={GoogleLikeButton} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AccountConnectModal;
