import { Button, CircularProgress } from '@material-ui/core';
import { mdiGoogleAds } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

const GoogleLikeButton = (props) => {
  const { loading, ...rest } = props;

  return (
    <Button
      color='secondary'
      variant='outlined'
      startIcon={
        loading ? (
          <CircularProgress color='white' thickness={6} size='1.5rem' />
        ) : (
          <Icon path={mdiGoogleAds} />
        )
      }
      className='!text-white !bg-[#ea4335]'
      size='large'
      {...rest}
    >
      Connect Google
    </Button>
  );
};

export default GoogleLikeButton;
