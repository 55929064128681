// prettier-ignore

class UrlBuilder {
  /**
   * Create new builder instance
   */
  constructor() {
    this.resetAll();
  }

  /**
   * Parse url
   * @param {String} url 
   */
  parse(url){
    this.resetAll();
    let parsed = new URL(url);

    this.scheme= parsed.protocol.replace(/:$/, '');
    this.host = parsed.host;
    this.secure = parsed.protocol.startsWith('https');
    this.port = parsed.port;
    this.path = parsed.pathname;
    this.query = {};
    this.fragment = parsed.hash.replace(/^#/, '');
    this.user = parsed.username;
    this.www = parsed.host.startsWith('www.');
    this.doubleSlashes = /^[a-zA-Z0-9+.-]*:\/\//.test(url)

    return this;
  }

  resetAll(){
    this.url = '';
    this.scheme = '';
    this.secure = true;
    this.host = '';
    this.port = '';
    this.path = '';
    this.query = {};
    this.fragment = '';
    this.user = '';
    this.www = true;
    this.doubleSlashes = true;
  }

  /**
   * Set url scheme
   * @param {String} scheme
   */
  withScheme(scheme) {
    this.scheme = scheme;

    return this;
  }

  /**
   * AKA this.withScheme()
   * @param {String} protocol
   */
  withProtocol(protocol) {
    return this.withScheme(protocol);
  }

  /**
   * Set url user
   * @param {String} user
   */
  withUser(user = '') {
    this.user = user;

    return this;
  }

  /**
   * Set url host
   * @param {String} host
   */
  withHost(host) {
    this.host = host;

    return this;
  }

  /**
   * Set url port
   * @param {String} port
   */
  withPort(port) {
    this.port = port;

    return this;
  }

  /**
   * Set url path
   * @param {Object} path
   */
  withPath(path) {
    this.path = path;

    return this;
  }

  /**
   * Set url query parameters
   * @param {Object} query
   */
  withQuery(query) {
    this.query = query;

    return this;
  }

  /**
   * Set url fragment
   * @param {Object} fragment
   */
  withFragment(fragment) {
    this.fragment = fragment;

    return this;
  }

  /**
   * Remove www from host
   * @returns {UrlBuilder}
   */
  withoutWWW(fragment) {
    this.www = false;

    return this;
  }

  /**
   * Return encoded paramters
   * @returns {String}
   */
  buildParams() {
    return 'someparam=12'
  }
  
  /**
   * returns path removed with starting slash
   * @returns {String}
   */
  getPath(){
    if(this.path === '') {
      return this.path;
    }

    if(String(this.path).startsWith('/')) {
      return this.path;
    } else {
      return '/' + this.path;
    }
  }
  
  /**
   * Returns host
   * @returns {String}
   */
  getHost(){
    if(this.host === '') {
      return this.host;
    }

    if(String(this.host).endsWith('/')) {
      this.host = this.host.replace(/\/$/, '')
    }

    if(this.www === false){
      this.host = this.host.replace(/^www./, '')
    }

    return this.host;
  }

  /**
   * Build and return mailto url
   * @param {String} email 
   * @returns {String}
   */
  mailto(email){
    return `mailto:${email}`;
  }
  

  /**
   * Build and return tel url
   * @param {String} number 
   * @returns {String}
   */
  tel(number){
    return `mailto:${number}`;
  }
  
  /**
   * @returns {UrlBuilder}
   */
  build() {
    // if no scheme is provided, provide http or https

    if (this.scheme === '') {
      this.scheme = this.secure ? 'https' : 'http';
    }
    // common schemes without double slashes
    else if (['mailto', 'tel'].includes(this.scheme)) {
      this.doubleSlashes = false;
    }

    let user = '';
    // if user is provided
    if(this.user !== null && this.user != '') {
      this.doubleSlashes = true;
      user = this.user + '@'
    }

    // https://, http://, mailto:
    let url = this.scheme + ':' 
      + (this.doubleSlashes ? '//' : '') 
      + user 
      + this.getHost()
      + (this.port !== '' ? ':' + this.port : '')
      + this.getPath()
      + (Object.keys(this.query).length > 0 ? '?' + this.buildParams(this.query) : '')
      + (this.fragment !== '' ? '#' + this.fragment : '');

    // Reset all config after building
    this.resetAll();

    return url;
  }

  /**
   * Tostring function
   * @returns {String}
   */
  toString(){
    return this.build();
  }
}

const url = new UrlBuilder();

export default url;
