/**
 *
 * @param {any} err
 * @param {import("formik").FormikValues} form
 * @returns
 */
const handleFormikErrors = (err, form) => {
  if (err.response?.data?.errors) {
    form.setErrors(err.response.data.errors);
  } else if (err.data?.errors) {
    form.setErrors(err.data.errors);
  }
};

export default handleFormikErrors;
