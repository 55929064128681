import React, { createContext, useContext, useState } from 'react';

const RulesContext = createContext();

export const RulesContextProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [ruleData, setRuleData] = useState(null);

  return (
    <RulesContext.Provider value={{ openModal, setOpenModal, ruleData, setRuleData }}>
      {children}
    </RulesContext.Provider>
  );
};

const useRules = () => useContext(RulesContext);

export default useRules;
