import StyledCard from 'components/Cards/StyledCard';
import React, { Fragment } from 'react';

const NinjaTableWrapper = (props) => {
  const {
    disableCard,
    selectedRows,
    resultData,
    setSelectedRows,
    component: Component = StyledCard,
    title,
    icon,
    padding = [0, 0],
    withoutHeader,
    ...rest
  } = props;

  // If card is disabled in table
  if (disableCard) {
    return <Fragment>{props.children}</Fragment>;
  }

  // If card is not disbaled then rendered StyledCard component by default
  // User can change wrapper component by passing wraper prop to NinjaTable
  return (
    <Component
      title={title}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      avatar={icon}
      padding={padding}
      withoutHeader={withoutHeader}
      {...rest}
    >
      {props.children}
    </Component>
  );
};

export default NinjaTableWrapper;
