import { Button, CircularProgress } from '@material-ui/core';
import { mdiFacebook } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

const FacebookLikeButton = (props) => {
  const { loading } = props;

  return (
    <Button
      color='primary'
      variant='outlined'
      startIcon={
        loading ? <CircularProgress color='inherit' size='1.5rem' /> : <Icon path={mdiFacebook} />
      }
      className='!text-white !bg-[#3c579e]'
      size='large'
      {...props}
    >
      Connect Accounts
    </Button>
  );
};

export default FacebookLikeButton;
