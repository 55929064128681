import ninjaApi from 'store/redux/apis/ninja.api';

export const workspaceAccessApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Workspace'],
  endpoints: (build) => ({
    /**
     * Invite new user to workspace by email
     *
     * @param {Object} data
     * @param {String} data.email
     * @param {String} data.role
     *
     * @returns {Promise}
     */
    invite: build.mutation({
      query: (body) => ({
        url: '/access/invite/send',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),

    /// After this check if used

    /**
     * Get invitation by hash
     *
     * @param {String} hash
     *
     * @returns {Promise}
     */
    getInvite: build.mutation({
      query: (body) => ({
        url: '/access/invite/get',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),

    /**
     * If user is not authenticated and wants to register send register request to accept invitation
     *
     * @param {Object} data
     * @param {String} data.hash
     * @param {String} data.name
     * @param {String} data.password
     * @param {String} data.email
     *
     * @returns {Promise}
     */
    registerInvite: build.mutation({
      query: (body) => ({
        url: '/access/invite/register',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),

    grantFormAccess: build.mutation({
      query: (body) => ({
        url: '/access/grant',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),

    deleteAccess: build.mutation({
      query: (body) => ({
        url: '/access/remove',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),
  }),
});

export const {
  useGetInviteMutation,
  useRegisterInviteMutation,
  useGrantFormAccessMutation,
  useDeleteAccessMutation,
} = workspaceAccessApi;
