import { tokenKey } from '@ninja/consts';
import storage from '@ninja/storage';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import ninjaApi from 'store/redux/apis';

const initialState = {
  auth: false,
  loading: Boolean(storage.get(tokenKey)),
  user: {},
};

export const state = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    /**
     * Logout user and set everything to initial
     */
    logoutAction: (state) => {
      state.auth = false;
      state.loading = false;
      state.user = {};
      state._config = {};
    },
  },
  extraReducers: (builder) => {
    // When user is bootstraped and not error returned
    builder.addMatcher(ninjaApi.endpoints.bootstrapUser.matchFulfilled, (state, { payload }) => {
      console.log('bootstrapUser matchFulfilled, user logged in');
      state.user = payload.user;
      state.auth = true;
      state.loading = false;
    });
  },
});

export const { logoutAction } = state.actions;

export default state.reducer;
