import { currency_options } from '@ninja/consts';

export default function setWorkspaceCurrencyConfig(currency) {
  let config;

  if (!(config = currency_options[currency.toUpperCase()])) {
    window.currency_conifg = null;
  }

  window.currency_config = config;
}
