import TextFieldBase from '@material-ui/core/TextField';
import { adornmentGenerator } from 'components/form/field-helpers';
import React, { forwardRef } from 'react';

const TextField = (props, ref) => {
  const {
    width = null,
    color = 'secondary',
    end = null,
    type,
    start = null,
    InputProps = {},
    ...rest
  } = props;

  const _props = {
    style: width ? { width: width } : null,
    InputProps: {
      ...InputProps,
    },
    InputLabelProps: {},
  };

  if (type === 'date') {
    _props.InputLabelProps.shrink = true;
  }

  _props.InputProps.endAdornment = adornmentGenerator(end, 'end', {
    InputProps,
    color,
  });
  _props.InputProps.startAdornment = adornmentGenerator(start, 'start', {
    InputProps,
    color,
  });

  return <TextFieldBase ref={ref} type={type} variant='outlined' fullWidth {...rest} {..._props} />;
};

export default forwardRef(TextField);
