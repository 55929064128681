import { makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';

const BootstrapTooltip = (props) => {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
};

export default BootstrapTooltip;

const useStyles = makeStyles(() => ({
  arrow: {
    color: '#151A22',
  },
  tooltip: {
    backgroundColor: '#151A22',
    fontSize: '1rem',
  },
}));
