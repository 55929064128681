/**
 * [1,2,3].toggle(1, 2) => [1]
 * [1,2,3].toggle(1, 4) => [2,3,4]
 * @returns {String}
 */
/* eslint-disable-next-line */
Array.prototype.toggleMany = function () {
  for (var i = 0; i < arguments.length; i++) {
    let ind = this.indexOf(arguments[i]);
    if (ind > -1) {
      this.splice(ind, 1);
    } else {
      this.push(arguments[i]);
    }
  }
  return this;
};
/**
 * [1,2,3].toggle(1) => [2,3]
 * [1,2,3].toggle(4) => [1,2,3,4]
 * @returns {String}
 */
/* eslint-disable-next-line */
Array.prototype.toggle = function (val) {
  let ind = this.indexOf(val);
  if (ind > -1) {
    this.splice(ind, 1);
  } else {
    this.push(val);
  }

  return this;
};
/**
 * [1,2,3].toggle(1) => [2,3]
 * [1,2,3].toggle(4) => [1,2,3,4]
 * @returns {String}
 */
/* eslint-disable-next-line */
Array.prototype.remove = function (val) {
  let ind = this.indexOf(val);
  if (ind > -1) {
    this.splice(ind, 1);
  }

  return this;
};

/**
 * Array.intersects([1,2,3], [2,3]) => true
 * Array.intersects([1,2,3], [3]) => true
 * Array.intersects([1,2,3], [5]) => false
 * @returns {Boolean}
 */
/* eslint-disable-next-line */
Array.intersects = function (arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    throw new Error('Paramters must be an array');
  }
  return (
    arr1
      .map((v) => {
        return arr2.indexOf(v) > -1;
      })
      .filter((a) => a).length > 0
  );
};

/**
 * Array.wrap([1,2,3]) => [1,2,3]
 * Array.wrap(2) => [2]
 * Wrap array to array
 * @returns {String}
 */
/* eslint-disable-next-line */
Array.wrap = function (val) {
  if (Array.isArray(val)) return val;

  return [val];
};
