import { IconButton, Tooltip, Zoom } from '@material-ui/core';
import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

const ErrorAdornment = ({ errorText }) => {
  return (
    <Tooltip title={errorText} TransitionComponent={Zoom} placement='left' enterDelay={0}>
      <IconButton size='small' tabIndex={0} color='error'>
        <Icon path={mdiAlertCircle} color='red' />
      </IconButton>
    </Tooltip>
  );
};

export default ErrorAdornment;
