import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import P from 'router/paths';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const AdNameFormatter = ({ row }) => {
  const { id, name, adset_id } = row;
  const classes = useStyles();
  const { dates } = useWidgetDate();
  return (
    <div
      className={clsx(
        'justify-between text-left w-full text-xs bg-background-light p-1.5 rounded-md flex items-center h-8 truncate',
        classes.cont
      )}
    >
      <div className='flex w-full'>
        <text className='nnj-body2 !font-medium w-48 flex-nowrap truncate  text-ellipsis'>
          {name}
        </text>
      </div>
      <a
        target='_blank'
        rel='noreferrer'
        href={P.REPORTING.INNER.DASHBOARD.replace(':id', adset_id)}
      >
        <OpenInNewIcon className='text-color-light hover:text-color-dark' />
      </a>
    </div>
  );
};

export default AdNameFormatter;

const useStyles = makeStyles(() => ({
  cont: {
    minWidth: '235px',
    width: 'fit-content',
  },
}));
