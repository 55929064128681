import axios from 'axios';
import { tokenKey, languageKey } from '@ninja/consts';
import { notificate } from 'store/NotificationsContext';
import storage from '@ninja/storage';
import UrlHelper from './utils/url-helper';

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: UrlHelper.api('/api'),
      timeout: 60000,
    });

    if (storage.get('Testing')) {
      this.api.defaults.headers.common['Testing'] = storage.get('Testing');
    }

    this.api.interceptors.request.use(
      (config) => {
        const token = storage.get(tokenKey, null);
        const testing = storage.get('Testing');

        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }

        if (testing) {
          config.headers['Testing'] = testing;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.authToken = storage.get(tokenKey, null);
    this.language = storage.get(languageKey, 'ka');

    this.setToken(this.authToken);
    this.setLanguageCode(this.language);
    this.addMiddlewares();
  }

  call = (method, ...params) => {
    return this.api[method](...params).then((data) => {
      this.catchNotifications(data.data);
      return data;
    });
  };

  catchNotifications = (data) => {
    if (!Array.isArray(data._notif)) return;
    notificate(data._notif);
  };

  getData = (res) => res.data;

  addMiddlewares() {
    this.api.interceptors.response.use(
      (response) => {
        if (response.data?._token) {
          this.setToken(response.data._token);
        }

        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          console.log('unauthorized, logging out ...');
        }
        return Promise.reject(error);
      }
    );
  }

  setToken(token) {
    this.authToken = token;
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return this;
  }

  setLanguageCode(code) {
    this.language = code;
    this.api.defaults.headers.common['Accept-Language'] = this.language;
    storage.set(languageKey, code);
    return this;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Api();
