const defaultPalette = {
  input: {
    text: '#2E3849',
    focusedBorder: '#000000de',
  },
  primary: {
    light: '#636c76',
    main: '#2c435a',
    dark: '#151a22',
    contrastText: '#ffffff',
  },
  secondary: {
    extra_light: '#FFF8F4',
    light: '#E0A400',
    main: '#ff4e00',
    dark: '#ff5f19',
    contrastText: '#ffffff',
  },
  success: {
    light: '#c5ff42',
    main: '#91C716',
    dark: '#588000',
    contrastText: '#ffffff',
  },
  warning: {
    light: '#ff6559',
    main: '#EB2F20',
    dark: '#a10c00',
    contrastText: '#ffffff',
  },
  error: {
    light: '#ff6559',
    main: '#EB2F20',
    dark: '#a10c00',
    contrastText: '#ffffff',
  },
  info: {
    light: '#2C7DF0',
    main: '#2C7DF0',
    dark: '#2C7DF0',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#000000',
    secondary: '#2c435a',
    disabled: '#00000042',
    hint: '#0000008a',
  },
  white: '#fff',
  background: {
    default: '#fff',
    paper: '#fff',
  },
  divider: '#d5d5d5',
  type: 'light',
  solids: {
    blue: '#2c7df0',
    yellow: '#ffbb02',
  },
  custom: {
    blue: '#2c7df0',
    yellow: '#ffbb02',
    white: '#ffffff',
    green: '#91c716',
    lightBlue: '#bddbff',
    gray: '#B4B4B4',
    backgroundLight: '#F1F5F8',
    textBlack: '#000000',
  },
};

export default defaultPalette;
