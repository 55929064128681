import gtagevent from '@lib/google-analytics/gtagevent';

export default function onRegisterConversions(data) {
  // Linkedin conversion
  window.lintrk?.('track', { conversion_id: data.user_id });

  // Gtag conversion
  gtagevent('event', 'Registration', {
    event_category: 'App',
    event_label: data.user_id,
  });
}
