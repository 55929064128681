import ninjaApi from 'store/redux/apis/ninja.api';

export const fileManagerApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['FileManager'],
  endpoints: (builder) => ({
    /* get config */
    getConfig: builder.mutation({
      query: (body) => ({
        url: '/file-manager/get-config',
        method: 'POST',
        body,
      }),
      providesTags: ['FileManager'],
    }),
    /* upload files */
    uploadFiles: builder.mutation({
      query: (body) => ({
        url: '/file-manager/upload',
        method: 'POST',
        body,
      }),
      invalidatesTags: (res) => {
        if (!res || !res.uploaded_file) return [];
        return ['FileManager'];
      },
    }),
    /**
     * Upload file
     */
    getFiles: builder.query({
      query: (body) => ({
        url: '/file-manager/get-files',
        method: 'POST',
        body,
      }),
      providesTags: ['FileManager'],
    }),
    /**
     * Upload file
     */
    getFile: builder.query({
      query: (body) => ({
        url: '/file-manager/get-file',
        method: 'POST',
        body,
      }),
      providesTags: [],
    }),

    /*create folder */
    createFolder: builder.mutation({
      query: (body) => ({
        url: '/file-manager/create-folder',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FileManager'],
    }),

    /**
     * Delete file
     */
    deleteFiles: builder.mutation({
      query: (body) => ({
        url: '/file-manager/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FileManager'],
    }),
  }),
});

export const {
  useGetConfigMutation,
  useUploadFilesMutation,
  useCreateFolderMutation,
  useDeleteFilesMutation,
  useGetFilesQuery,
  useGetFileQuery,
} = fileManagerApi;
