import { tokenKey } from '@ninja/consts';
import storage from '@ninja/storage';

/**
 * Return token from localstorage and api
 * @returns {String|null}
 */
export const removeApiAuthToken = (api) => {
  storage.set(tokenKey, '');
  console.log('[API] Token Removed');
};

/**
 * Return token from localstorage
 * @param {String} token
 * @returns {Promise}
 */
export const setApiAuthToken = (token) => {
  return new Promise((res, rej) => {
    storage.set(tokenKey, token);
    console.log('[API] Token Set', token);
    res();
  });
};
