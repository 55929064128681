import ninjaApi from 'store/redux/apis/ninja.api';
import normalizeResponse from 'store/redux/utils/transformers/normalizeResponse';
import providesNormalizedListTags from 'store/redux/utils/tags/providesNormalizedListTags';
import { NetworkAccount } from 'store/redux/endpoints';
import { getSocket, registerSocketEvent } from 'store/socket/socket';

/**
 * Returns workspace
 */
export const networkAccountsApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['DefaultWorkspace', 'NetworkAccount', 'Reporting', 'NetworkAccountStatus'],
  endpoints: (build) => ({
    /* Fetch all workspaces */
    networkAccountsList: build.query({
      query: (body) => ({
        url: NetworkAccount.endpoints.LIST.uri,
        method: NetworkAccount.endpoints.LIST.method,
        body,
      }),
      providesTags: ['NetworkAccount'],
      async onQueryStarted(arg, { dispatch }) {
        registerSocketEvent('network_accounts.status_changed', (...args) => {
          dispatch(ninjaApi.util.invalidateTags(['NetworkAccount']));
        });

        registerSocketEvent('network_accounts.state_changed', (...args) => {
          dispatch(ninjaApi.util.invalidateTags(['NetworkAccount']));
        });
      },
    }),
    /* Change default network account */
    changeNetworkAccount: build.mutation({
      query: (body) => ({
        url: '/workspace/network-accounts/change',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NetworkAccount', 'Reporting'],
    }),
    /* Change Network account status */
    changeNetworkAccountStatus: build.mutation({
      query: (body) => ({
        url: '/workspace/network-accounts/change-status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NetworkAccount', 'NetworkAccountStatus'],
    }),
    /* Change Network account status */
    createDefaultConversions: build.mutation({
      query: (body) => ({
        url: '/network/conversions/create-default',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
    /** sync single account */
    syncAccount: build.mutation({
      query: (data) => ({
        url: '/workspace/network-accounts/sync/account',
        method: 'POST',
        data,
      }),
    }),
    /* Login facebook Account */
    connectFacebook: build.mutation({
      query: (data) => ({
        url: '/auth/connect/facebook',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccount', 'NetworkAccountStatus'],
    }),
    /* Login google Account */
    connectGoogle: build.mutation({
      query: (data) => ({
        url: '/auth/connect/google',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccount', 'NetworkAccountStatus'],
    }),
    /* Login google Account */
    checkAccountTokens: build.query({
      query: (data) => ({
        url: '/workspace/network-accounts/check',
        method: 'POST',
        data,
      }),
      providesTags: ['NetworkAccountStatus'],
    }),
  }),
});

export const {
  useNetworkAccountsQuery,
  useNetworkAccountsListQuery,
  useCheckAccountTokensQuery,
  useChangeNetworkAccountMutation,
  useChangeNetworkAccountStatusMutation,
  useConnectFacebookMutation,
  useConnectGoogleMutation,
  useCreateDefaultConversionsMutation,
  useSyncAccountMutation,
} = networkAccountsApi;
