import React, { useCallback, useMemo } from 'react';
import { useNinjaTable } from './NinjaTable';
import { ColumnsCustomize } from 'components/Table/TableToolbar/components';

const ColumnsCustomization = (props) => {
  const {
    customizationId,
    onCustomizationUpdate = () => {},
    customizationEdit,
    removeColumns,
  } = props;

  const { allColumns, setColumns } = useNinjaTable();

  const handleUpdate = useCallback(
    (columnsCustomization) => {
      if (!columnsCustomization) {
        return allColumns;
      }

      const newColumns = allColumns?.filter(
        (column) => !(columnsCustomization[column.key]?.hidden === true)
      );
      setColumns(newColumns);
      onCustomizationUpdate(newColumns);
    },
    [allColumns, setColumns]
  );

  return (
    <div className='flex items-start mr-2'>
      <ColumnsCustomize
        removeColumns={removeColumns}
        customizationEdit={customizationEdit}
        columns={allColumns || []}
        onUpdate={handleUpdate}
        customizationId={customizationId}
      />
    </div>
  );
};

export default ColumnsCustomization;
