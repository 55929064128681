import { isSyntaxError, value } from '@ninja/utils';

/**
 * @todo create object dot notation getting algorithm
 */
class Storage {
  /**
   * Load localStorage Value, if object then object is parsed
   * if array, then array is parsed
   *
   * @param {String} key
   * @param {any} def
   * @returns {any}
   */
  load(key, def = undefined) {
    const stored = localStorage.getItem(key);

    // If not found with given key
    if (stored == null) {
      // if key contains "." then it's requested to load by dot
      if (key.indexOf('.') > -1) {
        return this.getByDot(key, def);
      }

      // If stored is null, return dafault value
      return value(def);
    }

    try {
      // Parse JSON data, if error while parsing then catch is fired
      // And raw value or default value is returned
      return JSON.parse(stored);
    } catch (err) {
      // If json syntax error, return raw value, else return default
      return isSyntaxError(err) ? stored : value(def);
    }
  }

  /**
   * If localStorage has key
   * @param {String} key
   * @returns {Boolean}
   */
  has(key) {
    return localStorage.hasOwnProperty(key);
  }

  /**
   * Remove storage key
   * @param {String} key
   * @returns {Boolean}
   */
  remove(key) {
    return localStorage.removeItem(key);
  }

  /**
   * Store localStorage value
   * @see {this.store}
   */
  set() {
    return this.store(...arguments);
  }

  /**
   * Save localStorage Value as String
   * @param {String} key
   * @param {any|function} value
   * @todo add dot key support
   */
  store(key, val) {
    localStorage.setItem(key, JSON.stringify(value(val)));
  }

  /**
   * Return number by localStorage
   * @returns {int|NaN}
   */
  getNumber() {
    const data = this.load(...arguments);
    const value = parseFloat(data);
    if (isNaN(NaN)) {
      return data;
    }
    return value;
  }

  /**
   * Return boolean of localstorage
   * @returns {int|NaN}
   */
  getBool() {
    return Boolean(this.getNumber(...arguments));
  }

  /**
   * Return localStorage value as string
   * @returns {String}
   */
  getString() {
    return '' + this.load(...arguments);
  }

  /**
   * Get localStorage value
   * @see {this.load}
   */
  get() {
    return this.load(...arguments);
  }

  /**
   * If requested by dot, load calls this function
   * dot request looks like this this.get('dates.date_from');
   * @param {String} key
   * @param {any} def
   * @returns {any}
   */
  getByDot(key, def) {
    /**
     * Split keys by limit 1
     * dates.widgets.date_from splitted into ["dates", "widgets.date_from"]
     * dates is key of localstorage
     */
    const parts = key.splitByLimit('.', 1);
    // Load localstorage first key
    let data = this.load(parts[0], def);

    // If loaded and data is present, then get by remaining dot keys
    if (
      typeof data === 'object' &&
      typeof data.getByDot === 'function' &&
      typeof parts[1] !== undefined
    ) {
      return data.getByDot(parts[1]);
    }
    // If not, then def value is retuned, since data is loaded by default load method
    return data;
  }

  /**
   * Modify current localstorage value
   * @param {String} key
   * @param {function} fn
   */
  modify(key, fn) {
    this.store(key, fn(this.load(key)));
  }

  /**
   * Append data to localStorage array
   * @param {any} item
   * @param {String} storageID
   */
  appendItemToArray(item, storageID) {
    this.modify(storageID, (storage = []) => [...storage, value(item)]);
  }

  /**
   * Remove item to localStorage array
   * @param {any} item
   * @param {String} storageID
   */
  removeItemFromArray(item, storageID) {
    this.modify(storageID, (storage = []) => storage.filter((s) => s !== value(item)));
  }

  /**
   * Save item to localStorage object
   * @param {any} item
   * @param {String} storageID
   */
  saveItemToObject(item, storageID) {
    this.modify(storageID, (storage = {}) => ({ ...storage, item }));
  }
}

// create singletone
const storage = new Storage();
window.__ninjastorage = storage;
export default storage;
