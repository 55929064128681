import { Alert, Button, Checkbox, Input, Modal, Skeleton, Typography } from 'antd';
import StatusSelect from 'components/Selects/StatusSelect';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { AlertType } from 'components/Dialogs/const';
import {
  useAdsetDetailsQuery,
  useCopyAdsetMutation,
} from 'store/redux/states/ad_adset/ad_adset.api';
import { useModal } from 'store/redux/states/ui/ui.hooks';
import NetworkAccountSelect from 'components/Selects/NetworkAccountSelect';

const AdsetCopyModal = () => {
  const [modalData, setModal] = useModal('adset_copy');
  const { adset_id } = modalData ? modalData : {};
  const isModalOpen = Boolean(adset_id);
  const { data: adset, isFetching: isFetchingLoading } = useAdsetDetailsQuery(
    { id: adset_id, adset_id: adset_id },
    { skip: !Boolean(adset_id) }
  );
  const [duplicateAdsetQuery, { isLoading: isDuplicationLoading, isSuccess }] =
    useCopyAdsetMutation({
      fixedCacheKey: JSON.stringify({
        id: adset_id,
        adset_id: adset_id,
      }),
    });

  const handleFormSubmit = () => {
    const data = { ...form.values };
    data.adset_id = adset_id;

    duplicateAdsetQuery(data)
      .then((data) => {
        form.resetForm();
        window.alert('Adset Duplicated Successfully');
      })
      .catch(() => {
        window.alert(`Error While Duplicating Adset`, () => {}, { type: AlertType.Eror });
      });
  };

  const form = useFormik({
    initialValues: {
      adset_id: adset_id,
      status: 'active',
      parameters: '',
      name: '',
      budget: 0,
      change_budget: false,
      parent_parameters: false,
      same_account: true,
      network_account_id: null,
      copy_ads: true,
      show_budget: false,
    },
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    if (!adset?.name) return;
    form.setFieldValue('name', `${adset?.name} - Copy`);
    form.setFieldValue('budget', Number(adset?.budget));
    form.setFieldValue('show_budget', Boolean(adset?.budget));

    return () => form.resetForm();
  }, [adset?.name, isModalOpen]);

  const isLoading = isFetchingLoading || isDuplicationLoading;

  return (
    <Modal
      footer={false}
      open={isModalOpen}
      onCancel={() => {
        form.resetForm();
        setModal(false);
      }}
      title='Duplicate Adset'
      destroyOnClose
      centered
    >
      <Skeleton active loading={isFetchingLoading}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            form.handleSubmit();
          }}
          className='flex flex-col gap-4'
        >
          {isSuccess ? <Alert type='success' message='Adset Successfully Copied' /> : null}

          {/* <div>
            <Typography.Text type='primary'>Selec Account:</Typography.Text>
            <NetworkAccountSelect
              value={form.values.network_account_id}
              multiple={false}
              disabled={form.values.same_account}
              onChange={(value) => form.setFieldValue('network_account_id', value)}
              size='large'
            />
            <Checkbox
              checked={form.values.same_account}
              disabled={isLoading}
              onChange={(e) => {
                if (!e.target.checked) form.setFieldValue('network_account_id', null);
                form.setFieldValue('same_account', e.target.checked);
              }}
            >
              Copy on same account
            </Checkbox>
          </div> */}

          <div>
            <Typography.Text type='primary'>New Ad Name:</Typography.Text>
            <Input
              name='name'
              size='large'
              placeholder='New Adset Name'
              value={form.values.name}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              disabled={isLoading}
            />
          </div>
          <div>
            <Typography.Text type='primary'>Parameters For New Ads:</Typography.Text>
            <Input
              name='parameters'
              size='large'
              placeholder='foo=bar'
              value={form.values.parameters}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              disabled={isLoading || form.values.parent_parameters}
            />
            <Checkbox
              checked={form.values.parent_parameters}
              disabled={isLoading}
              onChange={(e) => form.setFieldValue('parent_parameters', e.target.checked)}
            >
              Copy From Original
            </Checkbox>
          </div>
          <div>
            <Typography.Text type='primary'>Status:</Typography.Text>
            <StatusSelect
              mode={void 0}
              size='large'
              name='status'
              value={form.values.status}
              onChange={(status) => form.setFieldValue('status', status)}
              disabled={isLoading}
              onBlur={form.handleBlur}
            />
          </div>
          {form.values.show_budget && (
            <div>
              <Typography.Text type='primary'>Budget:</Typography.Text>
              <Input
                name='budget'
                size='large'
                placeholder='Budget'
                value={form.values.budget}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                disabled={isLoading || !form.values.change_budget}
              />
              <Checkbox
                checked={form.values.change_budget}
                disabled={isLoading}
                onChange={(e) => form.setFieldValue('change_budget', e.target.checked)}
              >
                Change Budget
              </Checkbox>
            </div>
          )}
          <div className='flex flex-col'>
            <Typography.Text type='primary'>Select if you want to copy ads:</Typography.Text>
            <Typography.Text type='secondary'>
              If not selected only adset will be copied
            </Typography.Text>
            <Checkbox
              checked={form.values.copy_ads}
              disabled={isLoading}
              onChange={(e) => form.setFieldValue('copy_ads', e.target.checked)}
            >
              Copy Ads
            </Checkbox>
          </div>
          <div className='flex-center'>
            <Button
              loading={isLoading}
              disabled={isSuccess || isLoading}
              htmlType='submit'
              type='primary'
            >
              Submit Copy
            </Button>
          </div>
        </form>
      </Skeleton>
    </Modal>
  );
};

export default AdsetCopyModal;
