import { makeStyles } from '@material-ui/core';
import { mdiCheckBold, mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import TextInput from 'components/input/TextInput';
import MenuItem from 'components/StyledComponents/MenuItem';
import React, { createRef, useEffect, useState } from 'react';

const SelectInput = (props) => {
  const classes = useStyles({
    fontSize: props.fontSize,
  });
  const { options, multiple, defaultValue, onChange, ...rest } = props;
  const inputRef = createRef();
  const [valuelabel, setValueLabel] = useState(rest.valuelabel);
  const [value, setValue] = useState(rest.value);

  const renderValue =
    props.renderValue ||
    ((val) => {
      return (
        <div className={classes.selectDefaultValue}>
          {valuelabel} <Icon path={mdiChevronDown} />
        </div>
      );
    });

  const handleChange = (e) => {
    let val = e.target.value;
    let name = null;

    if (typeof options === 'object' && options[val]) {
      name = options[val];
    }
    if (typeof onChange === 'function') {
      onChange(val, name);
    } else {
      setValue(val);
    }
  };

  const getValueLabelFromOptions = (val) => {
    let valuelabel = '';
    if (Array.isArray(val) && multiple) {
      let l = val.length;
      if (val.length === 0) {
        valuelabel = props.valuelabel;
      } else {
        for (let i = 0; i < l; i++) {
          if (!options[val[i]]) continue;
          valuelabel += options[val[i]].name + (i + 1 !== l ? ', ' : '');
        }
      }
    } else if (options[val]) {
      valuelabel = options[val].name;
    }

    return valuelabel;
  };

  useEffect(() => {
    let _valuelabel = value ? getValueLabelFromOptions(value) : valuelabel;

    if (_valuelabel !== valuelabel) {
      setValueLabel(_valuelabel);
    }
  }, [value, props.loading]);

  useEffect(() => {
    setValue(rest.value);
  }, [rest.value]);

  const opts = Array.isArray(options)
    ? options
    : typeof options === 'object'
    ? Object.values(options)
    : [];

  return (
    <TextInput
      select
      ref={inputRef}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      SelectProps={{
        IconComponent: () => null,
        renderValue: renderValue,
        multiple: multiple,
        displayEmpty: true,
        classes: {
          select: classes.selectInput,
          focus: classes.selectInputFocused,
        },
        MenuProps: {
          anchorEl: () => inputRef.current?.node?.closest('.MuiFormControl-root'),
          className: classes.selectMenu,
          variant: 'menu',
        },
      }}
      {...rest}
      value={value}
    >
      {opts.map((opt) => {
        return (
          <MenuItem className={classes.menuItem} value={opt.value} key={opt.value}>
            {opt.name}
            <Icon path={mdiCheckBold} />
          </MenuItem>
        );
      })}
    </TextInput>
  );
};

export default SelectInput;

const useStyles = makeStyles((theme) => ({
  selectDefaultValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: (props) => props.fontSize || '14px',
    '& svg': {
      fontSize: (props) => props.fontSize || '14px',
      marginLeft: 'auto',
    },
  },
  selectInput: {
    borderRadius: 8,
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: 8,
    },
  },
  selectMenu: {
    maxHeight: '400px',
    marginTop: '60px',
  },
  menuItem: {
    background: '#fff',
    backgroundColor: '#fff',
    '& svg': {
      color: theme.colors.orange,
    },
  },
}));
