import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  FormControlLabel,
  Select as SelectBase,
} from '@material-ui/core';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import Ninja from '@ninja';
import { selectDropDownIcon } from 'assets/svgPaths';
import clsx from 'clsx';
import React, { useState, Fragment } from 'react';
import { adornmentGenerator } from '../field-helpers';
import Checkbox from './Checkbox';

export const Option = ({ checked = false, checkbox = false }) => {
  return <Fragment>{checkbox && <Checkbox checked={checked} />}</Fragment>;
};

const Select = (props) => {
  const {
    label,
    checkbox,
    checked,
    selectAllCheckbox,
    options: baseOptions = [],
    value = '',
    skip = [],
    className,
    placeholder = null,
    name,
    showCheckIcon = true,
    valueKey = 'id',
    img = false,
    onChange = null,
    checkedAll: $checkedAll = null,
    renderOption: $renderOption = null,
    renderValue: $renderValue = null,
    end = null,
    start = null,
    width,
    inputProps = {},
    ...rest
  } = props;
  const [id] = useState(new Date().getTime());
  const classes = useSelectStyles({
    shrinkLabel: Boolean(placeholder) || value?.length,
  });
  const options = Ninja.normalize(baseOptions, valueKey);

  const handleChange = (e) => {
    const { value } = e.target;
    onChange && onChange(value, name);
  };

  const getSelected = () => {
    if (String(value).length) {
      return options[value] ?? {};
    }
    return {};
  };

  const renderValue = () => {
    const selected = getSelected();

    if (!selected.name) {
      return placeholderMessage();
    }

    return selected.name;
  };

  const placeholderMessage = () => {
    return <em>{placeholder}</em>;
  };

  const renderOption = (key) => {
    let opt = options[key];
    let checked = value === opt.value;
    const image = <img className='mr-5' src={opt.src} />;
    if (skip.includes(key)) return null;
    return (
      <MenuItem disableGutters key={key} value={opt.value}>
        {checkbox ? <Option value={opt.value} checkbox={checkbox} checked={checked} /> : null}
        {img && image}
        <ListItemText primary={opt.name} />
        {showCheckIcon && checked && (
          <ListItemIcon>
            <Icon className={classes.checkIcon} path={mdiCheck} />
          </ListItemIcon>
        )}
      </MenuItem>
    );
  };
  const shrinked = placeholder || undefined;
  return (
    <FormControl fullWidth variant='outlined' style={{ width: width ? width : null }}>
      {label && (
        <InputLabel shrink={shrinked} id={`${id}-label`}>
          {label}
        </InputLabel>
      )}

      <SelectBase
        value={value}
        IconComponent={DropDownIcon}
        labelId={`${id}-label`}
        id={id}
        displayEmpty
        label={label}
        renderValue={$renderValue || renderValue}
        className={clsx(classes.select, className)}
        inputProps={{
          name,
          ...inputProps,
        }}
        MenuProps={{
          classes: {
            paper: classes.paper,
            list: classes.list,
          },
          disableAutoFocusItem: true,
          transitionDuration: 100,
          MenuListProps: {
            variant: 'menu',
          },
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          getContentAnchorEl: null,
        }}
        onChange={handleChange}
        startAdornment={adornmentGenerator(start, 'start')}
        endAdornment={adornmentGenerator(end, 'end')}
        {...rest}
      >
        {selectAllCheckbox ? (
          <MenuItem disableRipple disableGutters value=''>
            <FormControlLabel
              className='w-full px-3 py-1'
              control={<Checkbox name='All' checkbox={checkbox} checked={checked} />}
              label='Select All'
              onChange={$checkedAll}
            />
          </MenuItem>
        ) : null}
        {Object.keys(options).map($renderOption ? $renderOption : renderOption)}
      </SelectBase>
    </FormControl>
  );
};

export const DropDownIcon = () => (
  <Icon className='dropDownIcon' path={selectDropDownIcon} size='7px' />
);

export const useSelectStyles = makeStyles((theme) => ({
  select: {
    '& .MuiChip-root:first-child': {
      marginLeft: theme.spacing(-1),
    },
    '& .MuiChip-root': {
      marginRight: theme.spacing(0.5),
    },
    '& em': {
      fontSize: 'inherit',
    },
    '&:hover .dropDownIcon': {
      color: '#4A89E3',
    },
    '&.Mui-focused .dropDownIcon': {
      color: '#4A89E3',
    },
    '& [aria-expanded="true"] ~ .dropDownIcon': {
      transform: 'rotate(180deg)',
    },
    '& .dropDownIcon': {
      fontSize: '7px',
      transition: '200ms transform ease-in',
      position: 'absolute',
      right: '17px',
      color: '#919191',
    },
    '&.no-border fieldset': {
      border: 'unset !important',
    },
    '&.no-arrow .dropDownIcon': {
      display: 'none !important',
    },
    '& fieldset legend': {
      maxWidth: (props) => (props.shrinkLabel ? '1000px' : null),
    },
  },

  checkIcon: {
    marginLeft: theme.spacing(),
    color: theme.palette.secondary.main,
  },
  paper: {
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
    marginTop: '5px',
  },
  list: {
    maxHeight: '350px',
    '&.MuiAutocomplete-listbox': {
      padding: 0,
    },
    '& .MuiMenuItem-root, & .MuiAutocomplete-option': {
      position: 'relative',
      padding: theme.spacing(0.25, 1.5),
    },
    '& .MuiMenuItem-root:first-child, & .MuiAutocomplete-option:first-child': {
      paddingTop: '5px',
    },
    '& .MuiMenuItem-root:last-child, & .MuiAutocomplete-option:last-child': {
      paddingBottom: '5px',
    },
    '& .MuiCheckbox-root': {
      padding: theme.spacing(),
    },
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
    },
  },
}));

export default Select;
