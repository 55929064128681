import React from 'react';
import CheckboxRaw from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const Checkbox = (props) => {
  const classes = useStyles({
    props: props.fontWeight,
  });
  const { name, checked, label, ...rest } = props;
  return (
    <FormControlLabel
      className={classes.formControl}
      control={
        <CheckboxRaw
          name={name}
          checked={checked}
          className={classes.checkbox}
          checkedIcon={
            <Icon className={classes.icon}>
              <img src='/icons/checkbox/checkbox-checked.svg' alt='checked' />
            </Icon>
          }
          icon={
            <Icon className={classes.icon}>
              <img src='/icons/checkbox/checkbox.svg' alt='unchecked' />
            </Icon>
          }
          {...rest}
        />
      }
      label={label}
      classes={{ label: classes.label }}
    />
  );
};

export default Checkbox;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '0',
  },
  checkbox: {
    padding: '0',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontFamily: 'fregular',
    color: theme.colors.inputMain,
    marginLeft: 7,
    // fontWeight: props => props.fontWeight,
    fontWeight: '700',
  },
  scroll: {
    height: 308,
    overflowX: 'auto',
  },
}));
