import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import BaseMenuItem from '@material-ui/core/MenuItem';
import BaseSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {},
}));

const Select = (props) => {
  const {
    id,
    menu: MenuStyle = BaseMenuItem,
    options = [],
    placeholder = null,
    onChange,
    placeholderDisabled = true,
    label,
    formControlProps = {},
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <FormControl
      variant='outlined'
      size='small'
      className={classes.formControl}
      {...formControlProps}
    >
      {label ? <InputLabel id={id}>{label}</InputLabel> : null}
      <BaseSelect labelId={id} id={id} {...rest} label={label} onChange={onChange}>
        {placeholder ? (
          <MenuStyle disabled={placeholderDisabled} value=''>
            <em>{placeholder}</em>
          </MenuStyle>
        ) : null}
        {options.map((opt) => {
          const option = typeof opt === 'object' ? opt : { name: opt, value: opt };

          return (
            <MenuStyle key={option.value} value={option.value}>
              {option.name}
            </MenuStyle>
          );
        })}
      </BaseSelect>
    </FormControl>
  );
};

export default Select;
