import ninjaApi from 'store/redux/apis/ninja.api';

export const rulesApi = ninjaApi.injectEndpoints({
  tagTypes: ['Rules'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * List all rules
     */
    rulesList: builder.query({
      query: (body) => ({
        url: '/network/rules/list',
        method: 'POST',
        body,
      }),
      // TODO temp fix
      transformResponse: (response) => {
        return { data: response };
      },
      providesTags: ['Rules'],
    }),
    createRule: builder.mutation({
      query: (body) => ({
        url: '/network/rules/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Rules'],
    }),
    updateRule: builder.mutation({
      query: (body) => ({
        url: '/network/rules/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Rules'],
    }),
  }),
});

export const { useRulesListQuery, useCreateRuleMutation, useUpdateRuleMutation } = rulesApi;
