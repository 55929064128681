const typography = {
  fontFamily: ['fregular', 'Helvetica', 'Arial'].join(','),
  fontSize: 13,
  color: '#000',
  h1: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'fregular',
  },
  h3: {
    fontSize: '16px',
    fontWeight: 700,
  },
  h4: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  h5: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'fregular',
  },
  h6: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  subtitle2: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#536A8B',
  },
  body1: {
    fontSize: 14,
    fontWeight: 350,
  },
  body2: {
    fontSize: 12,
    fontWeight: 350,
    fontFamily: 'fRegular',
  },
  caption: {
    color: '#727272',
    fontSize: '0.75rem',
  },
  button: {
    fontWeight: 800,
    fontSize: '0.875rem',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 350,
    fontFamily: 'fRegular',
  },
};

export default typography;

// below is NEW, up is OLD

// const typography = {
//   fontFamily: ['fregular', 'Helvetica', 'Arial'].join(','),
//   fontSize: 13,
//   h1: {
//     fontSize: 28,
//     fontWeight: 700,
//     fontFamily: 'fRegular',
//   },
//   h2: {
//     fontSize: 24,
//     fontWeight: 700,
//     fontFamily: 'fRegular',
//   },
//   h3: {
//     fontSize: 20,
//     fontWeight: 700,
//     fontFamily: 'fRegular',
//   },
//   h4: {
//     fontSize: 16,
//     fontWeight: 700,
//     fontFamily: 'fRegular',
//   },
//   h5: {
//     fontSize: 14,
//     fontWeight: 700,
//     fontFamily: 'fRegular',
//   },
//   h6: {
//     fontSize: 13,
//     fontWeight: 700,
//     fontFamily: 'fRegular',
//   },
//   subtitle1: {
//     fontSize: 16,
//     fontWeight: 350,
//     fontFamily: 'fRegular',
//   },
//   subtitle2: {
//     fontSize: 14,
//     fontWeight: 500,
//     fontFamily: 'fRegular',
//   },
//   body1: {
//     fontSize: 14,
//     fontWeight: 350,
//     fontFamily: 'fRegular',
//   },
//   body2: {
//     fontSize: 12,
//     fontWeight: 350,
//     fontFamily: 'fRegular',
//   },
//   caption: {
//     fontSize: 11,
//     fontWeight: 350,
//     fontFamily: 'fRegular',
//   },
//   button: {
//     fontSize: 14,
//     fontWeight: 800,
//     fontFamily: 'fRegular',
//   },
//   list: {
//     fontSize: 13,
//     fontWeight: 350,
//     fontFamily: 'fRegular',
//   },
// };

// export default typography;
