import { Alert, Button, Space } from 'antd';
import React from 'react';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useModal } from 'store/redux/states/ui/ui.hooks';

const FreeTrialNotice = (props) => {
  const { isLoading, hasActivePlan, hasFreePlan, getFreeDaysLeft } = useWorkspace();
  const [, setChoosePlanModalOpened] = useModal('choose_plan');

  if (isLoading) return null;

  if (!hasActivePlan()) {
    return null;
  }

  if (!hasFreePlan()) {
    return null;
  }

  return (
    <Alert
      className='px-8'
      message={`${getFreeDaysLeft()} days left untill free trial expires`}
      type='warning'
      showIcon
      closable
      action={
        <Space>
          <Button onClick={() => setChoosePlanModalOpened(true)} type='primary' ghost>
            Choose Plan
          </Button>
        </Space>
      }
    />
  );
};

export default FreeTrialNotice;
