import React from 'react';
import { LinearProgress, makeStyles, Typography } from '@material-ui/core';

const MAX_VALUE = 1000;

const ProgressFormatter = (props) => {
  const { leftIcon, color, row, column } = props;
  const value = row[column.id] || 0;
  const classes = useStyles({
    color: color,
  });

  return (
    <div className={`${classes.root} flex items-center`}>
      {leftIcon}
      <div className='w-full flex relative'>
        <LinearProgress
          variant='determinate'
          value={(value * 100) / MAX_VALUE}
          className={classes.progress}
          color='primary'
        />
        <Typography
          variant='h3'
          className='absolute left-3 text-white'
          style={{
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '28px',
          }}
        >
          {value}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& img': {
      marginRight: 10,
    },
  },
  progress: {
    flex: 1,
    height: 28,
    borderRadius: 99,
    background: '#CFDFEB',
    '& .MuiLinearProgress-bar': {
      backgroundColor: ({ color }) => color,
    },
  },
}));

export default ProgressFormatter;
