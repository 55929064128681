import React from 'react';
import { Checkbox } from '@material-ui/core';
import { useRowSelection, SELECT_COLUMN_KEY } from 'react-data-grid';

function stopPropagation(event) {
  event.stopPropagation();
}

const SelectCellFormatter = (props) => {
  const { value, disabled, onClick, onChange } = props;

  function handleChange(e) {
    onChange(e.target.checked, e.nativeEvent.shiftKey);
  }

  return <Checkbox disabled={disabled} checked={value} onChange={handleChange} onClick={onClick} />;
};

const SelectFormatter = (props) => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <SelectCellFormatter
      aria-label='Select'
      isCellSelected={props.isCellSelected}
      value={isRowSelected}
      onClick={stopPropagation}
      onChange={(checked, isShiftClick) => {
        onRowSelectionChange({ row: props.row, checked, isShiftClick });
      }}
    />
  );
};

const SelectGroupFormatter = (props) => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <SelectCellFormatter
      aria-label='Select Group'
      isCellSelected={props.isCellSelected}
      value={isRowSelected}
      onChange={(checked) => {
        onRowSelectionChange({ row: props.row, checked, isShiftClick: false });
      }}
      // Stop propagation to prevent row selection
      onClick={stopPropagation}
    />
  );
};

const SelectHeaderCell = (props) => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <div>
      <SelectCellFormatter
        aria-label='Select All'
        tabIndex={props.tabIndex}
        value={isRowSelected}
        onChange={(checked) => {
          onRowSelectionChange({ type: 'HEADER', checked });
        }}
      />
    </div>
  );
};

export const SelectColumn = {
  key: SELECT_COLUMN_KEY,
  name: '',
  cellClass: 'nnj-selector-cell flex-center',
  headerCellClass: 'nnj-selector-header',
  width: 55,
  maxWidth: 100,
  resizable: false,
  frozen: true,
  renderHeaderCell: SelectHeaderCell,
  renderCell: SelectFormatter,
  groupFormatter: SelectGroupFormatter,
};
