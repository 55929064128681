export default function loadFastSpringScript() {
  if (document.getElementById('fsc-api')) {
    return document.getElementById('fsc-api');
  }

  const script = document.createElement('script');
  script.src = 'https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js';
  script.type = 'text/javascript';
  script.id = 'fsc-api';
  script.setAttribute(
    'data-storefront',
    'mydataninja.onfastspring.com/popup-ninja-prod-advanced-1'
  );
  script.setAttribute('data-popup-webhook-received', 'fastSpringPopupCallback');
  // script.setAttribute('data-continuous', 'true');
  script.setAttribute('data-error-callback', 'errorCallback');
  script.setAttribute('data-debug', 'true');
  script.setAttribute('data-access-key', 'N8SJIBE4TA2RR4HWJMZPEW');

  document.head.appendChild(script);

  window.errorCallback = (...data) => {
    console.log('aaaaaaaa', data);
  };
  return script;
}
