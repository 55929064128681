import Ninja from '@ninja';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pixels: {},
};

export const networkDataSlice = createSlice({
  name: 'networkData',
  initialState: initialState,
  reducers: {
    setPixels: (state, action) => {
      const { payload } = action;
      state.pixels = Array.isArray(payload) ? Ninja.normalize(payload) : payload;
    },
  },
});

export const { setPixels, setPages } = networkDataSlice.actions;

export default networkDataSlice.reducer;
