export function provideTagsWithList(tag) {
  return (result) => {
    const tags = createTagsFromResponse(result, tag);
    tags.push({ type: tag, id: 'LIST' });
    return tags;
  };
}

export function provideTagsWithoutList(tag) {
  return (result) => {
    return createTagsFromResponse(result, tag);
  };
}

export function providesSingleResourceTag(tag) {
  return (result) => {
    if (!result) return [];

    if (result.id) {
      return [{ type: tag, id: result.id }];
    } else if (result.data?.id) {
      return [{ type: tag, id: result.data.id }];
    }

    return [];
  };
}

export function invalidatesTagsOfSingleResource(tag, options = null) {
  const id_key = options?.id_key ? options.id_key : 'id';

  return (result) => {
    if (!result) return [];

    if (result[id_key]) {
      return [{ type: tag, id: result[id_key] }];
    } else if (result.data?.[id_key]) {
      return [{ type: tag, id: result.data[id_key] }];
    }
  };
}

function createTagsFromResponse(res, tag) {
  if (!res) return [tag];
  let tags = [];
  if (Array.isArray(res)) {
    tags = res.map(({ id }) => ({ type: tag, id }));
  } else if (Array.isArray(res.data)) {
    tags = res.data.map(({ id }) => ({ type: tag, id }));
  }
  return tags;
}
