import ninjaApi from 'store/redux/apis/ninja.api';

export const emailServiceApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['General', 'DefaultWorkspace', 'User'],
  endpoints: (builder) => ({
    sendMailConfirmation: builder.mutation({
      query: (body) => ({
        url: '/setup/send-confirm-email',
        method: 'POST',
        body,
      }),
    }),

    confirmEmail: builder.mutation({
      query: (hash) => ({
        url: '/setup/confirm-email',
        method: 'POST',
        body: { hash: hash },
      }),
      invalidatesTags: ['DefaultWorkspace', 'User'],
    }),
  }),
});

export const { useSendMailConfirmationMutation, useConfirmEmailMutation } = emailServiceApi;
