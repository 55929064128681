import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import { billing_changeAllValueAction } from 'store/redux/states/billing/billing.state';
import { Typography } from '@material-ui/core';

export default function RedirectToSubscribeDialog() {
  const message = useSelector((state) => state.billing.errorMessage);
  const dispatch = useDispatch();
  return (
    <div className='w-[100vw] h-[100vh] bg-[#80808071] z-[3000] absolute top-0 left-0'>
      <div className={containerStyles()}>
        <div className=' absolute bottom-0 left-1/2 translate-x-[-50%]'>
          <Button
            component={Link}
            onClick={() =>
              dispatch(billing_changeAllValueAction({ status: true, path: '', message: '' }))
            }
            to={P.PROFILE.SETTINGS.WORKSPACE.BILLING}
            className={buttonStyles()}
            style={{
              height: 50,
              borderRadius: '6px',
              padding: '0 30px',
            }}
          >
            Pay Now
          </Button>
        </div>
        <div className={childElementStyles()}>
          <Typography className='text-[#2E3849]' variant='h3'>
            {message}
          </Typography>
        </div>
      </div>
    </div>
  );
}

function containerStyles() {
  return 'z-[2000] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 items-end justify-center p-4 text-center sm:items-center sm:p-0 min-w-[30vw] h-[auto]';
}
function childElementStyles() {
  return 'bg-[#fff] flex flex-col items-center justify-center gap-[20px] px-[50px] py-[20px] md:px-[80px] md:py-[30px] rounded-[8px] min-w-[75vw] min-h-[65vh] sm:min-w-[60vw] sm:min-h-[50vh] md:min-w-[40vw] md:min-h-[40vh] lg:min-w-[30vw] lg:min-h-[30vh]';
}

function buttonStyles() {
  return 'bg-[#FBECEA] text-[#FF4E00] text-[12px] font-semibold hover:bg-[#FBECEA] translate-y-[-20px]';
}
