import ninjaApi from 'store/redux/apis/ninja.api';

export const facebookPagesApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['FacebookPage', 'PageLeadForm'],
  endpoints: (build) => ({
    /* Fetch all facebook pages */
    listFacebookPages: build.query({
      query: (body) => ({
        url: '/network/facebook-pages/list',
        method: 'POST',
        body,
      }),
      providesTags: (res) => {
        return [
          ...res.map((res) => ({
            type: 'FacebookPage',
            id: res.id,
          })),
          { type: 'FacebookPage', id: 'LIST' },
        ];
      },
    }),
    // List facebook lead forms
    listPageLeadForms: build.query({
      query: (body) => ({
        url: '/network/facebook-pages/list-lead-forms',
        method: 'POST',
        body,
      }),
      providesTags: (res) => {
        return [
          ...res.map((res) => ({
            type: 'PageLeadForm',
            id: res.id,
          })),
          { type: 'PageLeadForm', id: 'LIST' },
        ];
      },
    }),
    // Sync facebook apges
    syncFacebookPages: build.mutation({
      query: (body) => ({
        url: '/network/facebook-pages/sync',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FacebookPage'],
    }),
  }),
});

export const {
  useListFacebookPagesQuery,
  useSyncFacebookPagesMutation,
  useListPageLeadFormsQuery,
} = facebookPagesApi;
