import { useChangeWorkspaceMutation } from 'store/redux/apis/workspace';

export default function useChangeWorkspace() {
  const [changeWorkspace, { isLoading }] = useChangeWorkspaceMutation();

  return {
    changeWorkspace,
    isLoading,
  };
}
