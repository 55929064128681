import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import BootstrapStyleTooltip from './BootstrapStyleTooltip';

const HelpButton = (props) => {
  const classes = useStyles();

  return (
    <BootstrapStyleTooltip title={props.help}>
      <div className={classes.buttonGroup}>
        <IconButton className={classes.iconButton}>
          <img src='/icons/help.svg' alt='' className={classes.icon} />
        </IconButton>
      </div>
    </BootstrapStyleTooltip>
  );
};

const useStyles = makeStyles(() => ({
  buttonGroup: {
    paddingLeft: '5px',
  },
  iconButton: {
    borderRadius: 100,
    width: 20,
    padding: 0,
  },
  icon: {
    objectFit: 'contain',
    height: '18px',
    width: '18px',
  },
}));

export default HelpButton;
