import AuthModule from 'modules/AuthModule';
import BootstrapAppModule from 'modules/BootstrapAppModule';
import GuestModule from 'modules/GuestModule';
import P from 'router/paths';

const rootRoutes = [
  {
    path: P.AUTH.INDEX,
    component: AuthModule,
    middleware: ['guest'],
  },
  {
    path: P.GUEST.INDEX,
    component: GuestModule,
  },
  {
    path: P.APP,
    component: BootstrapAppModule,
    middleware: ['auth'],
  },
];

export default rootRoutes;
