import { Alert, Button, Checkbox, Input, Modal, Skeleton, Typography } from 'antd';
import StatusSelect from 'components/Selects/StatusSelect';
import { useFormik } from 'formik';
import React from 'react';
import { AlertType } from 'components/Dialogs/const';
import {
  useCampaignDetailsQuery,
  useCopyCampaignMutation,
} from 'store/redux/states/ad_campaign/ad_campaign.api';
import { useModal } from 'store/redux/states/ui/ui.hooks';

const defaultSuffix = ' - Copy';

const CampaignCopyModal = () => {
  const [modalData, setModal] = useModal('campaign_copy');
  const { campaign_id } = modalData ? modalData : {};
  const isModalOpen = Boolean(campaign_id);
  const { data: campaign, isFetching: isFetchingLoading } = useCampaignDetailsQuery(
    { id: campaign_id, campaign_id: campaign_id },
    { skip: !Boolean(campaign_id) }
  );
  const [duplicateCampaignQuery, { isLoading: isDuplicationLoading, isSuccess }] =
    useCopyCampaignMutation({
      fixedCacheKey: JSON.stringify({ id: campaign_id, campaign_id: campaign_id }),
    });

  const handleFormSubmit = () => {
    const data = { ...form.values };
    data.campaign_id = campaign_id;

    duplicateCampaignQuery(data)
      .unwrap()
      .then((data) => {
        console.log(data);
        window.alert('Campaign Duplicated Successfully');
      })
      .catch(() => {
        window.alert(`Error While Duplicating Campaign`, () => {}, { type: AlertType.Eror });
      });
  };

  const form = useFormik({
    initialValues: {
      campaign_id: campaign_id,
      status: 'active',
      parameters: '',
      parent_parameters: false,
      suffix: defaultSuffix,
      copy_ads: true,
    },
    onSubmit: handleFormSubmit,
  });

  const isLoading = isFetchingLoading || isDuplicationLoading;

  return (
    <Modal
      footer={false}
      open={isModalOpen}
      onCancel={() => setModal(false)}
      title='Duplicate Campaign'
      destroyOnClose
      centered
    >
      <Skeleton active loading={isFetchingLoading}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            form.handleSubmit();
          }}
          className='flex flex-col gap-4'
        >
          {isSuccess ? <Alert type='success' message='Campaign Successfully Copied' /> : null}

          <div>
            <Typography.Text type='primary'>New Ad Name:</Typography.Text>
            <Input
              name='suffix'
              size='large'
              value={form.values.suffix}
              onChange={(e) => {
                if (String(e.target.value).trim() == '') {
                  form.setFieldValue('suffix', '_');
                } else {
                  form.setFieldValue('suffix', e.target.value);
                }
              }}
              addonBefore={campaign?.name}
              onBlur={form.handleBlur}
              disabled={isLoading}
            />
          </div>
          <div>
            <Typography.Text type='primary'>Parameters For New Ads:</Typography.Text>
            <Input
              name='parameters'
              size='large'
              placeholder='foo=bar'
              value={form.values.parameters}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              disabled={isLoading || form.values.parent_parameters}
            />
            <Checkbox
              checked={form.values.parent_parameters}
              disabled={isLoading}
              onChange={(e) => form.setFieldValue('parent_parameters', e.target.checked)}
            >
              Copy From Original
            </Checkbox>
          </div>
          <div>
            <Typography.Text type='primary'>Status:</Typography.Text>
            <StatusSelect
              mode={void 0}
              size='large'
              name='status'
              value={form.values.status}
              onChange={(status) => form.setFieldValue('status', status)}
              disabled={isLoading}
              onBlur={form.handleBlur}
            />
          </div>
          <div className='flex flex-col'>
            <Typography.Text type='primary'>Select if you want to copy ads:</Typography.Text>
            <Typography.Text type='secondary'>
              If not selected the ads will not be copies
            </Typography.Text>
            <Checkbox
              checked={form.values.copy_ads}
              disabled={isLoading}
              onChange={(e) => form.setFieldValue('copy_ads', e.target.checked)}
            >
              Copy Ads
            </Checkbox>
          </div>
          <div className='flex-center'>
            <Button
              loading={isDuplicationLoading}
              disabled={isSuccess}
              htmlType='submit'
              type='primary'
            >
              Submit Copy
            </Button>
          </div>
        </form>
      </Skeleton>
    </Modal>
  );
};

export default CampaignCopyModal;
