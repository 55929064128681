import Dialog from 'components/Dialogs/Dialog';
import React from 'react';
import { Button, Typography } from '@material-ui/core';

const ConfirmationDialog = (props) => {
  const { onClose = () => {}, onOK = () => {}, text, config = {} } = props;
  return (
    <Dialog open onClose={onClose}>
      <div className='flex column items-center p-8'>
        {config.icon ? <img src={config.icon} /> : null}
        <Typography variant='h1' style={{ color: config.color, marginTop: 10, fontSize: 16 }}>
          {config.title}
        </Typography>
        <Typography variant='h3' style={{ marginTop: 30, fontWeight: 350, fontSize: 14 }}>
          {text
            ? text
            : 'This is done for accessibility purposes, however, it might create issues. In the event theusers need to interact with another part of the page.'}
        </Typography>
        <div style={{ marginTop: 30 }} className='flex gap-4'>
          <Button onClick={onOK}>OK</Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
