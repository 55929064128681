import { useSnackbar } from 'notistack';
import { createContext, useContext, useEffect } from 'react';

const NotificationsContext = createContext();

export const NotificationsContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const notificationCreatedListener = function (e) {
    const notifications = e.detail;
    if (!Array.isArray(notifications)) return;
    notifications.forEach((n) => {
      enqueueSnackbar(n._message, { variant: n._status });
    });
  };

  useEffect(() => {
    window.addEventListener('notificationCreated', notificationCreatedListener);

    return window.removeEventListener('notificationCreated', notificationCreatedListener);
  }, []);

  return children;
};

const useNotifications = () => useContext(NotificationsContext);

export default useNotifications;

/**
 * invoke notificationCreated event to display notification.
 */
export const notificate = (data) => {
  var event = new CustomEvent('notificationCreated', {
    detail: data,
  });

  window.dispatchEvent(event);
};

/**
 * showNotification.
 */
export const showNotification = (message, status) => {
  var event = new CustomEvent('notificationCreated', {
    detail: [{ _message: message, _status: status }],
  });

  window.dispatchEvent(event);
};
