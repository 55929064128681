import ninjaApi from 'store/redux/apis/ninja.api';

export const adFormDraftApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['AdForm'],
  endpoints: (build) => ({
    //save ad creation form as a draft
    saveDraft: build.mutation({
      query: (body) => ({
        url: '/network/draft/save-draft',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AdForm'],
    }),

    //get ad creation draft forms
    getDrafts: build.query({
      query: (body) => ({
        url: '/network/draft/get-drafts',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AdForm'],
    }),

    //load  ad creation single draft form
    getDraft: build.query({
      query: (body) => ({
        url: '/network/draft/get-draft',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AdForm'],
    }),
  }),
});
export const { useSaveDraftMutation, useGetDraftsQuery, useGetDraftQuery } = adFormDraftApi;
