import ninjaApi from 'store/redux/apis/ninja.api';

export const salesApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Country'],
  endpoints: (build) => ({
    country: build.mutation({
      query: (body) => ({
        url: '/utils/countries-with-phone-codes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Country'],
    }),
  }),
});
export const { useCountryMutation } = salesApi;
