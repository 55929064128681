/* eslint-disable no-extend-native */
Object.defineProperty(Object.prototype, 'containsEveryKey', {
  value: function () {},
});

Object.defineProperty(Object.prototype, 'getByDot', {
  value: function (key, def = undefined) {
    let data = this;
    for (let i of key.split('.')) {
      if (data.hasOwnProperty(i)) {
        data = data[i];
      } else {
        return def;
      }
    }
    return data;
  },
  configurable: true,
});
