import { createSlice } from '@reduxjs/toolkit';
import url from '@ninja/url';
import storage from '@ninja/storage';

const getDate = (key) => {
  return url.get(key, () => storage.get('dates.' + key));
};

const default_date_from = getDate('date_from');
const default_date_to = getDate('date_to');

const initialState = {
  /**
   * Format that is used to format all full length dates
   */
  format: 'yyyy-MM-dd',
  /**
   * Format that is used to format all short dates
   */
  format_short: 'dd mmm',

  dates: {
    /**
     * Global scope of date_from and date_to
     */
    global: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    reporting: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    accounts_balance: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    total_statistics: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    daily_statistics: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    worst_ads: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    best_ads: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    crm_leads: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    daily_sales: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
    event_data: {
      date_from: default_date_from,
      date_to: default_date_to,
    },
  },
};

export const data = createSlice({
  name: 'dates',
  initialState: initialState,
  reducers: {
    setDatesAction(state, action) {
      state.dates[action.payload.key] = { ...action.payload.dates };

      if (action.payload.change_global) {
        Object.keys(state.dates).forEach((date_key) => {
          state.dates[date_key] = { ...action.payload.dates };
        });
      }
    },
  },
});

export const { setDatesAction } = data.actions;

export default data.reducer;
