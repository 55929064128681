import { format } from 'date-fns';

/**
 * new Date().format() => "2021-09-11"
 * @returns {String}
 */
/* eslint-disable-next-line */
Date.format = function (date, f = 'yyyy-MM-dd') {
  if (!date) return null;
  return format(date, f);
};
