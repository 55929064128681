import '@ninja';
import useBootstrapUser from 'store/redux/hooks/actions/useBootstrapUser';
import rootRoutes from 'router/rootRoutes';
import 'react-data-grid/lib/styles.css';
import { renderRoutes } from './router/routes';

const App = () => {
  useBootstrapUser();

  return renderRoutes(rootRoutes);
};

export default App;
