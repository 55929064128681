import ninjaApi from 'store/redux/apis/ninja.api';

export const pixelApi = ninjaApi.injectEndpoints({
  tagTypes: ['Pixel'],
  overrideExisting: true,
  endpoints: (build) => ({
    pixels: build.query({
      query: (body) => ({
        url: '/network/pixel/get-pixels',
        method: 'POST',
        body,
      }),
    }),

    pages: build.query({
      query: (body) => ({
        url: '/network/page/get-pages',
        method: 'POST',
        body,
      }),
    }),

    // search pixels
    searchPixel: build.query({
      query: (body) => ({
        url: '/network/pixel/search',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { usePixelsQuery, usePagesQuery, useSearchPixelQuery } = pixelApi;
