import Module from 'modules/Module';
import LoginPage from 'modules/AuthModule/pages/LoginPage';
import RegisterPage from 'modules/AuthModule/pages/RegisterPage';
import P from 'router/paths';
import RequestResetPasswordPage from './pages/RequestResetPasswordPage';
import UpdatePasswordPage from './pages/UpdatePasswordPage';

const AuthModule = () => {
  const mod = new Module({
    routes: [
      { path: P.AUTH.REGISTER, guest: true, component: RegisterPage },
      { path: P.AUTH.LOGIN, guest: true, component: LoginPage },
      { path: P.AUTH.RESET_PASSWORD, guest: true, component: RequestResetPasswordPage },
      { path: P.AUTH.UPDATE_PASSWORD, guest: true, component: UpdatePasswordPage },
    ],
  });
  return mod.render();
};

export default AuthModule;
