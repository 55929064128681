import ninjaApi from 'store/redux/apis/ninja.api';

export const GeneralApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['General'],
  endpoints: (builder) => ({
    /* Fetch all ad name details */
    adDetails: builder.query({
      query: (body) => ({
        url: '/network/adset/details',
        method: 'POST',
        body,
      }),
      providesTags: ['General'],
    }),

    /* Fetch all account  details */
    accountDetails: builder.query({
      query: (body) => ({
        url: '/network/account/details',
        method: 'POST',
        body,
      }),
      providesTags: ['General'],
    }),
  }),
});
export const { useAdDetailsQuery, useAccountDetailsQuery } = GeneralApi;
