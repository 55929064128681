import ninjaApi from 'store/redux/apis/ninja.api';

export const conversionRulesApi = ninjaApi.injectEndpoints({
  tagTypes: ['ConversionRule', 'Workspace'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Get All Conversion Rules
     */
    conversionRulesList: builder.query({
      query: (body) => ({
        url: '/conversion-rules/list',
        method: 'POST',
        body,
      }),
      providesTags: ['ConversionRule'],
    }),
    /**
     * Get Conversion rule
     */
    getConversionRule: builder.query({
      query: (body) => ({
        url: '/conversion-rules/get',
        method: 'POST',
        body,
      }),
      providesTags: ['ConversionRule'],
    }),
    /**
     * Get Conversion rule
     */
    updateConversionRule: builder.mutation({
      query: (body) => ({
        url: '/conversion-rules/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ConversionRule'],
    }),
    /**
     * Get Conversion rule
     */
    createConversionRule: builder.mutation({
      query: (body) => ({
        url: '/conversion-rules/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ConversionRule'],
    }),
    /**
     * Get Conversion rule
     */
    deleteConversionRule: builder.mutation({
      query: (body) => ({
        url: '/conversion-rules/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ConversionRule'],
    }),
  }),
});

export const {
  useConversionRulesListQuery,
  useGetConversionRuleQuery,
  useUpdateConversionRuleMutation,
  useDeleteConversionRuleMutation,
  useCreateConversionRuleMutation,
} = conversionRulesApi;
