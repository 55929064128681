import ninjaApi from 'store/redux/apis/ninja.api';
import useAuth from 'store/redux/hooks/useAuth';
import { provideTagsWithList } from 'store/redux/utils/rtk-tag-utils';

/**
 * Returns workspace
 */
export const permissionsApi = ninjaApi.injectEndpoints({
  reducerPath: 'setupApi',
  overrideExisting: false,
  tagTypes: ['Modules', 'Workspace', 'Permisssions', 'WorkspaceUser', 'DefaultWorkspace'],
  endpoints: (build) => ({
    /**
     * List all Accessed modules
     */
    getPermissions: build.query({
      query: () => ({
        url: '/workspace/permissions/get-permissions',
        method: 'POST',
      }),
      providesTags: [{ type: 'Permisssions', id: 'LIST' }],
    }),

    /**
     * List all avialable permission modules
     */
    listModules: build.query({
      query: () => ({
        url: '/workspace/permissions/list-modules',
        method: 'POST',
      }),
      providesTags: [{ type: 'Modules', id: 'LIST' }],
    }),

    /**
     * Get invitation by token
     */
    getInvitationByToken: build.query({
      query: (body) => ({
        url: '/workspace/permissions/get-invite',
        method: 'POST',
        body: body,
      }),
      providesTags: [{ type: 'Modules', id: 'LIST' }],
    }),

    /**
     * Get invitation by token
     */
    acceptInvite: build.mutation({
      query: (body) => ({
        url: '/workspace/permissions/accept-invite',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Modules', id: 'LIST' }, 'DefaultWorkspace'],
    }),

    /**
     * Send invite to user
     */
    sendInvite: build.mutation({
      query: (body) => ({
        url: '/workspace/permissions/send-invite',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [{ type: 'Modules', id: 'LIST' }, 'PermissionInvite'],
    }),

    /**
     * Remove user permission
     */
    removePermission: build.mutation({
      query: (body) => ({
        url: '/workspace/permissions/remove-permission',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['User', 'Workspace', 'WorkspaceUser', 'PermissionInvite'],
    }),

    /**
     * remove invited users
     */
    removeInvite: build.mutation({
      query: (body) => ({
        url: '/workspace/permissions/remove-invite',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Workspace', 'WorkspaceUser', 'PermissionInvite'],
    }),

    /**
     * Remove user permission
     */
    changePermissionRole: build.mutation({
      query: (body) => ({
        url: '/workspace/permissions/change-role',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['WorkspaceUser'],
    }),

    /**
     * Get workspace users
     */
    getWorkspaceUsers: build.query({
      query: (body) => ({
        url: '/workspace/permissions/get-users',
        method: 'POST',
        body: body,
      }),
      providesTags: provideTagsWithList('WorkspaceUser'),
    }),

    //pending invite
    pendingInvite: build.query({
      query: (body) => ({
        url: 'workspace/permissions/pending-invites',
        method: 'POST',
        body: body,
      }),
      providesTags: ['PermissionInvite'],
    }),
  }),
});

export const {
  useListModulesQuery,
  useGetPermissionsQuery,
  useGetInvitationByTokenQuery,
  useLazyGetInvitationByTokenQuery,
  useSendInviteMutation,
  useAcceptInviteMutation,
  useGetWorkspaceUsersQuery,
  useRemovePermissionMutation,
  useRemoveInviteMutation,
  useChangePermissionRoleMutation,
  usePendingInviteQuery,
} = permissionsApi;

export const usePermissions = () => {
  const { loading: isAuthLoading, auth } = useAuth();
  const {
    data: permissions = {},
    isLoading,
    isFetching,
  } = useGetPermissionsQuery(null, {
    skip: isAuthLoading || !auth,
  });

  const canView = (module) => {
    return true;
    // eslint-disable-next-line no-unreachable
    if (typeof permissions[module] === 'undefined') return false;
    if (permissions[module].can_view || permissions[module].can_edit) return true;

    return false;
  };

  const canEdit = (module) => {
    return true;
    // eslint-disable-next-line no-unreachable
    if (typeof permissions[module] === 'undefined') return false;
    if (permissions[module].can_edit) return true;

    return false;
  };

  const loading = isAuthLoading || isLoading || isFetching;

  return {
    permissions,
    loading,
    canView,
    canEdit,
  };
};
