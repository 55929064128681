import ninjaApi from 'store/redux/apis/ninja.api';

export const utilsApi = ninjaApi.injectEndpoints({
  tagTypes: ['Utils'],
  overrideExisting: true,
  endpoints: (builder) => ({
    getTimezones: builder.mutation({
      query: (body) => ({
        url: '/utils/timezones',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Utils'],
    }),
    getCountries: builder.mutation({
      query: (body) => ({
        url: '/utils/countries',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetTimezonesMutation, useGetCountriesMutation } = utilsApi;
