/* eslint-disable no-extend-native */
/**
 * @todo make configurable by .env file
 */

/**
 * "example".ucFirst() => "Example"
 * @returns {String}
 */
Object.defineProperty(String.prototype, 'ucFirst', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  configurable: true,
});

/**
 * Split strings by limit
 * "dates.widgets.dashboard.date_from".splitByLimit('.', 2) => ["dates", "widgets", "dashboard.date_from"]
 * @returns {Array}
 */
Object.defineProperty(String.prototype, 'splitByLimit', {
  value: function (separator, limit = 1) {
    let splitted = this.split(separator, limit);
    let joined = [...splitted, ''].join(separator);
    let replaced = this.replace(joined, '');
    return [...splitted, replaced];
  },
  configurable: true,
});
