import { Button, FormControlLabel, makeStyles, Popover } from '@material-ui/core';
import StyledCard from 'components/Cards/StyledCard';
import Checkbox from 'components/switches/Checkbox';
import React, { useEffect, useState } from 'react';
import useLocalStorage from '@ninja/hooks/useLocalStorage';

const ColumnsCustomize = ({
  customizationId,
  onUpdate = () => {},
  columns,
  customizationEdit,
  removeColumns = [],
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tmpColumns, setTmpColumns] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [localColumns, setLocalColumns] = useLocalStorage({ key: customizationId });

  const updatedObject = { ...tmpColumns };

  for (const columnId of removeColumns) {
    if (updatedObject[columnId] && customizationEdit !== null) {
      updatedObject[columnId] = { ...updatedObject[columnId], hidden: true };
    }
  }

  useEffect(() => {
    const tempCustomColumns = {
      ...localColumns,
    };

    columns.forEach((c) => {
      if (!tempCustomColumns[c.key]) {
        tempCustomColumns[c.key] = { hidden: false, name: c.name };
      }
    });

    setLocalColumns(tempCustomColumns);
  }, [columns]);

  useEffect(() => {
    setTmpColumns(localColumns);
    onUpdate(updatedObject);
  }, [localColumns, customizationEdit]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onPopupSaveClick = () => {
    setLocalColumns(tmpColumns);
    onUpdate(updatedObject);
    setAnchorEl(null);
  };

  const handleChange = (columnId, checked) => {
    console.log(columnId, checked);
    setTmpColumns((ps) => ({
      ...ps,
      [columnId]: {
        ...ps[columnId],
        hidden: !checked,
      },
    }));
  };
  const handleSelectAllChange = (checked) => {
    let colIds = Object.keys(updatedObject);
    setSelectAll(checked);

    colIds.forEach((columnId) => handleChange(columnId, checked));
  };

  return (
    <div className={classes.customizeContainer}>
      <Button
        variant='contained'
        color='#ECEEEF'
        onClick={handleClick}
        style={{ padding: 5, minWidth: 40 }}
      >
        <img src='/icons/tune.svg' />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledCard avatar={<img src='/icons/tune.svg' />} padding={[1.25]} title='Customization'>
          <div className={classes.fieldsContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  variant='outlined'
                  text='Select All'
                  checked={selectAll}
                  onChange={(e) => {
                    handleSelectAllChange(e.target.checked);
                  }}
                />
              }
              label='Select All'
            />
            {Object.entries(updatedObject).map(([columnId, column]) => (
              <div className={`align-center grid ${classes.field}`} key={columnId}>
                <FormControlLabel
                  control={
                    <Checkbox
                      variant='outlined'
                      text={column.name}
                      checked={!column.hidden}
                      onChange={(e) => {
                        handleChange(columnId, e.target.checked);
                      }}
                    />
                  }
                  label={column.name || columnId}
                />
              </div>
            ))}
          </div>
          <div className={`grid justify-end ${classes.footer}`}>
            <Button
              onClick={handleClose}
              style={{
                width: 80,
                height: 40,
                color: '#8A8A8A',
                backgroundColor: '#ffffff',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onPopupSaveClick}
              style={{
                width: 80,
                height: 40,
                color: 'white',
              }}
            >
              Save
            </Button>
          </div>
        </StyledCard>
      </Popover>
    </div>
  );
};

export default ColumnsCustomize;

const useStyles = makeStyles(() => ({
  fieldsContainer: {
    maxHeight: 300,
    overflowY: 'auto',
    padding: 10,
  },
  footer: {
    display: 'flex',
    paddingTop: 10,
    borderTop: '1px solid gray',
    marginTop: 10,
  },
  field: {
    width: 258.4,
  },
}));
