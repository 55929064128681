/**
 * If value is callable this will call it
 * otherwise returns value
 * @param {any} val
 */
export function value(val) {
  if (typeof val === 'function') {
    return val();
  }
  return val;
}

/**
 * If given value is syntax error
 * @param {Error} err thrown error
 * @returns {Boolean}
 */
export function isSyntaxError(err) {
  return err.name === 'SyntaxError';
}
