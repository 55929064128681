import { Input, Select, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import CountriesSelect from '../CountriesSelect/CountriesSelect';

const PhoneNumberInput = (props) => {
  const { onCountryChange, country, onChange, ...rest } = props;

  return (
    <Space.Compact style={{ width: '100%' }}>
      <CountriesSelect
        value={country}
        onChange={onCountryChange}
        size='large'
        popupMatchSelectWidth={false}
        autocomplete='one-time-code'
        showPhoneCode
      />
      <Input type='number' onChange={onChange} {...rest} />
    </Space.Compact>
  );
};

export default PhoneNumberInput;
