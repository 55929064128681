import { useSelector } from 'react-redux';
import RedirectToSubscribeDialog from 'components/Dialogs/RedirectToSubscribeDialog';
import React from 'react';
const useSubscriptionRedirect = () => {
  const isSubscriptionActive = useSelector((state) => state.billing.subscriptionStatus);

  return !isSubscriptionActive ? <RedirectToSubscribeDialog /> : null;
};

export default useSubscriptionRedirect;
