import { ButtonBase, makeStyles } from '@material-ui/core';
import React from 'react';

const ActionButton = (props) => {
  const { color, onClick, icon, label, withoutBorder, ...rest } = props;

  const classes = useStyles({
    color: color,
    hasLabel: Boolean(label),
    withoutBorder: withoutBorder,
  });

  return (
    <div className={classes.actionButtonContainer}>
      <ButtonBase
        onClick={onClick}
        classes={{
          disabled: classes.buttonDisabled,
        }}
        className={classes.iconButton}
        {...rest}
      >
        {icon ? icon : null} {label}
      </ButtonBase>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    border: (props) => (props.withoutBorder ? '' : `1px solid ${theme.colors.xlight}`),
    height: 25,
    padding: theme.spacing(0.25),
    borderRadius: 100,
  },
  buttonDisabled: {
    color: `${theme.colors.xlight} !important`,
    pointerEvents: 'unset',
    cursor: 'not-allowed !important',
  },
  iconButton: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    color: (props) => props.color,
    borderRadius: 100,
    padding: (props) => (props.hasLabel ? theme.spacing(0, 1) : theme.spacing(0.5)),
    fontWeight: 'bold',
    '& svg': {
      fontSize: '14px',
      marginRight: (props) => (props.hasLabel ? theme.spacing() : 0),
    },
  },
  icon: {
    objectFit: 'contain',
    height: '15px',
  },
}));

export default ActionButton;
