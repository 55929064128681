import { makeStyles } from '@material-ui/core/styles';
import { mdiHelp } from '@mdi/js';
import Icon from '@mdi/react';
import HeaderButton from 'components/layouts/components/Header/HeaderButton';
import React from 'react';

const SettingsMenu = () => {
  const classes = useStyles();

  const handleClick = (_) => {
    window.open('https://www.youtube.com/watch?v=6zE1AByuwgk');
  };

  return (
    <HeaderButton color='darkText' className={classes.container} onClick={handleClick}>
      <Icon path={mdiHelp} />
    </HeaderButton>
  );
};

export default SettingsMenu;

const useStyles = makeStyles((theme) => ({
  profileMenu: {
    marginTop: theme.spacing(),
    padding: 0,
  },
  menuItem: {
    textDecoration: 'none',
    padding: theme.spacing(1, 2),
    color: theme.colors.darkText,
    fontSize: 14,
    fontFamily: 'fregular',
    fontWeight: 500,
    minWidth: '20ch',
    borderBottom: `1px solid ${theme.colors.xlight}`,
    '& svg': {
      marginRight: theme.spacing(),
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
