import React from 'react';
const arrows = {
  success: '/icons/greenarrow.svg',
  error: '/icons/redarrow.svg',
};

export default function Arrows({ state }) {
  const ar = arrows[state] ?? arrows.error;

  return <img src={ar} className='w-2.5' />;
}
