import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toolbar: {
    types: ['video', 'image', 'document'],
    order_by: 'date',
    search: '',
  },
  selecteds: [],
  image_to_crop: null,
  cropped_image: null,
  cropper_title: 'Crop The Image',
  cropper_helper_text: null,
  crop_aspect_ratio: 16 / 9,
  current_folder: '/',
  folder_creation_form_opened: false,
};

export const fileManagerSlice = createSlice({
  name: 'filemanager',
  initialState: initialState,
  reducers: {
    /**
     * Set whole state
     * @returns
     */
    filemanager_setStateAction: (state, action) => {
      if (typeof action.payload !== 'object') return console.log('invalid object');

      for (let key in action.payload) {
        state[key] = action.payload[key];
      }
    },
    /**
     * Set toolbar
     */
    filemanager_setToolbarAction: (state, action) => {
      state.toolbar = {
        ...state.toolbar,
        ...action.payload,
      };
    },
    /**
     * Select files
     */
    filemanager_setSelectedFilesAction: (state, action) => {
      state.selected_files = Array.isArray(action.payload) ? action.payload : [];
    },
    /**
     * Select files
     */
    filemanager_setCurrentFolderAction: (state, action) => {
      state.current_folder = typeof action.payload === 'string' ? action.payload : '/';
    },
    /**
     * Set if form is opened
     */
    filemanager_setFolderCreationFormOpenedAction: (state, action) => {
      state.folder_creation_form_opened = Boolean(action.payload);
    },
  },
});

export const {
  filemanager_setToolbarAction,
  filemanager_setSelectedFilesAction,
  filemanager_setCurrentFolderAction,
  filemanager_setFolderCreationFormOpenedAction,
  filemanager_setStateAction,
} = fileManagerSlice.actions;

export default fileManagerSlice.reducer;
