import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subscriptionStatus: false,
  fastspringPLanPath: '',
  errorMessage: '',
};

export const billlingSlice = createSlice({
  name: 'billing',
  initialState: initialState,
  reducers: {
    billing_setSubscriptionStatusAction: (state, action) => {
      if (typeof action.payload !== 'boolean') return;
      state.subscriptionStatus = action.payload;
    },
    billing_setFastspringPlanPathAction: (state, action) => {
      if (typeof action.payload !== 'string') return;
      state.fastspringPLanPath = action.payload;
    },
    billing_setErrorMessageAction: (state, action) => {
      if (typeof action.payload !== 'string') return;
      state.errorMessage = action.payload;
    },
    billing_changeAllValueAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.subscriptionStatus = action.payload.status;
      state.fastspringPLanPath = action.payload.path;
      state.errorMessage = action.payload.message;
    },
  },
});

export const {
  billing_setSubscriptionStatusAction,
  billing_setFastspringPlanPathAction,
  billing_setErrorMessageAction,
  billing_changeAllValueAction,
} = billlingSlice.actions;

export default billlingSlice.reducer;
