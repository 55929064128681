import { InputAdornment, TextField } from '@material-ui/core';
import InputLoadingSpinner from 'components/input/TextInput/adornments/InputLoadingSpinner';
import ActionButton from 'components/inputs/adornments/ActionButton';
import InfoButton from 'components/inputs/adornments/InfoButton';
import SplitButtons from 'components/inputs/adornments/Networks';
import React, { forwardRef } from 'react';
import ErrorAdornment from './adornments/ErrorAdornment';

const TextInput = (props, ref) => {
  const {
    children,
    fb,
    gg,
    info,
    loading,
    InputProps = {},
    InputLabelProps = {},
    white,
    inFieldErrors = false,
    errorText = null,
    height,
    endAdornment = null,
    width,
    ...rest
  } = props;

  if (white) {
    rest.background = 'white';
  }

  const generateAdornment = () => {
    return (
      <InputAdornment>
        {/* If Errortext Passed */}
        {errorText && inFieldErrors ? <ErrorAdornment errorText={errorText} /> : null}
        {Array.isArray(props.actionbuttons)
          ? props.actionbuttons.map((butt, ind) => {
              return <ActionButton key={ind} {...butt} />;
            })
          : null}
        {fb || gg ? <SplitButtons fb={fb} gg={gg} /> : null}
        {typeof info === 'string' ? <InfoButton info={info} /> : null}
        {loading ? <InputLoadingSpinner /> : <div />}
        {endAdornment && endAdornment}
      </InputAdornment>
    );
  };

  if (rest.type === 'date') {
    InputLabelProps.shrink = true;
  }

  return (
    <TextField
      inputRef={ref}
      InputProps={{
        ...InputProps,
        autoComplete: 'off',
        endAdornment: generateAdornment(),
      }}
      InputLabelProps={{
        ...InputLabelProps,
      }}
      fullWidth
      variant='outlined'
      autoComplete='off'
      {...rest}
    >
      {children}
    </TextField>
  );
};

export default forwardRef(TextInput);
