import {
  useCreateFastSpringSubscriptionMutation,
  useGetFastSpringPayloadMutation,
  useUpdateFastSpringSubscriptionMutation,
} from 'store/redux/states/billing/billing.api';
import useFastSpringScript from '@lib/fastspring/useFastSpringScript';
import gtagevent from '@lib/google-analytics/gtagevent';
import useAuth from 'store/redux/hooks/useAuth';

function useFastspringButton(props) {
  const [subscribeFastSpringQuery, { isLoading: subscriptionLoading }] =
    useCreateFastSpringSubscriptionMutation();
  const [updateFastSpringSubscriptionQuery, { isLoading: isUpdateLoading }] =
    useUpdateFastSpringSubscriptionMutation();
  const [getFastSpringPayloadQuery, { isLoading: isPayloadLoading }] =
    useGetFastSpringPayloadMutation();

  const { user } = useAuth();

  function fastSpringPopupCallback(data) {
    console.log('fastspring data', data);

    subscribeFastSpringQuery({
      data: data,
    })
      .then((res) => {
        gtagevent('event', 'Purchase', {
          event_category: props.planCode,
          event_label: user.id,
        });
        props.onSuccess?.(res);
      })
      .catch((err) => {
        props.onError?.(err);
      });
  }

  const { loading: scriptLoading } = useFastSpringScript({ callback: fastSpringPopupCallback });

  function checkout(planCode) {
    /** If plan update change is requested */
    if (props.update) {
      return updateFastSpringSubscriptionQuery({
        code: planCode,
      })
        .unwrap()
        .then((data) => {
          return data;
        })
        .catch((err) => {
          props?.onError(err);
        });
    }

    try {
      getFastSpringPayloadQuery({
        code: planCode,
      })
        .unwrap()
        .then((data) => {
          window.fastspring.builder.clean();
          window.fastspring.builder.secure(data.securePayload, data.secureKey);
          window.fastspring.builder.checkout();
        });
    } catch (error) {
      props?.onError?.(error);
    }
  }

  return {
    checkout,
    isScriptLoading: scriptLoading,
    isSubscriptionLoading: subscriptionLoading || isUpdateLoading || isPayloadLoading,
  };
}

export default useFastspringButton;
