import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const GreenSwitch = withStyles({
  root: {
    width: 'auto',
    height: 'auto',
    padding: '4px',
  },
  switchBase: {
    color: '#5d5d5d',
    padding: '4px',
    '&$checked': {
      color: '#91c716',
    },
    '&$checked + $track': {
      backgroundColor: '#91c716',
    },
  },
  thumb: {
    width: '12.43px',
    height: '12px',
  },
  input: {
    left: 0,
    width: '100%',
  },
  checked: {
    transform: 'translateX(10px)!important',
  },
  track: {
    backgroundColor: '#5d5d5d',
    height: '12px',
    width: '22px',
  },
})(Switch);

export default GreenSwitch;
