import { useDispatch } from 'react-redux';
import ninjaApi from 'store/redux/apis';
import { logoutAction } from 'store/redux/states/auth.state';
import { removeApiAuthToken } from 'store/redux/utils/authtoken';

export default function useLogout() {
  const dispatch = useDispatch();

  return () => {
    removeApiAuthToken();
    dispatch(logoutAction());
    dispatch(ninjaApi.util.resetApiState());
  };
}
