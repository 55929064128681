import { useDispatch, useSelector } from 'react-redux';
import { setDatesAction } from './dates.state';

export const useWidgetDate = (key = 'global') => {
  const dates = useSelector((state) => state.dates.dates[key]);
  const dispatch = useDispatch();

  // All requested dates must be valid
  if (dates === 'undefined') {
    throw new Error('Invalid Date key requested');
  }

  /**
   * Update dates
   * if change_global is passed, it will update all dates globally
   *
   * @param {Object} data
   * @param {String} data.date_from
   * @param {String} data.date_to
   * @param {Boolean} data.change_global
   */
  const setDate = (data) => {
    const dates = {};
    if (data.date_from) {
      dates.date_from = data.date_from;
    }

    if (data.date_to) {
      dates.date_to = data.date_to;
    }

    dispatch(
      setDatesAction({
        key: key,
        dates: dates,
        change_global: Boolean(data.change_global),
      })
    );
  };

  return {
    ...dates,
    dates: dates,
    setDate,
  };
};
