import Module from 'modules/Module';
import P from 'router/paths';
import ConfirmInvitationPage from './pages/ConfirmInvitationPage';

const GuestModule = () => {
  const module = new Module({
    routes: [{ path: P.GUEST.CONFIRM_INVITE, component: ConfirmInvitationPage }],
  });

  return module.render();
};

export default GuestModule;
