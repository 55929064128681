import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  draftLoaded: false,
  error: false,
  form: {
    mode: 'advanced',
  },
  formStatus: {
    publishedCampaign: false,
    publishedAdgroup: false,
    publishedAd: false,
  },
  accounts: {
    facebook: null,
    google: null,
  },
  networks: {
    facebook: true,
    google: true,
    google_search: false,
    all: true,
    single: null,
  },
  campaign: {
    campaign_name: '',
    custom_name: false,
    campaign_status: 'active',
    campaign_objective: 'traffic',
    existing_campaign_id: null /* ? */,
    creation_loading: false /* ? */,
  },
  adgroup: {
    existing_adgroup_id: null,
    adgroup_name: 'DN_Adgroup',
    custom_name: false,
    adgroup_status: 'active',
    adgroup_start_date: Date.format(new Date()),
    adgroup_end_date: undefined,
    show_end_date_input: false,
    show_exclude_locations: false,
    adgroup_bid_optimization: 'clicks',
    adgroup_google_target_roas: 10,
    adgroup_facebook_target_roas: 10,
    adgroup_google_conversion_ids: [],
    adgroup_fb_conversion_ids: [],
    adgroup_billing_event: 'views',
    adgroup_bid_type: 'automatic',
    adgroup_bid_strategy: 'bid_cap',
    adgroup_fb_bid_amount: '',
    adgroup_google_bid_amount: 0.1,
    adgroup_google_budget: 10,
    adgroup_fb_budget: 10,
    adgroup_google_bid_enhanced: '0',
    adgroup_set_max_click_limit: 0,
    adgroup_google_max_bid_amount: null,
    adgroup_is_dynamic: false,
    creation_loading: false,
    parental_status: 'all',
  },
  targeting: {
    targ_gender: 'all',
    targ_household_income: ['top_10', '11_20', '21_30', '31_40', '41_50', 'lower_50', 'unknown'],
    targ_parental_status: '',
    targ_devices: ['desktop', 'mobile', 'tablet'],
    targ_os: [],
    targ_only_wifi: true,
    targ_fb_inc_audiences: [],
    targ_fb_exc_audiences: [],
    targ_google_inc_audiences: [],
    targ_google_exc_audiences: [],
    targ_age_from: 18,
    targ_age_to: '65+',
    targ_languages: [{ value: '1000000', name: 'All Languages' }],
    targ_keywords: {
      broad: [],
      phrases: [],
      exact: [],
    },
    targ_exclude_keywords: {
      broad: [],
      phrases: [],
      exact: [],
    },
    targ_facebook_interests: [],
    targ_facebook_exclude_locations: [],
    targ_facebook_include_locations: [],
    targ_location_radius: 15,
    targ_location_unit: 'mile',
    targ_location_type: 'specific',
    targ_user_location_type: 'living',
    targ_ad_rotation: 'optimize',
    targ_google_affinity_cat_ids: [],
    targ_google_inmarket_ids: [],
  },
  ad: {
    ad_name: 'DN_AD',
    ad_type: 'image',
    ad_placements: [],
    ad_url: '',
    ad_facebook_page_id: null,
    ad_facebook_pixel_id: null,
    ad_main_video_id: [],
    ad_main_image_id: [],
    ad_google_images: {},
    ad_headlines: [''],
    ad_descriptions: [''],
    ad_youtube_links: [''],
    ad_fb_primary_text: 'Sample Ad Primary Text',
    ad_google_primary_text: 'Sample Ad Primary Text',
    ad_business_name: 'Business Name',
    ad_display_link: 'www.sample.com/',
    ad_url_parameters: '',
    ad_facebook_call_to_action: 'learn_more',
    ad_google_call_to_action: 'learn_more',
    ad_call_to_action: 'learn_more',
    creation_loading: false,
    display_links: {
      path1: '',
      path2: '',
    },
  },
  ad_extensions: {
    callout_texts: ['Sample callout text 1'],
    sitelink: [
      {
        text: '',
        desc1: '',
        desc2: '',
        final_url: '',
      },
    ],
  },
};

export const adCreationSlice = createSlice({
  name: 'adForm',
  initialState: initialState,
  reducers: {
    /**
     * When Form general field changes
     * @example
     * adform_setAdFormFieldAction({campaign_name: 'test'});
     */
    adform_setAdFormFieldAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.form = { ...state.form, ...action.payload };
    },
    /**
     * When accounts changes
     * @example
     * adform_setAccountsAction({faceook: 111});
     */
    adform_setAccountsAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.accounts = { ...state.accounts, ...action.payload };
    },
    /**
     * When campaign field changes
     * @example
     * adform_setCampaignAction({campaign_name: 'test'});
     */
    adform_setCampaignAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.campaign = { ...state.campaign, ...action.payload };
    },
    /**
     * When adgroup field changes
     * @example
     * adform_setAdGroupAction({adgroup_name: 'test'});
     */
    adform_setAdGroupAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.adgroup = { ...state.adgroup, ...action.payload };
    },
    /**
     * When ad field changes
     * @example
     * adform_setAdAction({ad_name: 'test'});
     */
    adform_setAdAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.ad = { ...state.ad, ...action.payload };
    },

    /**
     * When Extension fields changes
     * @example
     * adForm_setAdExtensionAction({sitelink: {}});
     */ adForm_setAdExtensionAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.ad_extensions = { ...state.ad_extensions, ...action.payload };
    },

    /**
     * When Ad Targeting field changes
     * @example
     * adForm_setAdTargetingAction({targ_age_from: 15});
     */
    adForm_setAdTargetingAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.targeting = { ...state.targeting, ...action.payload };
    },

    /**
     * Set Ad Networks
     */
    setNetworksAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.networks = { ...state.networks, ...action.payload };
    },
    /**
     * When form draft is loaded, parse form draft and update
     * set networks, campaigns, adgroup and every key
     * @example
     * loadDraftAction(draft)
     */
    loadDraftAction: (state, action) => {
      const { networks, campaign, adgroup, targeting, ad } = action.payload;
      state.networks = networks;
      state.campaign = campaign;
      state.adgroup = adgroup;
      state.targeting = targeting;
      state.ad = ad;
      state.draftLoaded = new Date().getTime();
    },
    /**
     * Update formstatus when published ad form
     * @example
     * setAdFormStatusAction({campaign: 111});
     */
    setAdFormStatusAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.formStatus = { ...state.formStatus, ...action.payload };
    },
    /**
     * Set ad form error
     * @example
     * setErrorAction('err')
     */
    setErrorAction: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  adform_setAdFormFieldAction,
  adform_setCampaignAction,
  adform_setAccountsAction,
  adform_setAdGroupAction,
  adform_setAdAction,
  adForm_setAdExtensionAction,
  adForm_setAdTargetingAction,
  /** @todo remove */
  setNetworksAction,
  loadDraftAction,
  setErrorAction,
} = adCreationSlice.actions;

export default adCreationSlice.reducer;
