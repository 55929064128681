import React from 'react';
import SingleNotification from './SingleNotification';

const NotificationsPanel = () => {
  const notifications = [];

  return (
    <div style={{ width: '22rem' }}>
      <p
        className='text-indigo-700 font-base font-bold m-6 mb-2 text-base'
        style={{ color: '#536A8B', fontFamily: 'fregular', fontWeight: 500 }}
      >
        System Notifications
      </p>
      {notifications.map((n) => (
        <SingleNotification key={n.id} data={n} />
      ))}
    </div>
  );
};

export default NotificationsPanel;
