/**
 * Get window uri
 * @returns {String}
 */
window.getLocation = () => {
  return window.location.href.replace(window.location.origin, '');
};

window.createUrl = (obj) => {
  const href = window.location.href;
  if (typeof obj !== 'object') {
    return href;
  }
  const queryString = new URLSearchParams(obj).toString();

  return href.indexOf('?') > -1 ? `${href}&${queryString}` : `${href}?${queryString}`;
};
