import { useEffect } from 'react';
import ninjaApi from 'store/redux/apis';
import { setApiAuthToken } from 'store/redux/utils/authtoken';

export default function useBootstrapUser() {
  const [trigger, { isLoading, isFetching }] = ninjaApi.endpoints.bootstrapUser.useLazyQuery();

  /**
   * Runs bootstap query
   * @param {String} token
   */
  const bootstrapWithToken = (token = null) => {
    if (token) {
      setApiAuthToken(token).then(() => {
        trigger({});
      });
    } else {
      trigger({});
    }
  };

  /** Runs initially loaded in main app controller */
  useEffect(trigger, []);

  return {
    bootstrapWithToken,
    isLoading: isLoading || isFetching,
    trigger,
  };
}
