import ninjaApi from 'store/redux/apis/ninja.api';

export const cronUpdateTimeApi = ninjaApi.injectEndpoints({
  tagTypes: ['General'],
  overrideExisting: true,
  endpoints: (build) => ({
    cronUpdateTime: build.query({
      query: (body) => ({
        url: '/workspace/dashboard/data/cron-times',
        method: 'POST',
        body,
      }),
      providesTags: ['General'],
    }),
  }),
});

export const { useCronUpdateTimeQuery } = cronUpdateTimeApi;
