import { useEffect, useState } from 'react';
import loadFastSpringScript from './fastspringscript';

export default function useFastSpringScript({ callback }) {
  const [loading, setLoading] = useState(true);

  const handleScriptLoad = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const script = loadFastSpringScript();
    window.fastSpringPopupCallback = callback;

    script.addEventListener('load', handleScriptLoad);

    return () => {
      document.head.removeChild(script);
      script.removeEventListener('load', handleScriptLoad);
    };
  }, []);

  return {
    loading,
  };
}
