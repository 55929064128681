import Dialog from 'components/Dialogs/Dialog';
import React from 'react';
import { Button, Typography } from '@material-ui/core';

const AlertDialog = (props) => {
  const { handleCancel = () => {}, onClose = () => {}, text, cancel } = props;

  return (
    <Dialog open onClose={onClose}>
      <div className='flex gap-8 p-4'>
        <div className='flex column gap-2'>
          <Typography variant='h1' style={{ color: '#FF4E00', fontSize: 14, fontWeight: 'bold' }}>
            MyDataNinja.com says
          </Typography>
          <Typography variant='h3' style={{ color: 'black', fontSize: 12, fontWeight: 350 }}>
            {text}
          </Typography>
        </div>
        <div className='flex gap-2'>
          <Button onClick={onClose}>OK</Button>
          {cancel && <Button onClick={handleCancel}>Cancel</Button>}
        </div>
      </div>
    </Dialog>
  );
};

export default AlertDialog;
