import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';

const Networks = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup}>
      {props.fb ? (
        <IconButton className={classes.iconButton}>
          <img src='/images/facebook.png' alt='' className={classes.icon} />
        </IconButton>
      ) : null}
      {props.gg ? (
        <IconButton className={classes.iconButton}>
          <img src='/images/google.png' alt='' className={classes.icon} />
        </IconButton>
      ) : null}
    </div>
  );
};

export default Networks;

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginRight: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-around',
    border: `1px solid ${theme.colors.xlight}`,
    height: 25,
    padding: theme.spacing(0.25),
    borderRadius: 100,
  },
  iconButton: {
    padding: 2,
    borderRadius: 100,
    width: 20,
  },
  icon: {
    objectFit: 'contain',
    height: '15px',
  },
}));
