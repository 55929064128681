import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import './full-page-overlay.css';

const FullPageOverlay = (props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.fullPageLoadingRoot, { hidden: !props.loading })}>
      <CircularProgress size={110} thickness={2} />
      <img src='/icons/dataninja/dataninja.svg' alt='' className='logo animate-pulse' />
    </div>
  );
};

export default FullPageOverlay;

const useStyles = makeStyles((theme) => ({
  fullPageLoadingRoot: {
    position: 'fixed',
    top: 0,
    width: '100vw',
    height: '100vh',
    left: 0,
    backgroundColor: theme.colors.bgColorDark,
    zIndex: 100000,
    textAlign: 'center',
    paddingTop: '30vh',
    '& svg': {
      color: theme.colors.orange,
    },
    '& img': {
      position: 'absolute',
      top: 'calc(30vh + 55px - 36px)',
      left: 'calc(50vw - 33px)',
      width: 68,
      animation: `$pulsate 2500ms 200ms infinite`,
    },
  },
}));
