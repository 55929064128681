import { MenuItem, withStyles } from '@material-ui/core';

export default withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.colors.xlight}`,
    fontFamily: 'fregular',
    fontWeight: 500,
    '& svg': {
      display: 'none',
    },
  },
  selected: {
    '& svg': {
      display: 'block',
      color: theme.colors.orange,
      marginLeft: theme.spacing(2),
      width: '0.8em',
    },
  },
}))(MenuItem);
