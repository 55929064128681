import React, { forwardRef } from 'react';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { topBarHeight } from '@ninja/consts';

const HeaderButton = (props, ref) => {
  const { children, underline, color, className, ...rest } = props;
  const classes = useStyles({
    color: color,
    underline: underline,
  });

  return (
    <ButtonBase {...rest} ref={ref} className={`${classes.buttonContainer} ${className}`}>
      {children}
    </ButtonBase>
  );
};

export default forwardRef(HeaderButton);

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: topBarHeight,
    minWidth: topBarHeight,
    maxWidth: '40ch',
    overflow: 'hidden',
    padding: theme.spacing(1, 3),
    color: (props) => theme.colors[props.color],
    textDecoration: (props) => (props.underline ? 'underline' : 'none'),
    fontWeight: 'bold',
    borderRight: `2px solid ${theme.colors.bgColorLight}`,
    cursor: 'pointer',
    transition: '0.2s all ease-in-out',
    '&:hover': {
      background: '#edeff1',
    },
  },
}));
