import UrlHelper from 'common/utils/url-helper';
import io from 'socket.io-client';

let socket = null;

let registeredEvents = {};

export const initSocket = (token, options) => {
  if (!isSocketInitialized()) {
    socket = io(UrlHelper.socket(), {
      secure: true,
      extraHeaders: {
        Authorization: token,
      },
      ...options,
      rejectUnauthorized: false,
    });

    Object.entries(registeredEvents).map(([event, args]) => {
      socket.on(event, ...args);
    });
  }
};

export const getSocket = () => {
  if (!isSocketInitialized()) {
    throw new Error('Socket has not been initialized. Call initSocket first.');
  }
  return socket;
};

export const closeSocket = () => {
  if (isSocketInitialized()) {
    socket.disconnect();
    socket = null;
  }
};

export const registerSocketEvent = (event, ...args) => {
  registeredEvents[event] = args;
};

const isSocketInitialized = () => {
  return socket !== null;
};
