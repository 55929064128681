export const baseUrl = import.meta.env.VITE_API_URL;
export const socketBaseUrl = import.meta.env.VITE_SOCKET_BASE_URL;
export const staticBaseUrl = import.meta.env.VITE_STATIC_BASE_URL;
export const homeUrl = import.meta.env.VITE_HOME_BASE_URL;
export const appBaseUrl = import.meta.env.VITE_APP_BASE_URL;
export const docsBaseUrl = import.meta.env.VITE_DOCS_BASE_URL;
export const postbacksBaseUrl = import.meta.env.VITE_POSTBACKS_BASE_URL;

export const mydataninjaWebsiteId = 'WTcDaPENOQbJuPAL';

export const tokenKey = 'dntoken';
export const languageKey = 'dnlanguage';
export const layoutPaddings = { y: 2, x: 4 };
export const drawerWidth = 240;
export const shrinkDrawerWidth = 64;
export const topBarHeight = 64;

export const facebookAppId = '996494977506145';
export const googleScopes = {
  adwords: 'https://www.googleapis.com/auth/adwords',
  'userinfo.email': 'https://www.googleapis.com/auth/userinfo.email',
  'userinfo.profile': 'https://www.googleapis.com/auth/userinfo.profile',
};
export const googleClientId =
  '557578979024-srsfq3vd30gqqh796s4vopo13p9m705i.apps.googleusercontent.com';
export const googleApiKey = 'AIzaSyCD74c3L60veFjxymjwlOLhMQWkVLWtZ4c';
export const currencies = ['USD', 'EUR', 'GEL', 'GBP'];
export const currency_options = {
  USD: { name: 'USD', value: 'USD', symbol: '$', sign_location: 'pre' },
  EUR: { name: 'EUR', value: 'EUR', symbol: '€', sign_location: 'post' },
  GEL: { name: 'GEL', value: 'GEL', symbol: '₾', sign_location: 'post' },
  GBP: { name: 'GBP', value: 'GBP', symbol: '£', sign_location: 'pre' },
};
export const defaultCurrency = 'USD';

export const NETWORKS = {
  google: 'google',
  facebook: 'facebook',
};

export const roleOptions = {
  1: { name: 'Owner', value: '1', color: '#FF4E00', description: 'With All Roles.' },
  2: { name: 'Editor', value: '2', color: '#2C7DF0', description: 'Restrict only to view.' },
  3: { name: 'Reader', value: '3', color: '#FFBB02', description: 'With only edit mode' },
  4: { name: 'Custom Roles', value: '4', color: '#8f11e0', description: 'Give custom rules.' },
};
