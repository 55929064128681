import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import BootstrapStyleTooltip from './BootstrapStyleTooltip';

const InfoButton = (props) => {
  const classes = useStyles();

  return (
    <BootstrapStyleTooltip title={props.info}>
      <div className={classes.buttonGroup}>
        <IconButton className={classes.iconButton}>
          <img src='/icons/info.svg' alt='' className={classes.icon} />
        </IconButton>
      </div>
    </BootstrapStyleTooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginRight: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-around',
    border: `1px solid ${theme.colors.xlight}`,
    height: 25,
    padding: theme.spacing(0.25),
    borderRadius: 100,
  },
  iconButton: {
    padding: 2,
    borderRadius: 100,
    width: 20,
  },
  icon: {
    objectFit: 'contain',
    height: '15px',
  },
}));

export default InfoButton;
