import { Button, makeStyles, Popover, Typography } from '@material-ui/core';
import StyledCard from 'components/Cards/StyledCard';
import React from 'react';

const ExportButtons = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        variant='contained'
        color='#ECEEEF'
        onClick={handleClick}
        style={{ padding: 5, minWidth: 40 }}
      >
        <img src='/icons/download/downloadgrey.svg' />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledCard
          avatar={<img src='/icons/download/downloadgrey.svg' />}
          padding={[2.5]}
          title='Export'
        >
          <div>
            <Field label='Excel' icon='/icons/formats/excel.svg' />
            <Field label='PDF' icon='/icons/formats/pdf.svg' />
            <Field label='CSV' icon='/icons/formats/csv.svg' />
          </div>
        </StyledCard>
      </Popover>
    </div>
  );
};

export default ExportButtons;

const Field = (props) => {
  const classes = useStyles();
  const { label, onClick, icon } = props;
  return (
    <div className={classes.exportField} alignItems='center' container>
      <div className='flex items-center'>
        <img src={icon} alt='format' />
        <Typography variant='h6'>{label}</Typography>
      </div>
      <span>
        <Button onClick={onClick} style={{ width: 90, height: 40 }}>
          Download
        </Button>
      </span>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  exportField: {
    width: 280,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0',
    padding: 10,
    backgroundColor: '#F1F5F8',
    borderRadius: 6,
    '& h6': {
      marginLeft: 11.72,
    },
  },
}));
