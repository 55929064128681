import React, { useEffect, useState } from 'react';
import TextFormatter from './TextFormatter';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import {
  Avatar,
  Button,
  Divider,
  makeStyles,
  Popover,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';

export const CommentFormatter = (props) => {
  const { disabled = true } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className='w-full'>
      <div onClick={handleClick}>
        <span className='flex align-center justify-between w-full items-center'>
          <TextFormatter
            fontWeight={400}
            {...props}
            emptyText='Add a comment'
            className={classes.text}
          />
          <div className={`${classes.editIcon} editIcon`}>
            <Icon path={mdiPencil} style={{ fontSize: 14 }} />
          </div>
        </span>
      </div>
      <Popover
        open={open && disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CommentPopupBody
          leadId={props.row.id}
          getComments={props.getComments}
          addComment={props.addComment}
          onClose={handleClose}
        />
      </Popover>
    </div>
  );
};

const CommentPopupBody = ({ leadId, getComments, addComment, onClose }) => {
  const classes = useStyles();

  const [comments, setComments] = useState([]);
  const [inputValue, setInputValue] = useState('');
  console.log(leadId);

  useEffect(() => {
    loadComments();
  }, [comments]);

  const loadComments = async () => {
    const res = await getComments(leadId);
    setComments(res);
  };

  const handleAddComment = async () => {
    await addComment(leadId, inputValue);
    setInputValue('');
    loadComments();
  };

  return (
    <div>
      <div className='m-2 max-w-[400px]' style={{ overflow: 'auto' }}>
        {comments.map((comment) => (
          <React.Fragment key={comment.id}>
            <div className='p-5 border-b border-[#c3ced5] max-w-[420px] min-w-[300px]'>
              <div className='flex items-center gap-2'>
                <Avatar alt='' src={comment.avatar} className={classes.avatar} />
                <Typography variant='subtitle1' className={classes.user}>
                  {comment.author}
                </Typography>
                <div className='flex-1' />

                <Typography variant='subtitle1' className={classes.time}>
                  {format(new Date(comment.created_at), 'dd-MM-yyyy HH:mm')}
                </Typography>
              </div>
              <Typography variant='h4' className={classes.comment}>
                {comment.text}
              </Typography>
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className='p-5'>
        <TextareaAutosize
          aria-label='minimum height'
          minRows={3}
          placeholder='Add a comment'
          className='w-full'
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <div className='flex gap-2.5'>
          <Button color='secondary' onClick={handleAddComment}>
            Post
          </Button>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  text: {
    background: '#E9F0F4',
    borderRadius: 12,
    padding: '5px 6px',
    display: 'inline',
    maxWidth: '9rem',
  },
  user: {
    fontWeight: 350,
    fontSize: 12,
    color: '#151A22',
  },
  time: {
    fontSize: 11,
    fontWeight: 350,
    color: '#828282',
  },
  avatar: {
    border: '2px solid #2C7DF0',
  },
  comment: {
    marginTop: 20,
    fontSize: 13,
    fontWeight: 600,
    color: '#151A22',
  },
  editIcon: {
    backgroundColor: '#F1F5F8',
    borderRadius: '50%',
    width: 26,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    '& svg': {
      color: '#8EA4B5 !important',
    },
  },
}));

export default CommentFormatter;
