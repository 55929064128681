import { value } from './utils';

/**
 * @todo add multiple params support {get, set}
 */
class Url {
  getSearchParams = () => {
    return new URLSearchParams(window.location.search);
  };

  get = (param, def) => {
    const data = this.getSearchParams().get(param);

    if (data == null) {
      return value(def);
    }

    return data;
  };

  set = (param, value) => {
    const params = this.getSearchParams();
    params.set(param, value);
    const search = decodeURIComponent(params.toString());
    window.history.replaceState(null, null, '?' + search);
  };
}

// create url singletone
const url = new Url();
window.__ninjaurl = url;
export default url;
