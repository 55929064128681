import ninjaApi from 'store/redux/apis/ninja.api';

export const adAdsetApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['AdAdset', 'KeywordReporting'],
  endpoints: (builder) => ({
    /* Get Adset Details */
    adsetDetails: builder.query({
      query: (body) => ({
        url: '/network/adset/details',
        method: 'POST',
        body,
      }),
      providesTags: ['AdAdset'],
    }),
    /* Change Adset Status */
    changeAdsetStatus: builder.mutation({
      query: (body) => ({
        url: '/network/adset/change-status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AdAdset'],
    }),
    /* Change Adset Targeting Status */
    changeTargetingStatus: builder.mutation({
      query: (body) => ({
        url: '/network/adset/change-targeting-status',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['KeywordReporting'],
    }),
    /* Change Adset Keyword Status */
    addKeywordToAdset: builder.mutation({
      query: (body) => ({
        url: '/network/adset/add-keywords',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['KeywordReporting'],
    }),
    /* copy Adset */
    copyAdset: builder.mutation({
      query: (body) => ({
        url: '/network/adset/copy',
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),

    /* search Adset */
    searchAdset: builder.query({
      query: (body) => ({
        url: 'network/adset/search',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useChangeAdsetStatusMutation,
  useChangeTargetingStatusMutation,
  useAddKeywordToAdsetMutation,
  useAdsetDetailsQuery,
  useCopyAdsetMutation,
  useSearchAdsetQuery,
} = adAdsetApi;
