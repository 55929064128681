export const AlertType = Object.freeze({
  Error: 'error',
  Warning: 'warning',
  Success: 'success',
});

export const AlertConfig = Object.freeze({
  error: {
    icon: '/icons/cross/crosscirclered.svg',
    title: 'Error',
    color: '#EB2F20',
  },
  warning: {
    icon: '/icons/cross/warning.svg',
    title: 'Warning',
    color: 'orange',
  },
  success: {
    icon: '/icons/cross/success.svg',
    title: 'Success',
    color: 'green',
  },
});
