import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import TextField from 'components/form/fields/TextField';
import SelectInput from 'components/inputs/SelectInput';
import CancelIcon from '@material-ui/icons/Cancel';

const fieldsOptions = {
  profit: { value: 'profit', name: 'Profit' },
};
const operatorsOptions = {
  equals: { value: 'equals', name: 'Equals' },
  lower_than: { value: 'lower_than', name: 'Lower than' },
  greater_than: { value: 'greater_than', name: 'Greater than' },
};

const Condition = ({ data, onAnd, onDelete, onChange }) => {
  return (
    <div className='flex gap-2 items-center'>
      <div className='flex gap-2 items-center flex-col md:flex-row'>
        <SelectInput
          label='Field'
          className='bg-white'
          options={fieldsOptions}
          value={data.field || ''}
          onChange={(v) => {
            onChange({ ...data, field: v });
          }}
        />
        <SelectInput
          label='Operator'
          className='bg-white'
          value={data.operator || ''}
          options={operatorsOptions}
          onChange={(v) => {
            onChange({ ...data, operator: v });
          }}
        />
        <TextField
          label='Value'
          className='bg-white'
          value={data.value}
          onChange={(e) => {
            onChange({ ...data, value: e.target.value });
          }}
        />
      </div>
      <Button type='text' color='blue' onClick={onAnd} className={!onAnd && 'invisible'}>
        AND
      </Button>
      <IconButton onClick={onDelete}>
        <CancelIcon fontSize='large' />
      </IconButton>
    </div>
  );
};

export default Condition;
