import { useDispatch, useSelector } from 'react-redux';
import {
  adform_setAdAction,
  adform_setAdFormFieldAction,
  adform_setAdGroupAction,
  adform_setCampaignAction,
  adForm_setAdExtensionAction,
  adForm_setAdTargetingAction,
  setErrorAction,
  setNetworksAction,
  adform_setAccountsAction,
} from './ad-form.state';

export const useAdCreationNetworks = () => {
  const networks = useSelector((state) => state.adForm.networks);
  const dispatch = useDispatch();

  const setNetworks = (action) => {
    const { network, state: networkState } = action;
    const clonedNetworks = { ...networks };
    const computedNetworks = changeNetworkState(clonedNetworks, network, networkState);

    dispatch(setNetworksAction(computedNetworks));
  };

  return {
    networks,
    allNetworkActive: networks.all,
    onlyNetworkActive: networks.single,
    googleEnabled: networks.google || networks.google_search,
    setNetworks,
  };
};

export const useAdFormCampaign = () => {
  const campaign = useSelector((state) => state.adForm.campaign);
  const dispatch = useDispatch();

  const setCampaign = (new_campaign_field) => {
    dispatch(
      adform_setCampaignAction({
        ...campaign,
        ...new_campaign_field,
      })
    );
  };

  return {
    ...campaign,
    setCampaign,
  };
};

export const useAdFormAccounts = () => {
  const accounts = useSelector((state) => state.adForm.accounts);
  const dispatch = useDispatch();

  const setAccounts = (new_accounts) => {
    dispatch(
      adform_setAccountsAction({
        ...accounts,
        ...new_accounts,
      })
    );
  };

  const getAccountIds = () => {
    const val = [];
    if (accounts.google) val.push(accounts.google);
    if (accounts.facebook) val.push(accounts.facebook);
    return val;
  };

  return {
    ...accounts,
    setAccounts,
    getAccountIds,
  };
};

export const useAdForm = () => {
  const form = useSelector((state) => state.adForm.form);
  const dispatch = useDispatch();

  const setAdForm = (new_form_field) => {
    dispatch(
      adform_setAdFormFieldAction({
        ...form,
        ...new_form_field,
      })
    );
  };

  const setAdFormMode = (mode) => {
    setAdForm({ mode: mode });
  };

  const isAdvancedMode = form.mode === 'advanced';
  const isEasyMode = form.mode === 'easy';

  return {
    ...form,
    isAdvancedMode,
    isEasyMode,
    setAdForm,
    setAdFormMode,
  };
};

export const useAdFormAdGroup = () => {
  const adgroup = useSelector((state) => state.adForm.adgroup);
  const dispatch = useDispatch();

  const setAdGroup = (new_adgroup_field) => {
    dispatch(
      adform_setAdGroupAction({
        ...adgroup,
        ...new_adgroup_field,
      })
    );
  };

  return {
    ...adgroup,
    setAdGroup,
  };
};

export const useAdFormTargeting = () => {
  const targeting = useSelector((state) => state.adForm.targeting);
  const dispatch = useDispatch();

  const setTargeting = (new_targeting_field) => {
    dispatch(
      adForm_setAdTargetingAction({
        ...targeting,
        ...new_targeting_field,
      })
    );
  };

  return {
    ...targeting,
    setTargeting,
  };
};

export const useAdFormAd = () => {
  const ad = useSelector((state) => state.adForm.ad);
  const dispatch = useDispatch();

  const setAd = (new_ad_field) => {
    dispatch(
      adform_setAdAction({
        ...new_ad_field,
      })
    );
  };

  return {
    ...ad,
    setAd,
  };
};

export const useAdFormExtension = () => {
  const extension = useSelector((state) => state.adForm.ad_extensions);
  const dispatch = useDispatch();

  const setExtention = (new_extension_field) => {
    dispatch(
      adForm_setAdExtensionAction({
        ...extension,
        ...new_extension_field,
      })
    );
  };

  return {
    ...extension,
    setExtention,
  };
};

export const useAdFormError = () => {
  const error = useSelector((state) => state.adForm.error);
  const dispatch = useDispatch();

  const setError = (error) => {
    dispatch(setErrorAction(error));
  };

  return [error, setError];
};

/**
 * Change network state in ad-state
 * @param {Object} networks
 * @param {Array|Object|String} network
 * @param {String} action
 */
function changeNetworkState(networks, network, action) {
  const allNetworks = ['facebook', 'google'];
  const searchNetworks = ['google_search'];

  const searchChangeRequested = Array.intersects(searchNetworks, Array.wrap(network));

  // At first, run all actions and set new networks as valid network
  Array.wrap(network).forEach((n) => {
    if (typeof networks[n] === 'undefined') return;
    networks[n] = action == 'toggle' ? !networks[n] : action === 'on';
  });

  // If search is clicked
  if (searchChangeRequested) {
    // If search is activated, turn off all networks
    if (networks.google_search) {
      networks.all = false;
      networks.single = 'google_search';

      allNetworks.forEach((n) => {
        networks[n] = false;
      });
    } else if (!networks.google_search) {
      networks.all = false;
      networks.single = null;
      networks.none = null;
    }

    return networks;
  }
  // If search is not clicked, make sure that it's deactivated
  else {
    networks.google_search = false;
  }

  const activeNetworks = allNetworks
    .map((n) => {
      if (typeof networks[n] === 'undefined' || !networks[n]) return false;
      return n;
    })
    .filter((n) => n);

  networks.all = activeNetworks.length === allNetworks.length;
  networks.single = activeNetworks.length !== 1 ? null : activeNetworks[0];
  networks.none = activeNetworks.length === 0;

  return networks;
}
