import {
  appBaseUrl,
  baseUrl,
  docsBaseUrl,
  homeUrl,
  postbacksBaseUrl,
  socketBaseUrl,
  staticBaseUrl,
} from '@ninja/consts';
import url from 'packages/url-builder';

class UrlHelper {
  /**
   * Build home url
   * @param {String} path
   * @param {Object} query
   * @returns {String}
   */
  static home(path = '', query = {}) {
    return String(url.withHost(homeUrl).withPath(path).withQuery(query));
  }

  /**
   * Build api url
   * @param {String} path
   * @param {Object} query
   * @returns {String}
   */
  static api(path = '', query = {}) {
    return String(url.withHost(baseUrl).withPath(path).withQuery(query));
  }

  /**
   * Build app url
   * @param {String} path
   * @param {Object} query
   * @returns {String}
   */
  static app(path = '', query = {}) {
    return String(url.withHost(appBaseUrl).withPath(path).withQuery(query));
  }

  /**
   * Build app url
   * @param {String} path
   * @param {Object} query
   * @returns {String}
   */
  static docs(path = '', query = {}) {
    return String(url.withHost(docsBaseUrl).withPath(path).withQuery(query));
  }

  /**
   * Build postbacks url
   * @param {String} path
   * @param {Object} query
   * @returns {String}
   */
  static postback(path = '', query = {}) {
    return String(url.withHost(postbacksBaseUrl).withPath(path).withQuery(query));
  }

  /**
   * Build socket url
   * @param {String} path
   * @param {Object} query
   * @returns {String}
   */
  static socket(path = '', query = {}) {
    return String(url.withHost(socketBaseUrl).withPath(path).withQuery(query));
  }

  /**
   * Build static server url
   * @param {String} path
   * @param {Object} query
   * @returns {String}
   */
  static static(path = '', query = {}) {
    return String(url.withHost(staticBaseUrl).withPath(path).withQuery(query));
  }

  /**
   * Create File Url
   * @param {String|Number} fileId
   * @param {Object} query
   * @returns {String}
   */
  static getFileUrl(fileId, query = {}) {
    return UrlHelper.static(`file-manager/get/${fileId}`, query);
  }

  /**
   * Create flag url
   * @param {String} countryCode
   * @param {Object} query
   * @returns {String}
   */
  static getFlagIconUrl(countryCode, query = {}) {
    return UrlHelper.static(`images/flags/${String(countryCode).toLowerCase()}.svg`, query);
  }
}

export default UrlHelper;
