import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import TextField from 'components/form/fields/TextField';
import SelectInput from 'components/inputs/SelectInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import RuleConditions from './RuleConditions';
import useRules from 'store/RulesContext';
import { useCreateRuleMutation, useUpdateRuleMutation } from 'store/redux/apis/rules.api';
import { useAllNetworkAccounts } from 'store/redux/hooks/useNetworkAccounts';

const levelOptions = {
  workspace: { value: 'workspace', name: 'Workspace' },
  network: { value: 'network', name: 'Network' },
  network_account_id: { value: 'network_account_id', name: 'Account' },
  network_campaign_id: { value: 'network_campaign_id', name: 'Campaign' },
  network_adset_id: { value: 'network_adset_id', name: 'Adset' },
};
const actionOptions = {
  turn_off: { value: 'turn_off', name: 'Turn off' },
  turn_on: { value: 'turn_on', name: 'Turn on' },
  notificate: { value: 'notificate', name: 'Notificate' },
  grow_budget: { value: 'grow_budget', name: 'Grow Budget' },
  lower_budget: { value: 'lower_budget', name: 'Lower Budget' },
};
const networkOptions = {
  facebook: { name: 'Facebook', value: 'facebook' },
  google: { name: 'Google', value: 'google' },
};

const actionsWithValue = {
  notificate: 'Notificate',
  grow_budget: 'Grow budget',
  lower_budget: 'Lower budget',
};

const validationSchema = yup.object({
  ruleName: yup.string().required('Rule name is required'),
  level: yup.string().required('Level is required'),
  levelValue: yup.string().when('level', {
    is: (level) => {
      return !level || level !== 'workspace';
    },
    then: yup.string().required('Level value is required'),
  }),
});

const RuleModal = () => {
  const { ruleData, setRuleData, openModal, setOpenModal } = useRules();

  const accountOptions = useAllNetworkAccounts(true);

  const [createRule] = useCreateRuleMutation();
  const [updateRule] = useUpdateRuleMutation();

  const levelConfig = {
    network: { options: networkOptions, label: '' },
    network_account_id: { options: accountOptions, label: '' },
    // network_campaign_id: { options: [], key: 'network_campaign_id' },
    // network_adset_id: { options: [], key: 'network_adset_id' },
  };

  const handleClose = () => {
    setOpenModal(false);
    setRuleData(null);
    resetForm();
  };

  const saveRule = (formData) => {
    let rules = formData.conditions.map((conds) =>
      conds.filter((cond) => cond.field && cond.operator && cond.value)
    );
    rules = rules.filter((r) => r.length);

    const data = {
      id: formData.id || undefined,
      name: formData.ruleName,
      [formData.level]: formData.levelValue,
      action: { action: formData.actionName, value: formData.actionValue },
      rules: rules,
    };
    if (data.id) {
      updateRule(data).then((res) => {
        if (res.data?.success) {
          handleClose();
        } else {
          alertError(res.error);
        }
      });
    } else {
      createRule(data).then((res) => {
        if (!res.error) {
          handleClose();
        } else {
          alertError(res.error);
        }
      });
    }
  };

  const alertError = (error) => {
    if (error?.data?.errors) {
      const errorMsg = Object.entries(error?.data?.errors)
        .map(([_, v]) => v)
        .join('; ');
      window.alert(errorMsg);
    }
  };

  const { values, setFieldValue, errors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: null,
      level: 'workspace',
      levelValue: '',
      ruleName: '',
      actionName: '',
      actionValue: '',
      conditions: [[{}]],
    },
    validationSchema: validationSchema,
    onSubmit: saveRule,
    validateOnChange: false,
  });

  useEffect(() => {
    if (ruleData) {
      setFieldValue('ruleName', ruleData.name);
      setFieldValue('id', ruleData.id);
      setFieldValue('conditions', ruleData.rules ? JSON.parse(ruleData.rules) : [[{}]]);
    } else {
      resetForm();
    }
  }, [ruleData]);

  const title = values.id ? 'Update rule' : 'Create rule';
  const levelCfg = values.level && levelConfig[values.level];

  return (
    <Dialog onClose={handleClose} open={openModal}>
      <DialogTitle onClose={handleClose}>
        <div className='flex justify-between items-center'>
          <Typography className='!font-bold'>{title}</Typography>
          <IconButton aria-label='close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className='flex flex-col gap-4'>
          <div className='flex gap-4'>
            <SelectInput
              label='Choose level'
              options={levelOptions}
              value={values.level}
              onChange={(v) => {
                setFieldValue('level', v);
                setFieldValue('levelValue', '');
              }}
              error={!!errors.level}
              helperText={errors.level}
            />
            {levelCfg && (
              <SelectInput
                label={levelCfg.label}
                options={levelCfg.options}
                value={values.levelValue}
                onChange={(v) => {
                  setFieldValue('levelValue', v);
                }}
                error={!!errors.levelValue}
                helperText={errors.levelValue}
              />
            )}
          </div>
          <TextField
            label='Rule name'
            value={values.ruleName}
            onChange={(e) => {
              setFieldValue('ruleName', e.target.value);
            }}
            error={!!errors.ruleName}
            helperText={errors.ruleName}
          />
          <div className='flex gap-4'>
            <SelectInput
              label='Action'
              options={actionOptions}
              value={values.actionName}
              onChange={(v) => {
                setFieldValue('actionName', v);
                setFieldValue('actionValue', '');
              }}
            />
            {actionsWithValue[values.actionName] && (
              <TextField
                label={actionsWithValue[values.actionName]}
                value={values.actionValue}
                onChange={(e) => {
                  setFieldValue('actionValue', e.target.value);
                }}
              />
            )}
          </div>
          <RuleConditions
            data={values.conditions}
            onChange={(conditions) => {
              setFieldValue('conditions', conditions);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleSubmit();
          }}
          disabled={!values.conditions?.length || !values.actionName || !values.ruleName}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RuleModal;
