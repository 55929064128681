import { mdiPause, mdiPlay } from '@mdi/js';
import Icon from '@mdi/react';
import { Select, Space } from 'antd';
import React from 'react';

const StatusSelect = (props) => {
  const { placeholder = 'Select Status' } = props;
  return (
    <Select
      mode='multiple'
      style={{ width: '100%' }}
      placeholder={placeholder}
      maxTagCount='responsive'
      allowClear
      {...props}
      showSearch={false}
    >
      <Select.Option value='active' label='Active'>
        <Space>
          <span role='img' aria-label='Active'>
            <Icon path={mdiPlay} size='12px' />
          </span>
          Active
        </Space>
      </Select.Option>
      <Select.Option value='paused' label='Paused'>
        <Space>
          <span role='img' aria-label='Paused'>
            <Icon path={mdiPause} size='12px' />
          </span>
          Paused
        </Space>
      </Select.Option>
    </Select>
  );
};

export default StatusSelect;
