import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const SingleNotification = (props) => {
  const { data } = props;
  const classes = useStyles();

  const handleMarkAsReadClick = () => {};

  return (
    <div className={clsx('flex gap-4 p-4 items-center', classes.notification)}>
      <div className='w-10'>{data.img && <img src={data.img} />}</div>
      <div className='flex flex-grow flex-col gap-0.5 w-40'>
        <text className={classes.title}>{data.title}</text>
        <text className='text'>{data.text}</text>
        <text className='nnj-caption'>{data.date}</text>
        <div className='flex space-x-2 mt-2'>
          {data.ok && <Button>OK</Button>}
          {data.cancel && <Button variant='outlined'>Cancel</Button>}
        </div>
        <div className='flex space-x-2 mt-2'>
          {data.accept && <Button>Aaccept</Button>}
          {data.decline && <Button variant='outlined'>Decline</Button>}
        </div>
      </div>
      <div className='flex-center w-3'>
        <div
          onClick={handleMarkAsReadClick}
          className={clsx('w-2 h-2 rounded-full', {
            'bg-gray-light': !data.unread,
            'bg-success': data.unread,
          })}
        >
          {/* {isLoading ? <CircularProgress size={8} /> : null} */}
        </div>
      </div>
    </div>
  );
};

export default SingleNotification;

const useStyles = makeStyles(() => ({
  notification: {
    '& .text': {
      fontFamily: 'fregular',
      fontSize: 12,
      color: '#536A8B',
      fontWeight: 400,
    },
    borderBottom: '1px solid  #EBEFF2',
    '&:hover': {
      backgroundColor: '#F6FBFF',
      color: '#2C7DF0',
      '& .text': {
        color: '#000',
      },
    },

    '& .MuiButton-containedSecondary': {
      borderRadius: '100px',
    },
    '& .MuiButton-outlinedSecondary': {
      borderRadius: '100px',
    },
  },
  title: {
    fontFamily: 'fregular',
    fontSize: 13,
    fontWeight: 700,
  },
}));
