import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * Returns workspace
 */
export const setupApi = ninjaApi.injectEndpoints({
  reducerPath: 'setupApi',
  overrideExisting: false,
  tagTypes: ['WorkspaceQuestion', 'Workspace', 'DefaultWorkspace'],
  endpoints: (build) => ({
    /**
     * Load all questions with answer
     */
    listQuestions: build.query({
      query: () => ({
        url: '/workspace/setup/list-questions',
        method: 'POST',
      }),
      providesTags: [{ type: 'WorkspaceQuestion', id: 'LIST' }],
    }),
    /**
     * Answer single question
     */
    answerQuestion: build.mutation({
      query: (body) => ({
        url: '/workspace/setup/answer-question',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'WorkspaceQuestion', id: 'LIST' }],
    }),
    /**
     * Answer single question
     */
    setWizardStep: build.mutation({
      query: (body) => ({
        url: '/workspace/setup/set-wizard-step',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Workspace' }, { type: 'DefaultWorkspace' }],
    }),
  }),
});

export const { useListQuestionsQuery, useAnswerQuestionMutation, useSetWizardStepMutation } =
  setupApi;

export const useQuestion = (question_name) => {
  const { data: questions, isLoading: isListLoading } = useListQuestionsQuery();
  const [answerQuestionRequest, { isLoading: isAnswerLoading }] = useAnswerQuestionMutation();

  const answerQuestion = (answer) => {
    return answerQuestionRequest({
      question: question_name,
      answer: answer,
    }).unwrap();
  };

  return {
    isLoading: isListLoading || isAnswerLoading,
    answer: questions ? questions[question_name] : null,
    answerQuestion: answerQuestion,
  };
};
