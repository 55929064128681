import React from 'react';
import { Button } from '@material-ui/core';
import Condition from './Condition';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

const RuleConditions = ({ data: _data = [[]], onChange }) => {
  const data = cloneDeep(_data);
  const handleDeleteCondition = (orConditions, i, j) => {
    if (data.length === 1 && orConditions.length === 1) {
      onChange([[{}]]);
      return;
    }
    orConditions.splice(j, 1);
    if (!orConditions.length && data.length > 1) {
      data.splice(i, 1);
    }
    onChange(data);
  };

  return (
    <div className='flex flex-col gap-4'>
      {data.map((orConditions, i) => (
        <div key={i} className='flex flex-col gap-2 bg-background-light p-4'>
          {orConditions.map((oc, j) => {
            return (
              <Condition
                key={oc.id}
                data={oc}
                onAnd={
                  j === orConditions.length - 1 &&
                  (() => {
                    orConditions.push({ id: uuidv4() });
                    onChange(data);
                  })
                }
                onDelete={() => {
                  handleDeleteCondition(orConditions, i, j);
                }}
                onChange={(newOc) => {
                  orConditions[j] = newOc;
                  onChange(data);
                }}
              />
            );
          })}
        </div>
      ))}
      <Button
        type='primary'
        className='w-2'
        onClick={() => {
          data.push([{ id: uuidv4() }]);
          onChange(data);
        }}
      >
        OR
      </Button>
    </div>
  );
};

export default RuleConditions;
