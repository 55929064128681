import useAuth from 'store/redux/hooks/useAuth';
import { useGetBillingPlanQuery } from '../billing.api';

export default function useBilling() {
  const { auth } = useAuth();
  const {
    data = {},
    isLoading,
    isFetching,
    isError,
  } = useGetBillingPlanQuery(null, {
    skip: auth === false,
  });

  let billing_data = data ? data : {};

  return {
    billing: billing_data,
    isLoading: isFetching || isLoading,
    isError,
  };
}
