import defaultPalette from 'common/@mui/palette';
import createSpacing from '@material-ui/core/styles/createSpacing';
import colorPalette from 'common/@mui/palette';
import shadows from '@material-ui/core/styles/shadows';

const spacing = createSpacing();
const helpersFontSize = '0.6875rem';
const inputFontSize = '0.8125rem';

const overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
      outline: 'none',
      height: '2.5rem',
      borderRadius: (props) => (props.round ? 30 : 6),
      width: (props) => props.width && props.width,
      padding: (props) => props.padding && spacing(...props.padding),
    },
    containedPrimary: {
      backgroundColor: '#2c7df0',
      color: '#fff',
    },
    containedSecondary: {
      backgroundColor: '#ff4e00',
      color: '#fff',
    },
    outlinedPrimary: {
      borderColor: '#2c7df0 !important',
      borderWidth: '1.5px !important',
      borderStyle: 'solid',
      color: '#2c7df0',
    },
    outlinedSecondary: {
      borderColor: '#ff4e00 !important',
      borderWidth: '1.5px !important',
      borderStyle: 'solid',
      color: '#ff4e00',
    },
    textPrimary: {
      color: '#2c7df0',
    },
    textSecondary: {
      color: '#ff4e00',
    },
    contained: {
      height: '2.5rem',
      padding: spacing(2, 2.5),
    },
    outlined: {
      height: '2.5rem',
      padding: spacing(2, 2.5),
    },
    text: {
      height: '2.5rem',
      padding: spacing(2, 2.5),
    },
    sizeSmall: {
      height: '2.125rem',
    },
    sizeLarge: {
      height: '3.375rem',
    },
    startIcon: {
      marginRight: spacing(1.25),
    },
    endIcon: {
      marginLeft: spacing(1.25),
    },
  },

  MuiIconButton: {
    root: {
      padding: 8,
    },
    label: {
      // for mdiIcon
      '& svg[role]': {
        fill: 'currentColor',
        width: '1rem',
        height: '1rem',
        display: 'inline-block',
        fontSize: '1.5rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: '0',
        userSelect: 'none',
      },
    },
    sizeSmall: {
      padding: 4,
    },
  },

  MuiButtonBase: {
    root: {
      backgroundColor: (props) =>
        colorPalette[props.background] ? colorPalette[props.background] : 'transparent',
    },
  },

  MuiToggleButton: {
    root: {
      border: 0,
      color: defaultPalette.text.secondary,
      textTransform: 'capitalize',
      fontWeight: 'normal',
      transition: '100ms all ease-in-out',
      '&.Mui-selected': {
        backgroundColor: defaultPalette.secondary.main,
        color: defaultPalette.custom.white,
      },
      '&.Mui-selected:hover': {
        color: defaultPalette.custom.white,
        backgroundColor: defaultPalette.secondary.dark,
      },
      '&:hover': {
        color: defaultPalette.custom.white,
        backgroundColor: `${defaultPalette.secondary.dark} !important`,
      },
    },
    groupedHorizontal: {
      color: 'red',
    },
  },

  MuiToggleButtonGroup: {
    root: {
      gap: spacing(0.5),
    },
    groupedHorizontal: {
      borderRadius: '20px !important',
      padding: spacing(1, 2),
    },
    grouped: {
      background: 'transparent',
    },
  },

  MuiSvgIcon: {
    root: {
      width: '1rem',
      height: '1rem',
    },
  },

  MuiChip: {
    colorPrimary: {
      backgroundColor: defaultPalette.custom.backgroundLight,
    },
  },
  MuiCheckbox: {
    root: {
      color: defaultPalette.custom.gray,
      '&.Mui-checked': {
        color: defaultPalette.secondary.main,
      },
      '&.Mui-disabled': {
        color: defaultPalette.primary.main,
        cursor: 'not-allowed',
      },
    },
  },
  MuiAvatar: {
    root: {
      width: 32,
      height: 32,
    },
  },
  MuiListItemAvatar: {
    root: {
      minWidth: 'unset', // DoubleTypography prop streches
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 'unset', // DoubleTypography prop streches
    },
  },
  MuiTypography: {
    colorPrimary: {
      color: defaultPalette.custom.textBlack,
    },
  },

  MuiFormControl: {
    root: {
      '&:hover .MuiFormLabel-root:not(.Mui-disabled):not(.Mui-error)': {
        color: '#4A89E3',
      },
      '&:hover .MuiInputBase-root:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline':
        {
          borderWidth: 2,
          borderColor: '#4A89E3',
        },
      '& legend': {
        fontSize: helpersFontSize,
        marginLeft: '6px',
      },
      '& legend > span': {
        fontSize: 'inherit',
      },
    },
    marginDense: {
      marginBottom: 0,
      marginTop: 0,
    },
  },

  MuiOutlinedInput: {
    root: {
      // background: '#fff',
      borderRadius: 8,
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1.2,
        borderColor: '#D8D8D8',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 2,
        borderColor: '#4A89E3',
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f4f4f4',
        color: '#999999',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#EB2F20',
      },
    },
    adornedEnd: {
      '& .dropDownIcon + .MuiInputAdornment-root': {
        marginRight: 20,
      },
    },
    input: {
      paddingLeft: '20px',
    },
  },

  MuiInputBase: {
    root: {
      fontSize: inputFontSize,
      fontWeight: 'bold',
      color: '#2E3849',
      '&:not(.MuiAutocomplete-inputRoot)': {
        flexWrap: 'nowrap',
        '& @media only screen and (max-width: 600px)': {
          flexWrap: 'wrap',
        },
      },
      minHeight: '54px',
      '&.Mui-disabled': {
        color: '#999999',
      },
    },
    marginDense: {
      '&:not(.MuiAutocomplete-inputRoot)': {
        height: '40px',
      },
      minHeight: '40px',
    },
  },

  MuiAutocomplete: {
    root: {
      '& .MuiInputAdornment-root': {
        marginRight: '0 !important',
        maxHeight: 'unset', // because chips was not wrapping
        height: 'unset',
        flexWrap: 'wrap',
        overflow: 'hidden',
      },
      '& .MuiAutocomplete-endAdornment': {
        right: '14px !important',
      },
    },
    popupIndicator: {
      padding: '8px',
    },
    endAdornment: {
      // top: 'calc(50% - 12px)',
    },
  },

  MuiInputLabel: {
    outlined: {
      transform: 'translate(20px, 21px) scale(1)',
      color: '#7C899D',
      fontSize: helpersFontSize,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(20px, -5px) scale(1)',
      },
      '&.MuiInputLabel-marginDense.MuiInputLabel-shrink': {
        transform: 'translate(20px, -5px) scale(1)',
      },
      '&.MuiInputLabel-marginDense': {
        transform: 'translate(20px, 14px) scale(1)',
      },
    },
    animated: {
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
  },

  MuiFormLabel: {
    root: {
      '&.Mui-disabled': {
        color: '#9a9a9a',
      },
      '&.Mui-focused': {
        color: '#4A89E3',
      },
      '&.Mui-error': {
        color: '#EB2F20',
      },
      outlined: {
        transform: 'translate(20px, 21px) scale(1)',
        color: '#7C899D',
        fontSize: helpersFontSize,
        '&.MuiInputLabel-shrink': {
          transform: 'translate(20px, -5px) scale(1)',
        },
        '&.MuiInputLabel-marginDense.MuiInputLabel-shrink': {
          transform: 'translate(20px, -5px) scale(1)',
        },
        '&.MuiInputLabel-marginDense': {
          transform: 'translate(20px, 14px) scale(1)',
        },
      },
      animated: {
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      },
    },

    outlined: {
      transform: 'translate(20px, 21px) scale(1)',
      color: '#7C899D',
      fontSize: helpersFontSize,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(20px, -5px) scale(1)',
      },
      '&.MuiInputLabel-marginDense.MuiInputLabel-shrink': {
        transform: 'translate(20px, -5px) scale(1)',
      },
      '&.MuiInputLabel-marginDense': {
        transform: 'translate(20px, 14px) scale(1)',
      },
    },
    animated: {
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
  },

  MuiTextField: {
    root: {},
  },

  MuiFormHelperText: {
    root: {
      fontSize: helpersFontSize,
      lineHeight: '12px',
      '&.Mui-disabled': {
        color: '#9a9a9a',
      },
      '&.Mui-focused': {
        color: '#4A89E3',
      },
      '&.Mui-error': {
        color: '#EB2F20',
      },
    },
    contained: {
      marginTop: '5px',
      marginLeft: '20px',
      marginRight: '20px',
    },
  },

  MuiSelect: {
    root: {},
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    outlined: {
      paddingRight: '8px !important',
    },
  },

  MuiAccordion: {
    root: {
      margin: 0,
      borderRadius: '6px',
      boxShadow: shadows[2],
      '&::before': {
        content: 'unset',
      },
    },
    '&$expanded': {
      margin: 0,
      '& $accordionSummary': {},
      '& $accordionSummaryContent': {
        margin: spacing(1, 0),
      },
    },
    rounded: {
      borderRadius: spacing(1),
    },
  },
  MuiAccordionSummary: {
    root: {
      backgroundColor: '#fff!important',
      width: '100%',
      padding: spacing(2, 4),
      marginTop: 30,
      borderRadius: '6px',
    },
    content: {},
  },
};

export default overrides;
