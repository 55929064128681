import { useEffect, useState } from 'react';

const useLocalStorage = ({ key, initialData = {} }) => {
  const [localData, setLocalData] = useState(JSON.parse(localStorage.getItem(key)) || initialData);

  useEffect(() => {
    if (!localStorage.getItem(key)) {
      localStorage.setItem(key, JSON.stringify(localData));
    }
  }, []);

  const onLocalStorageChange = (data) => {
    localStorage.setItem(key, JSON.stringify(data));
    setLocalData(data);
  };

  return [localData, onLocalStorageChange];
};

export default useLocalStorage;
