import React from 'react';
import Arrows from './Arrows';
import clsx from 'clsx';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

export default function InnerProfitForrmatter(props) {
  let val = 0,
    state = null;

  if (props.getValue) {
    val = props.getValue(props);
  } else {
    val = props.row[props.column.key];
  }

  if (props.column.type === 'profit') {
    if (val > 0) state = 'success';
  } else if (props.column.type === 'profit') {
    if (val <= 0) state = 'error';
  }

  return (
    <div className='flex whitespace-nowrap items-center'>
      <Arrows state={state} />

      <span
        className={clsx('px-[7px] py-[5px] rounded-[12px] ml-2 flex items-center h-7', {
          'bg-error text-white': val <= 0,
          'bg-success text-white': val > 0,
        })}
      >
        {getFormattedValue(val)}
      </span>
    </div>
  );
}
